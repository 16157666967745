import Button from '@stryberventures/gaia-react.button';
import { LoadIcon } from '@stryberventures/gaia-react.icons';
import Table, { TableBody, TableCell, TableRow } from '@stryberventures/gaia-react.table';
import { Order, PaginatedRequestParams } from 'api';
import {
  HeadCell,
  TableContainer,
  TableHead,
  TableNoDataRow,
  TablePagination,
  TableSkeletonLoader,
  Text,
  TextColor,
} from 'components';
import useStyles from 'components/table/table.styles';
import { useDownloadDeliveryItemsCsv } from 'features/delivery/use-cases/download-delivery-items-csv';
import { useGetDeliveries } from 'features/delivery/use-cases/get-deliveries';
import { Actions, ActionsCellType } from 'features/logistics/components/table/actions/actions';
import { StatusCell } from 'features/logistics/components/table/status/status';
import i18n from 'i18n';
import React from 'react';
import { Status } from 'types/status';
import { DateService, DEFAULT_TIMEZONE, formatDate, getReadableString } from 'utils';
import { usePagination } from 'utils/hooks/use-pagination';

const headCells: HeadCell[] = [
  { id: 'id', label: i18n.screens.logistics.table.delivery.headCells.id },
  { id: 'location', label: i18n.screens.logistics.table.delivery.headCells.location },
  { id: 'deliveryDate', label: i18n.screens.logistics.table.delivery.headCells.deliveryDate },
  { id: 'createdAt', label: i18n.screens.logistics.table.delivery.headCells.createdAt },
  { id: 'items', label: i18n.screens.logistics.table.pickup.headCells.items },
  { id: 'status', label: i18n.screens.logistics.table.delivery.headCells.status },
  { id: 'actions', label: '' },
];
const initialValues: PaginatedRequestParams = {
  order: Order.desc,
  page: 1,
  take: 20,
};

const { UTCtoLocalTimezone } = DateService(DEFAULT_TIMEZONE);

export const DeliveriesTable = () => {
  const classes = useStyles();
  const { page, onPageChange } = usePagination();
  const { downloadDeliveryItemsCsv } = useDownloadDeliveryItemsCsv();
  const { data, isLoading, isSuccess } = useGetDeliveries({ ...initialValues, page });

  if (isLoading) {
    return <TableSkeletonLoader />;
  }
  if (!isSuccess) {
    return null;
  }

  const getStatusRowStyle = (status: Status) => {
    switch (status) {
      case Status.SUBMITTED:
      case Status.CONFIRMED:
      case Status.IN_TRANSIT:
        return classes.primary;
      case Status.FAILED:
      case Status.CANCELLED:
      case Status.REJECTED:
        return classes.danger;
      case Status.DRAFT:
        return classes.standard;
      case Status.COMPLETED:
        return classes.muted;
    }
  };

  return (
    <TableContainer>
      <Table>
        <TableHead headCells={headCells} />
        <TableBody>
          {!data.data.length ? (
            <TableNoDataRow />
          ) : (
            data.data.map((item) => (
              <TableRow key={item.id} className={getStatusRowStyle(item.status)}>
                <TableCell>
                  <Text color={TextColor.Secondary} variant='components2' weight='medium'>
                    {getReadableString(item.id)}
                  </Text>
                </TableCell>
                <TableCell>
                  <Text variant='components2' weight='bold'>
                    {getReadableString(item?.location?.name)}
                  </Text>
                </TableCell>
                <TableCell>
                  <Text color={TextColor.Secondary} variant='components2' weight='medium'>
                    {item.deliveryDate
                      ? formatDate({
                          date: UTCtoLocalTimezone(item.deliveryDate),
                          dateFormat: 'longFullDate',
                        })
                      : getReadableString()}
                  </Text>
                </TableCell>
                <TableCell>
                  <Text color={TextColor.Secondary} variant='components2' weight='medium'>
                    {item.createdAt
                      ? formatDate({
                          date: UTCtoLocalTimezone(item.createdAt),
                          dateFormat: 'shortMonthDay',
                        })
                      : getReadableString()}
                  </Text>
                </TableCell>
                <TableCell>
                  {item.status !== Status.DRAFT && (
                    <Button
                      icon={<LoadIcon variant='download' fill='#98A2B3' />}
                      size='small'
                      variant='ghost'
                      onClick={() => downloadDeliveryItemsCsv(item.id)}
                    />
                  )}
                </TableCell>
                <TableCell>
                  <StatusCell status={item.status} />
                </TableCell>
                <TableCell>
                  <Actions type={ActionsCellType.DELIVERY} status={item.status} id={item.id} />
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
      <TablePagination meta={data.meta} onPageChange={onPageChange} />
    </TableContainer>
  );
};
