import { clearStorage, getAccessToken } from 'api/axios-instance';
import { useGetMe } from 'features/users/use-cases/get-me';
import { queryClient } from 'index';
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { ROUTES } from 'routes/constants';
import { User } from 'types/user';

export type ContextValue = {
  user: User | null;
  status: 'error' | 'loading' | 'success';
  logout: () => void;
};

export const UserContext = createContext<ContextValue | null>(null);

export const useUserContext = () => {
  const context = useContext(UserContext);

  if (!context) {
    throw new Error('You tried to use UserContext outside the scope of the UserContextProvider.');
  }

  return context;
};

export function UserContextProvider({ children }: { children: ReactNode }) {
  const [user, setUser] = useState<User | null>(null);
  const isLoggedIn = getAccessToken();

  const { data, status } = useGetMe({
    enabled: Boolean(isLoggedIn),
  });

  useEffect(() => {
    if (data) {
      const newUser: User = {
        email: data.email,
        phone: data.phone || '',
        firstName: data?.firstName || '',
        lastName: data?.lastName || '',
        username: data.username || '',
        id: data?.id || '',
        roles: data.roles || [],
        enabled: Boolean(data.enabled),
        emailVerified: Boolean(data.emailVerified),
        customAttributes: {
          isActive: data.customAttributes.isActive,
          lang: data.customAttributes.lang,
        },
      };
      setUser(newUser);
    }
  }, [data]);

  const logout = useCallback(() => {
    clearStorage();
    queryClient.clear();
    window.location.href = ROUTES.LOGIN;
  }, []);

  const contextValue = useMemo(
    () =>
      ({
        status,
        user,
        logout,
      }) as ContextValue,
    [status, user, logout],
  );

  return <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>;
}
