import { createStyles } from '@stryberventures/gaia-react.theme';

export default createStyles((theme) => ({
  container: {
    display: 'flex',
    height: '100%',
    backgroundColor: theme.colors.neutralGray.extraDark900,
  },
  sidebar: {
    height: '100%',
    padding: [28, 22],
    boxSizing: 'border-box',
    fontFamily: theme.secondaryFont,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  sidebarLogoContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 33,
    textDecoration: 'none',
  },
  sidebarTitle: {
    display: 'block',
    color: theme.colors.contrast.white,
    fontSize: 23,
    fontWeight: 500,
    letterSpacing: -0.45,
    marginLeft: theme.spacing[4],
  },
  menuWrapper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  sectionTitle: {
    display: 'block',
    textTransform: 'uppercase',
    fontFamily: theme.secondaryFont,
    padding: 8,
    fontWeight: 700,
    fontSize: 13,
    lineHeight: '150%',
    letterSpacing: 1.82,
    color: theme.colors.text.secondary,
  },
  sidebarItem: {
    cursor: 'pointer',
    borderRadius: 5,
    padding: theme.spacing[8],
    gap: theme.spacing[8],
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    color: theme.colors.neutralGray.main500,
    '& path': {
      fill: theme.colors.neutralGray.main500,
    },
    '&:hover': {
      color: theme.colors.contrast.white,
      '& path': {
        fill: theme.colors.contrast.white,
      },
    },
  },
  sidebarItemActive: {
    color: theme.colors.contrast.white,
    backgroundColor: theme.colors.neutralGray.extraDark800,
    '& path': {
      fill: theme.colors.contrast.white,
    },
  },
  iconWrapper: {
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  role: {
    marginLeft: 'auto',
  },
}));
