import useStyles from 'components/layout/styles';
import Sidebar from 'components/sidebar/sidebar';
import { useGetMe } from 'features/users/use-cases/get-me';
import { Outlet } from 'react-router-dom';

const Layout = () => {
  const classes = useStyles();

  // User info is required for all app authenticated pages, so it's fetched in a Layout used for them.
  // We can not fetch it after login, because on refresh page, data will be lost.
  useGetMe();

  return (
    <div className={classes.root}>
      <Sidebar />
      <main>
        <Outlet />
      </main>
    </div>
  );
};

export default Layout;
