import Text from '@stryberventures/gaia-react.text';
import { ClockIcon } from 'components/Icons/ClockIcon/ClockIcon';
import i18n from 'i18n';
import React from 'react';

import useStyles from './styles';

export const NotActivatedMessage: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <ClockIcon />
      <div className={classes.message}>
        <Text variant='h2' weight='bold'>
          {i18n.screens.accountNotActivated.title}
        </Text>
        <Text variant='components1'>{i18n.screens.accountNotActivated.description}</Text>
      </div>
    </div>
  );
};
