import i18n from 'i18n';

export const PriceService = () => {
  const getPrice = (priceCents: number) => {
    if (isNaN(priceCents)) return '-';
    return (priceCents / 100).toFixed(2);
  };

  const getPriceWithCurrency = (priceCents: number) =>
    `${getPrice(priceCents)} ${i18n.currency.sar}`;

  const getMonthlyMeterRequestedPrice = (price: number, metersRequested: number) => {
    const monthlyPrice = price * metersRequested;
    return monthlyPrice.toFixed(2);
  };

  const getStorageTypePrice = (priceCents: number, storageTypeName: string, storageUnit?: string) =>
    `${storageTypeName} (${getPrice(priceCents)}${i18n.currency.sar}/${storageUnit}/${
      i18n.monthAbbreviation
    })`;

  return {
    getPrice,
    getPriceWithCurrency,
    getMonthlyMeterRequestedPrice,
    getStorageTypePrice,
  };
};
