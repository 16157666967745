import { createStyles, toRem } from '@stryberventures/gaia-react.theme';

export default createStyles((theme) => ({
  signUpVerification: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    backgroundColor: theme.colors.background.white,
    transition: 'background-color .3s ease-out',
    padding: `${theme.spacing[48]} 0 ${theme.spacing[80]}`,
    height: '100vh',
    boxSizing: 'border-box',
    '*, *:after, *:before': {
      boxSizing: 'inherit',
    },
  },
  successVerification: {
    backgroundColor: theme.colors.primary.main500,
  },
  errorVerification: {
    backgroundColor: theme.colors.error.main500,
  },
  screen: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: toRem(560),
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.colors.contrast.white,
    '& [class*=button]': {
      backgroundColor: theme.colors.contrast.white,
      color: theme.colors.primary.main500,
    },
  },
  title: {
    width: '100%',
    marginBottom: theme.spacing[24],
    fontSize: 28,
    fontFamily: theme.secondaryFont,
    fontWeight: 700,
  },
  description: {
    color: theme.colors.text.disabled,
    marginBottom: theme.spacing[48],
  },
  [`@media (max-width: ${theme.breakpoints.md}px)`]: {
    signUpVerification: {
      padding: [theme.spacing[48], theme.spacing[16]],
    },
  },
}));
