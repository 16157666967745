// eslint-disable-next-line import/no-anonymous-default-export
export default {
  loading: 'Loading...',
  title: 'Your storage needs, Sorted.',
  menuItems: {
    home: 'Home',
    calls: 'Calls',
    login: 'Login',
  },
  commonErrors: {
    noDataShort: 'N/D',
    sessionExpired: 'Session expired, please login again',
    somethingWentWrong: 'Oops, something went wrong',
  },
  errors: {
    systemFailure: "Something is wrong, but we're working on it!",
    unexpectedError: 'Unexpected error occurred. Please contact the administrator.',
    sessionExpired: 'Session expired, please login again',
  },
  currency: {
    sar: 'SAR',
  },
  sku: 'SKU',
  unit: 'unit',
  units: 'units',
  estimatedAbbreviation: '(Est.)',
  monthAbbreviation: 'mo',
  role: {
    user: 'User',
    admin: 'Admin',
  },
  status: {
    DRAFT: 'Draft',
    SUBMITTED: 'Submitted',
    CONFIRMED: 'Confirmed',
    IN_TRANSIT: 'In Transit',
    CANCELLED: 'Cancelled',
    REJECTED: 'Rejected',
    COMPLETED: 'Completed',
    DELIVERED: 'Delivered',
    FAILED: 'Failed',
  },
  yes: 'Yes',
  no: 'No',
  components: {
    sidebar: {
      title: 'sorted',
      menu: {
        title: 'Menu',
        dashboard: 'Dashboard',
        inventory: 'Inventory',
        logistics: 'Transport',
        createPickup: 'Create Pickup',
      },
    },
    topBar: {
      goBack: 'Go back',
      avatarOptions: {
        logout: 'Logout',
      },
    },
    successfulRequest: {
      title: 'Request sent! You’ll hear from our team soon',
      subtitle: 'This may take up to 12 hours.',
      overview: 'overview',
      contactSupport: 'Contact support',
      dashboardButton: 'Go to dashboard',
      contactSupportEmail: {
        pickup: {
          subject: 'Pickup',
          body: 'Please help me with pickup',
        },
        delivery: {
          subject: 'Delivery',
          body: 'Please help me with delivery',
        },
      },
    },
    table: {
      noData: 'There is no data to display',
      paginationLabel: (from: number, to: number, count: number) =>
        `${from}-${to} of ${count} items`,
    },
    storageItemsList: {
      noItemsMessage: 'No items added',
    },
    truckTypeForm: {
      truckType: {
        placeholder: 'Select truck type',
        label: 'Truck type',
      },
      comment: {
        placeholder: 'Include any manpower or machinery needed',
        label: 'Additional Comments (Optional)',
      },
    },
    transportDate: {
      timeSlot: {
        selectDate: 'Select date',
        contactUs: 'For more slots, contact us at: ',
        supportEmail: 'support@get-sorted.co ',
      },
    },
    location: {
      formFields: {
        locationName: {
          label: 'Location name',
          placeholder: 'Enter location name',
        },
        locationNameSelect: {
          label: 'Location name',
          placeholder: 'Select location name',
        },
        latLong: {
          label: 'Latitude, Longitude',
          placeholder: '0,0',
        },
        submit: 'Save location',
      },
    },
  },
  screens: {
    login: {
      title: 'Log in to your account',
      description: 'Welcome back!',
      email: 'Email',
      password: 'Password',
      checkbox: 'Remember for 30 days',
      forgotPassword: 'Forgot password',
      submit: 'Sign in',
      signUp: 'Sign up',
      signUpDescription: "Don't have an account?",
      errors: {
        email: 'Email is required',
        password: 'Password is required',
        emailInvalid: 'Email incorrect',
      },
      placeholders: {
        email: 'Enter your email',
        password: 'Enter your password',
      },
    },
    signUp: {
      title: 'Sign up',
      description: 'Get sorted!',
      labels: {
        firstName: 'First name',
        lastName: 'Last name',
        email: 'Email',
        phone: 'Phone number',
        password: 'Password',
        confirmPassword: 'Confirm password',
      },
      placeholders: {
        firstName: 'First name',
        lastName: 'Last name',
        email: 'Enter your email',
        phone: 'Enter your phone number',
        password: 'Create a password',
        confirmPassword: 'Confirm your password',
      },
      errors: {
        firstName: 'First name is required',
        lastName: 'Last name is required',
        email: 'Email is required',
        phone: 'Phone number is required',
        emailInvalid: 'Email incorrect',
        password: 'Password is required',
        passwordInvalid:
          'Password should have 8 characters, lower and upper case, numbers and special characters.',
        confirmPassword: "Passwords don't match",
      },
      submit: 'Sign up',
      signIn: 'Sign in',
      signInDescription: 'Already have an account?',
    },
    forgotPassword: {
      title: 'Forgot password',
      description:
        'Enter the email address associated with your account and we’ll send an email with instructions to reset your password in no time!',
      label: 'Email',
      placeholder: 'Enter your email',
      emailError: 'Email is required',
      emailInvalid: 'Email incorrect',
      submit: 'Send password instructions',
      signUp: 'Create a new account',
      successMessage: 'Email sent! Check your email for further steps',
    },
    forgotPasswordConfirm: {
      title: 'Create new password',
      labels: {
        password: 'Create Password',
        confirmPassword: 'Repeat Password',
      },
      placeholders: {
        password: 'Type your password here',
        confirmPassword: 'Type your password here',
      },
      errors: {
        password: 'Password is required',
        passwordInvalid:
          'The password should have 8 characters, lower and upper case, numbers and special characters.',
        confirmPassword: "Passwords don't match",
      },
      hint: 'The password should have atleast 8 characters, lower and upper case, numbers and special characters.',
      submit: 'Save password',
      successMessage: 'Password changed successfully',
    },
    signUpConfirm: {
      title: 'Check your email',
      description: 'Complete account setup by clicking on the activation link we just sent to ',
      resendVerification: 'Resend verification email',
      login: 'Continue to login',
    },
    signUpVerification: {
      successTitle: 'Account verified',
      successDescription:
        'Welcome to Sorted! Now that your account has been set up, you can start managing your inventory the right way, every day.',
      login: 'Go to login',
      errorTitle: 'Oops, something went wrong',
      errorDescription:
        'Please check if verification link is correct or try to resend verification email',
    },
    dashboard: {
      section: {
        overview: {
          title: 'Overview',
          statistics: {
            items: {
              title: 'Current',
              type: 'products',
            },
            request: {
              title: 'In progress',
              type: 'requests',
            },
            chargePrice: {
              title: 'Monthly charges',
              type: 'SAR',
              hint: 'Due by Aug 23',
            },
            chargeDate: {
              title: 'Next cycle in',
              type: 'days',
            },
          },
          links: 'Quick Links',
          newPickup: 'New pickup',
          newDelivery: 'New delivery',
          deliverItems: 'Deliver items',
          paymentButton: 'Make payment',
          invoiceButton: 'View invoice',
        },
        storageUtilization: {
          title: 'Storage utilization',
          tooltip: {
            title: 'Add product',
            content: 'Increases storage capacity',
          },
        },
        table: {
          title: 'In progress',
          headCells: {
            id: 'ID',
            type: 'Type',
            origin: 'Origin',
            destination: 'Destination',
            latestUpdate: 'Latest update',
            estimatedArrival: 'ESTD. arrival',
          },
          requestType: {
            pickup: 'Pickup',
            delivery: 'Delivery',
          },
        },
      },
    },
    pickupLogistics: {
      title: 'New pickup request',
      logisticsPrice: 'Logistics charges',
      errorMessages: {
        latLngIsRequired: 'Lat lng is required',
        latLngIsWrong: 'Lat lng is wrong. Correct format: AB,CD or AB.AB,CD.CD',
        addressIsRequired: 'Address is required',
        weekendsAreForbidden: 'Weekends are not allowed. Please select working day',
      },
      locationTitle: 'Pickup location',
      transportTitle: 'Collection time',
    },
    pickupOverview: {
      title: 'Edit pickup request',
      divider: {
        transport: 'Transport',
      },
      submit: 'Save changes',
      formFields: {
        status: {
          label: 'Request status',
          placeholder: 'Select request status',
        },
        statusComment: {
          label: 'Change status comment',
          placeholder: 'Change status comment',
        },
        storageType: {
          label: 'Storage type',
          placeholder: 'Select storage type',
        },
        realStorage: {
          label: 'Final storage',
          placeholder: 'Final storage',
        },
        truckType: {
          label: 'Truck type',
          placeholder: 'Select truck type',
        },
        pickupTime: {
          label: 'Pickup time',
          placeholder: 'Select pickup time',
        },
        pickupDate: {
          label: 'Pickup date',
          placeholder: 'Select pickup date',
        },
        comment: {
          label: 'Additional comment',
          placeholder: 'Additional comment',
        },
      },
    },
    pickupStorage: {
      title: 'New pickup request',
      continueButton: 'Continue',
      steps: {
        pickupItems: 'Choose items to add',
        logistics: 'Plan logistics',
      },
      storagePrice: 'Estimated storage price',
      requestedStorage: {
        storageType: {
          title: 'Choose storage type',
          subtitle: 'Total storage required (Est.)',
          hint: 'View unused storage',
        },
        storageSize: {
          title: 'Total storage required (Est.)',
          subtitle: 'True values will be measured at the warehouse',
        },
        tooltip: 'Storage left to use in current billing',
      },
      errorMessages: {
        storageType: 'Please choose storage type',
        dateIsRequired: 'Pickup date is required',
        dateIsInvalid: 'Pickup date is invalid',
        dateMinValue: 'Pickup min date is:',
      },
      addItemForm: {
        formControl: {
          title: 'Items to store',
        },
        newRadioButton: { label: 'Create new' },
        existingRadioButton: { label: 'Choose from my items' },
        bulkUploadRadioButton: {
          label: 'Bulk upload',
        },
        bulkUpload: {
          buttonLabel: 'Upload CSV with “name”, “SKU”, and “quantity”',
          successMessage: 'Items were successfully added',
          errorMessage: 'No items were added',
        },
        addNewItemForm: {
          name: {
            placeholder: 'Item name',
            errorMessages: {
              nameIsRequired: 'Item name is required',
            },
          },
          sku: {
            placeholder: 'Item SKU',
            errorMessages: {
              skuIsRequired: 'Item SKU is required',
            },
          },
          quantity: {
            errorMessages: {
              quantityIsRequired: 'Quantity is required',
              minQuantity: 'Quantity should be more than 0',
            },
          },
        },
        addExistingItemForm: {
          placeholders: {
            selectItem: 'Item name or SKU',
          },
        },
      },
      pickupItemList: {
        title: 'Items to pickup',
        pickupItem: {
          deleteButton: 'Delete',
        },
      },
    },
    pickupCreatedInfo: {
      title: 'Pickup',
      pickupInfo: {
        size: 'Item size',
        location: 'Pickup location',
        date: 'Collection time',
        transportPrice: 'Transport charges',
        storagePrice: 'Storage charges',
        estimationDescription: 'This is an estimate based on your entered values.',
        storageSpace: 'Storage space',
        charge: 'charge',
      },
    },
    deliveryLogistics: {
      title: 'New delivery request',
      locationTitle: 'Delivery location',
      transportTitle: 'Delivery time',
    },
    deliveryOverview: {
      title: 'Edit delivery request',
      divider: {
        transport: 'Transport',
      },
      submit: 'Save changes',
      formFields: {
        status: {
          label: 'Request status',
          placeholder: 'Select request status',
        },
        statusComment: {
          label: 'Change status comment',
          placeholder: 'Change status comment',
        },
        storageType: {
          label: 'Storage type',
          placeholder: 'Select storage type',
        },
        truckType: {
          label: 'Truck type',
          placeholder: 'Select truck type',
        },
        pickupTime: {
          label: 'Delivery time',
          placeholder: 'Select delivery time',
        },
        pickupDate: {
          label: 'Delivery date',
          placeholder: 'Select delivery date',
        },
        comment: {
          label: 'Additional comment',
          placeholder: 'Additional comment',
        },
      },
    },
    deliveryCreatedInfo: {
      title: 'Delivery',
      deliveryInfo: {
        location: 'Delivery location',
        date: 'Delivery time',
        transportPrice: 'Transport charges',
      },
    },
    accountNotActivated: {
      title: 'Your account is almost ready',
      description: 'Please check back soon while we finish setting up your pricing agreements',
    },
    logistics: {
      modal: {
        deleteRequest: {
          title: 'Are you sure to delete a request?',
        },
      },
      table: {
        title: 'Transport requests',
        buttons: {
          newPickup: 'New pickup',
          pickups: 'Pickups',
          deliveries: 'Deliveries',
        },
        pickup: {
          headCells: {
            id: 'ID',
            location: 'Pickup location',
            storage: 'Storage',
            pickupDate: 'Pickup time',
            createdAt: 'Request created',
            items: 'Items',
            status: 'Status',
          },
        },
        delivery: {
          headCells: {
            id: 'ID',
            location: 'Delivery location',
            deliveryDate: 'Delivery time',
            createdAt: 'Request created',
            status: 'Status',
          },
        },
        rowsCells: {
          storage: {
            hint: 'This is your estimated storage space. True values are calculated at the warehouse.',
          },
        },
      },
    },
    deliveryStorage: {
      title: 'New delivery request',
      steps: {
        items: 'Choose items to add',
        logistics: 'Plan logistics',
      },
      requestedStorage: {
        storageType: {
          title: 'Choose storage type',
          subtitle: 'Where items are currently stored',
          hint: 'To choose a different storage type, please remove items to deliver or create new delivery request',
        },
      },
      addItemForm: {
        title: 'Add items to deliver',
        hint: 'Please choose storage type first',
        select: {
          placeholder: 'Item name or SKU',
        },
        quantity: {
          errorMessage: (maxQuantity: number) => `Stored only ${maxQuantity} items`,
        },
      },
      deliveryItemsList: {
        title: 'Items to deliver',
        deliveryItem: {
          deleteButton: 'Delete',
          maxQuantityError: (quantity: number) => `You only store ${quantity} items in warehouse`,
        },
      },
    },
    inventory: {
      headerTitle: 'Storage needs, Sorted.',
      searchPlaceholder: 'Search',
      title: 'Your Inventory',
      table: {
        headCells: {
          photo: 'Photo',
          sku: 'Item SKU',
          name: 'Item name',
          quantity: 'Quantity',
          warehouseType: 'Storage type',
        },
      },
      imageModal: {
        replaceImage: 'Replace image',
        viewFullImage: 'View full image',
      },
    },
  },
};
