import { addDays } from 'date-fns';
import { startOfDayUTC } from 'utils';

export const getWarehouseTomorrowOpeningHour = (minHourUtc: number) => {
  const startOfToday = startOfDayUTC();

  const minDate = addDays(startOfToday, 1);
  minDate.setUTCHours(minHourUtc);

  return minDate;
};
