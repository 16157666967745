import { CheckIcon } from '@stryberventures/gaia-react.icons';
import { ImagePlaceholderIcon } from 'components/Icons';
import LoadingButton from 'components/LoadingButton';
import { Text, TextColor } from 'components/text/text';
import i18n from 'i18n';
import React from 'react';
import { Multimedia } from 'types/multimedia';
import { getPreviewImageUrl } from 'utils/getPreviewImageUrl';

import useStyles from './styles';

type StorageItemProps = {
  name: string;
  sku: string;
  multimedia: Multimedia[];
  quantityComponent: React.ReactNode;
  deleteItemComponent: React.ReactNode;
  isSubmitButtonDisabled: boolean;
  isSubmitButtonLoading: boolean;
  onSubmitButtonClick: () => void;
};

export const StorageItem: React.FC<StorageItemProps> = ({
  name,
  sku,
  multimedia,
  quantityComponent,
  isSubmitButtonDisabled,
  isSubmitButtonLoading,
  onSubmitButtonClick,
  deleteItemComponent,
}) => {
  const classes = useStyles();

  const renderImage = (multimedia: Multimedia[]) => {
    if (multimedia && multimedia[0]) {
      const imageUrl = getPreviewImageUrl(multimedia[0]);
      return (
        <div
          style={{
            backgroundImage: `url('${imageUrl}')`,
          }}
          className={classes.image}
        />
      );
    }

    return <ImagePlaceholderIcon />;
  };

  return (
    <li className={classes.item}>
      {renderImage(multimedia)}
      <div className={classes.titleWrapper}>
        <Text variant='components2' weight='medium' color={TextColor.Tint}>
          {i18n.sku}: {sku}
        </Text>
        <Text variant='body1' weight='medium'>
          {name}
        </Text>
      </div>
      {quantityComponent}
      <LoadingButton
        disabled={isSubmitButtonDisabled}
        icon={<CheckIcon />}
        size='small'
        className={classes.saveQuantityButton}
        onClick={onSubmitButtonClick}
        loading={isSubmitButtonLoading}
      />
      {deleteItemComponent}
    </li>
  );
};
