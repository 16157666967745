import classNames from 'classnames';
import { ListIcon, TableIcon } from 'components/Icons';
import React from 'react';

import useStyles from './styles';

export enum InventoryViewType {
  table = 'table',
  list = 'list',
}

type ViewSwitcherProps = {
  view: InventoryViewType;
  setView: React.Dispatch<React.SetStateAction<InventoryViewType>>;
};

export const ViewSwitcher: React.FC<ViewSwitcherProps> = ({ view, setView }) => {
  const classes = useStyles();
  return (
    <div className={classes.switchContainer}>
      <div
        className={classNames(classes.viewVariant, classes.viewTableVariant, {
          [classes.activeTableView]: view === InventoryViewType.table,
        })}
        onClick={() => setView(InventoryViewType.table)}
      >
        <TableIcon />
      </div>
      <div
        className={classNames(classes.viewVariant, classes.viewListVariant, {
          [classes.activeListView]: view === InventoryViewType.list,
        })}
        onClick={() => setView(InventoryViewType.list)}
      >
        <ListIcon />
      </div>
    </div>
  );
};
