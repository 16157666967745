import { getLatLng, getLatLngString } from 'components/google-maps/get-lat-lng';
import { GoogleMaps } from 'components/google-maps/google-maps';
import { useLocationContext } from 'components/location/location-context';
import { LocationMode } from 'components/location/types';
import React, { useCallback, useEffect, useState } from 'react';

const markers: google.maps.Marker[] = [];

export const Map = () => {
  const { setPin, mode, pin } = useLocationContext();
  const [map, setMap] = useState<google.maps.Map | null>(null);

  const onload = (map: google.maps.Map) => {
    setMap(map);
    const canCreateMarkers = mode === LocationMode.Create;
    if (canCreateMarkers) {
      map.addListener('click', (e: { latLng: google.maps.LatLng }) => {
        placeMarkerAndPanTo(e.latLng, map);
      });
    }
  };

  const createMarker = useCallback((map: google.maps.Map, latLng: google.maps.LatLng) => {
    removeMarkers();
    const marker = new google.maps.Marker({
      position: latLng,
      map: map,
    });
    markers.push(marker);
    map.panTo(latLng);
  }, []);

  const removeMarkers = () => {
    markers.map((marker) => marker.setMap(null));
  };

  const placeMarkerAndPanTo = (latLng: google.maps.LatLng, map: google.maps.Map) => {
    createMarker(map, latLng);
    setPin(
      getLatLngString({
        lat: latLng.lat(),
        lng: latLng.lng(),
      }),
    );
  };

  useEffect(() => {
    if (pin && map) {
      const latLng = new google.maps.LatLng(getLatLng(pin));
      createMarker(map, latLng);
    }
  }, [pin, createMarker, map]);

  const center = getLatLng(pin);
  return (
    <article>
      <GoogleMaps center={center} onLoad={onload} />
    </article>
  );
};
