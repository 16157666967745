import { addMonths, isValid, parseISO } from 'date-fns';
import { getWarehouseNextAvailableDate } from 'features/warehouse/utils';
import i18n from 'i18n';
import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';
import { isSaudiWeekend, startOfDayUTC } from 'utils';

export const useCalendarHook = (minHour: number, maxHour: number) => {
  const [date, setDate] = useState<string | null>(null);
  const [minDate] = useState(getWarehouseNextAvailableDate(minHour, maxHour));
  const maxDate = addMonths(startOfDayUTC(), 6).toISOString();

  const parseAndSaveDate = (date: string | null) => {
    const isDateValid = isValid(parseISO(date || ''));

    if (!isDateValid) {
      throw new Error(`Date is invalid: ${date}`);
    }

    if (date && isSaudiWeekend(new Date(date))) {
      enqueueSnackbar(i18n.screens.pickupLogistics.errorMessages.weekendsAreForbidden, {
        variant: 'warning',
      });
      return null;
    }

    setDate(date);
  };

  return {
    date,
    minDate,
    maxDate,
    parseAndSaveDate,
  };
};
