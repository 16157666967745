import { useMutation } from '@tanstack/react-query';
import { handleError } from 'api/error-handling';
import {
  DeleteDeliveryRequestParams,
  DeliveryApiService,
} from 'features/delivery/api/delivery-api.service';
import { deliveriesQueryKeys } from 'features/delivery/use-cases/query-keys';
import { queryClient } from 'index';
import { enqueueSnackbar } from 'notistack';

const { deleteDelivery } = DeliveryApiService();

export const useDeleteDelivery = () => {
  return useMutation(
    (params: DeleteDeliveryRequestParams) => {
      return deleteDelivery(params);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(deliveriesQueryKeys.lists());
      },
      onError: (error) => {
        const uiError = handleError(error);
        enqueueSnackbar(uiError, { variant: 'error' });
      },
    },
  );
};
