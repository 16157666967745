import { createStyles } from '@stryberventures/gaia-react.theme';

export default createStyles((theme) => ({
  addNewItemForm: {
    display: 'grid',
    gridTemplateColumns: '1fr 180px 170px 80px',
    gap: theme.spacing[16],
  },
  addItemButton: {
    height: 48,
    '& path': {
      fill: theme.colors.neutralGray.medium300,
    },
  },
}));
