import { SVGProps } from 'react';

export const ChilledWarehouseIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='14'
      height='14'
      viewBox='0 0 14 14'
      fill='none'
      {...props}
    >
      <path
        d='M11.375 12.2509H2.05624C1.88124 12.2509 1.74999 12.1731 1.66249 12.0175C1.57499 11.862 1.58472 11.7113 1.69166 11.5654L4.69583 7.36544C4.77663 7.24759 4.87947 7.15737 5.00435 7.09475C5.12922 7.03215 5.25972 7.00085 5.39583 7.00085H7.90416L11.4771 2.83002C11.6132 2.67446 11.776 2.63107 11.9656 2.69985C12.1552 2.76863 12.25 2.90438 12.25 3.1071V11.3759C12.25 11.6165 12.1643 11.8225 11.993 11.9938C11.8216 12.1652 11.6156 12.2509 11.375 12.2509ZM4.21458 5.54252L2.34791 8.15294C2.27013 8.25016 2.17545 8.31092 2.06386 8.33523C1.95225 8.35953 1.84763 8.3328 1.74999 8.25502C1.65277 8.17724 1.59201 8.08086 1.5677 7.96589C1.5434 7.85091 1.57013 7.74312 1.64791 7.64252L3.51458 5.0321C3.59235 4.91544 3.69687 4.82551 3.82812 4.76231C3.95937 4.69912 4.09305 4.66752 4.22916 4.66752H6.70833L9.11458 1.86752C9.19416 1.76641 9.29184 1.71196 9.4076 1.70419C9.52336 1.69641 9.62985 1.73141 9.72708 1.80919C9.8243 1.88696 9.87777 1.98634 9.88749 2.10733C9.89722 2.22832 9.86319 2.33797 9.78541 2.43627L7.37916 5.23627C7.30138 5.33349 7.20416 5.40884 7.08749 5.46231C6.97083 5.51578 6.84444 5.54252 6.70833 5.54252H4.21458ZM2.90208 11.3759H11.375V4.28835L8.56041 7.5696C8.48263 7.66682 8.38784 7.74217 8.27604 7.79564C8.16423 7.84912 8.04027 7.87585 7.90416 7.87585H5.39583L2.90208 11.3759Z'
        fill='#002F89'
      />
    </svg>
  );
};
