import { apiClient, ApiClientConfig } from 'api';
import { REST_API_ENDPOINTS } from 'api/constants';
import { TypedFormData } from 'types/typed-form-data';

export type UploadItemImageParams = {
  userId?: string;
  file: File;
  fileMinified: File;
  itemId: string;
};
export type UploadItemImageDTO = TypedFormData<UploadItemImageParams>;

export const MultimediaApiService = () => {
  const uploadItemImage = async (
    payload: UploadItemImageDTO,
    apiConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient({
      ...apiConfig,
      endpoint: REST_API_ENDPOINTS.MULTIMEDIA,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };

  return { uploadItemImage };
};
