import { DeliveryItemsApiService } from 'features/delivery/api/delivery-items-api.service';
import { useUserId } from 'features/users';

const { downloadDeliveryItemsCsv: downloadDeliveryItemsCsvApi } = DeliveryItemsApiService();

export const useDownloadDeliveryItemsCsv = () => {
  const userId = useUserId();

  const downloadDeliveryItemsCsv = async (id: number) => {
    const response = await downloadDeliveryItemsCsvApi(id, { userId });

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const a = document.createElement('a');

    a.href = url;
    // We have to manually set name, because axios doesn't expose Content-Disposition
    // header which contains filename set in BE
    a.download = `delivery-${id}.csv`;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  };

  return { downloadDeliveryItemsCsv };
};
