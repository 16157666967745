import { useMemo } from 'react';
import { mappedStorageTypes } from 'utils';
import { useGetQueryIdUserPrices } from 'utils/hooks/get-query-id-user-prices';

export const useGetStorageTypes = () => {
  const { data: userPrices, isSuccess } = useGetQueryIdUserPrices();

  const storageTypes = useMemo(
    () => mappedStorageTypes(userPrices?.usersToPrices || [], userPrices?.storageUnit || ''),
    [userPrices?.usersToPrices, userPrices?.storageUnit],
  );

  if (!isSuccess) {
    return {
      storageTypes: [],
      storageUnit: '',
    };
  }

  return { storageTypes, storageUnit: userPrices.storageUnit };
};
