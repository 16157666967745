import { createStyles } from '@stryberventures/gaia-react.theme';

export default createStyles((theme) => ({
  wrapper: {
    display: 'grid',
    gap: theme.spacing[16],
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-around',
  },
}));
