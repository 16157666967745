import { ButtonVariant, CustomButton } from 'components/custom-button/custom-button';
import { Text, TextColor } from 'components/text/text';
import { useCalendarHook } from 'components/transport-date/hooks/use-calendar-hook';
import { PredefinedHours } from 'components/transport-date/predefined-hours/predefined-hours';
import { Support } from 'components/transport-date/support/support';
import { TransportCalendar } from 'components/transport-date/transport-calendar/transport-calendar';
import { getPredefinedHours } from 'components/transport-date/utils/get-predefined-hours';
import { isSameDay } from 'date-fns';
import { getSaudiWeekendsInterval } from 'features/warehouse/utils';
import React, { useEffect } from 'react';
import { DateService, DEFAULT_TIMEZONE, utcDateToDbString } from 'utils';
import { formatDate } from 'utils/format-date';

import useStyles from './styles';

const { getHoursRange } = DateService(DEFAULT_TIMEZONE);

type WarehouseCollectionProps = {
  title: string;
  startTime: string;
  endTime: string;
  initialTime?: string;
  isLoading?: boolean;
  isUpdating?: boolean;
  onUpdate: (newDate: string) => void;
};

export const WarehouseCollection = ({
  title,
  startTime,
  endTime,
  initialTime,
  isLoading,
  isUpdating,
  onUpdate,
}: WarehouseCollectionProps) => {
  const styles = useStyles();

  const hoursRange = getHoursRange(startTime, endTime);
  const openingHour = hoursRange[0];
  const closingHour = hoursRange[hoursRange.length - 1] || -1;

  if (!openingHour || !closingHour) {
    throw new Error(`Min hour or nax hour is not valid min:${openingHour}, max:${closingHour} `);
  }

  const {
    date: calendarHookDate,
    minDate,
    maxDate,
    parseAndSaveDate,
  } = useCalendarHook(openingHour, closingHour);
  const hours = getPredefinedHours(hoursRange);
  const isNearestDaySelected = calendarHookDate
    ? isSameDay(new Date(calendarHookDate), new Date(minDate))
    : false;

  useEffect(() => {
    if (initialTime) {
      parseAndSaveDate(initialTime);
    }
  }, [initialTime, parseAndSaveDate]);

  const updatePickupDate = (newDate: Date | null) => {
    if (newDate) {
      const formatted = utcDateToDbString(newDate);
      onUpdate(formatted);
    }
  };

  const isDisabled = isLoading || isUpdating;

  const onNearestDayDateSelection = () => {
    parseAndSaveDate(minDate);
    updatePickupDate(new Date(minDate));
  };

  const onHourButtonDateSelection = (utcHour: number) => {
    if (!calendarHookDate) return null;

    const utcYear = new Date(calendarHookDate).getUTCFullYear();
    const utcMonthYear = new Date(calendarHookDate).getUTCMonth();
    const utcDayYear = new Date(calendarHookDate).getUTCDate();

    const newDate = new Date(Date.UTC(utcYear, utcMonthYear, utcDayYear, utcHour));

    parseAndSaveDate(newDate.toISOString());
    updatePickupDate(newDate);
  };

  const onCalendarDateSelection = (calendarDate: Date | null) => {
    if (!calendarDate) return null;

    if (!calendarHookDate) {
      const newDate = new Date(
        Date.UTC(
          calendarDate.getFullYear(),
          calendarDate.getMonth(),
          calendarDate.getDate(),
          openingHour,
        ),
      );

      parseAndSaveDate(newDate.toISOString());
      return updatePickupDate(newDate);
    }

    parseAndSaveDate(calendarDate.toISOString());
    updatePickupDate(calendarDate);
  };

  return (
    <div className={styles.wrapper}>
      <Text variant='components1' weight='medium' color={TextColor.Secondary}>
        {title}
      </Text>
      <div className={styles.controls}>
        <CustomButton
          disabled={isDisabled}
          design={'light'}
          color='primary'
          size='small'
          variant={isNearestDaySelected ? ButtonVariant.Contained : ButtonVariant.Outlined}
          onClick={() => onNearestDayDateSelection()}
        >
          {formatDate({ date: new Date(minDate), dateFormat: 'dayOfWeek' })}
        </CustomButton>
        <TransportCalendar
          date={calendarHookDate ? new Date(calendarHookDate) : null}
          minDate={new Date(minDate)}
          maxDate={new Date(maxDate)}
          excludeDates={getSaudiWeekendsInterval()}
          disabled={isDisabled}
          onChange={(calendarDate) => onCalendarDateSelection(calendarDate)}
        />
      </div>
      {hours.length > 0 && (
        <PredefinedHours
          isDisabled={isDisabled || !calendarHookDate}
          predefinedHours={hours}
          onClick={(hour) => onHourButtonDateSelection(hour)}
          selectedHour={calendarHookDate ? new Date(calendarHookDate).getUTCHours() : null}
        />
      )}
      <Support />
    </div>
  );
};
