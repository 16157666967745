import Text from '@stryberventures/gaia-react.text';
import classNames from 'classnames';
import React from 'react';

import useStyles from './styles';

type BadgeProps = {
  children: React.ReactNode;
  mode?: 'primary' | 'neutralGray' | 'success' | 'blue' | 'lightBlue';
  icon?: React.ReactNode;
};

export const Badge: React.FC<BadgeProps> = ({ children, icon, mode = 'primary' }) => {
  const classes = useStyles();
  return (
    <div className={classNames(classes.badgeContainer, classes[mode])}>
      <div className={classes.badgeWrapper}>
        {icon}
        <Text variant='components2'>{children}</Text>
      </div>
    </div>
  );
};
