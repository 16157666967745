import i18n from 'i18n';
import { Status } from 'types/status';

type Keys = keyof typeof Status;
const statusKeys: Keys[] = Object.keys(Status) as Keys[];

const getStatusOptions = (omitKeys: Keys[] = []) => {
  return statusKeys
    .filter((key) => !omitKeys.includes(key))
    .map((key) => {
      const label = i18n.status[key] as Status;
      const value = Status[key];

      if (!label || !value) {
        throw new Error(
          `Status label or value is not recognizable. Received: label:${label}, value:${value}`,
        );
      }

      return {
        label,
        value,
      };
    });
};

export const pickupStatusOptions = getStatusOptions(['DELIVERED']);
export const deliveryStatusOptions = getStatusOptions(['COMPLETED']);
