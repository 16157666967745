import { SVGProps } from 'react';

export const DeliveryIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M13.7667 7.2L11.9867 5.86667C11.6733 5.62667 11.28 5.5 10.8867 5.5H9.16667V4.66667C9.16667 4.02667 8.64 3.5 8 3.5H2.66667C2.02667 3.5 1.5 4.02667 1.5 4.66667V10C1.5 10.64 2.02667 11.1667 2.66667 11.1667H3.58C3.8 11.9333 4.5 12.5 5.33333 12.5C6.16667 12.5 6.86667 11.9333 7.08667 11.1667H9.57333C9.79333 11.9333 10.4933 12.5 11.3267 12.5C12.16 12.5 12.86 11.9333 13.08 11.1667H13.3267C13.9667 11.1667 14.4933 10.64 14.4933 10V8.66667C14.4933 8.09333 14.22 7.54667 13.76 7.2H13.7667ZM2.5 10V4.66667C2.5 4.57333 2.57333 4.5 2.66667 4.5H8C8.09333 4.5 8.16667 4.57333 8.16667 4.66667V10.1667H7.08667C6.86667 9.4 6.16667 8.83333 5.33333 8.83333C4.5 8.83333 3.8 9.4 3.58 10.1667H2.66667C2.57333 10.1667 2.5 10.0933 2.5 10ZM5.33333 11.5C4.87333 11.5 4.5 11.1267 4.5 10.6667C4.5 10.2067 4.87333 9.83333 5.33333 9.83333C5.79333 9.83333 6.16667 10.2067 6.16667 10.6667C6.16667 11.1267 5.79333 11.5 5.33333 11.5ZM11.3333 11.5C10.8733 11.5 10.5 11.1267 10.5 10.6667C10.5 10.2067 10.8733 9.83333 11.3333 9.83333C11.7933 9.83333 12.1667 10.2067 12.1667 10.6667C12.1667 11.1267 11.7933 11.5 11.3333 11.5ZM13.5 10C13.5 10.0933 13.4267 10.1667 13.3333 10.1667H13.0867C12.8667 9.4 12.1667 8.83333 11.3333 8.83333C10.5 8.83333 9.8 9.4 9.58 10.1667H9.16667V6.5H10.8867C11.0667 6.5 11.2467 6.56 11.3867 6.66667L13.1667 8C13.3733 8.15333 13.5 8.40667 13.5 8.66667V10Z'
        fill='#667085'
      />
    </svg>
  );
};
