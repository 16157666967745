import Input from '@stryberventures/gaia-react.input';
import NumberInput from '@stryberventures/gaia-react.number-input';
import { CustomButton, Divider, FormControl, Select, TableSkeletonLoader } from 'components';
import { Calendar } from 'components/calendar/calendar';
import {
  UpdatePickupDTO,
  UpdatePickupFinalDetailsDTO,
  UpdatePickupStatusDTO,
} from 'features/pickup/api/pickup-api.service';
import { useGetQueryIdPickup, useUpdateQueryIdPickup } from 'features/pickup/hooks';
import { useUpdatePickupFinalDetails } from 'features/pickup/use-cases/update-pickup-final-details';
import { useUpdatePickupStatus } from 'features/pickup/use-cases/update-pickup-status';
import { useGetTruckTypes } from 'features/truck/use-cases';
import { useUserId } from 'features/users';
import i18n from 'i18n';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Status } from 'types/status';
import {
  DateService,
  DEFAULT_TIMEZONE,
  formatDate,
  formatTime,
  getStorageTypeOptions,
  getTruckTypesOptions,
  mergeDateAndTimeToDateObject,
  pickupStatusOptions,
  startOfDayUTC,
  utcDateToDbString,
} from 'utils';
import { useGetStorageTypes } from 'utils/hooks/get-storage-types';

import useStyles from './styles';

const { UTCtoLocalTimezone, localTimezoneToUTC } = DateService(DEFAULT_TIMEZONE);

enum EditPickupFormField {
  Status = 'status',
  StatusComment = 'comment',
  StorageType = 'storageType',
  MetersReal = 'metersReal',
  TruckTypeId = 'truckTypeId',
  PickupTime = 'pickupTime',
  PickupDate = 'pickupDate',
  Comments = 'comments',
}

export type EditPickupFormValues = {
  [EditPickupFormField.Status]: { value: Status; label: Status };
  [EditPickupFormField.StatusComment]: string;
  [EditPickupFormField.StorageType]: { value: number; label: string };
  [EditPickupFormField.MetersReal]: number;
  [EditPickupFormField.TruckTypeId]: { value: number; label: string };
  [EditPickupFormField.PickupTime]: Date;
  [EditPickupFormField.PickupDate]: Date;
  [EditPickupFormField.Comments]: string;
};

export const EditPickupForm = () => {
  const classes = useStyles();

  const { data, isLoading, isSuccess: isGetPickupSuccess } = useGetQueryIdPickup();
  const [defaultValuesSet, setDefaultValuesSet] = useState(false);

  const {
    control,
    handleSubmit,
    reset,
    formState: { dirtyFields },
  } = useForm<EditPickupFormValues>({
    defaultValues: {
      [EditPickupFormField.Status]: { value: data?.status, label: data?.status },
      [EditPickupFormField.StorageType]: {
        value: data?.warehouseTypeId,
        label: data?.warehouseTypeId?.toString(),
      },
      [EditPickupFormField.MetersReal]: data?.metersReal,
      [EditPickupFormField.TruckTypeId]: {
        value: data?.truckTypeId,
        label: data?.truckTypeId?.toString(),
      },
      [EditPickupFormField.PickupTime]: data?.pickupDate
        ? UTCtoLocalTimezone(data.pickupDate)
        : undefined,
      [EditPickupFormField.PickupDate]: data?.pickupDate
        ? UTCtoLocalTimezone(data.pickupDate)
        : undefined,
      [EditPickupFormField.Comments]: data?.comments,
    },
  });
  const userId = useUserId();
  const { storageTypes, storageUnit } = useGetStorageTypes();
  const storageTypeOptions = getStorageTypeOptions(storageTypes, storageUnit);
  const { data: truckTypes, isSuccess: isGetTruckTypesSuccess } = useGetTruckTypes();
  const { mutate: updateStatus, isLoading: isUpdatingStatus } = useUpdatePickupStatus({
    preventNavigate: true,
  });
  const { mutate: updateFinalDetails, isLoading: isUpdatingFinalDetails } =
    useUpdatePickupFinalDetails();
  const truckTypesOptions = getTruckTypesOptions(truckTypes?.data);

  const { mutate: updatePickup, isLoading: isUpdatingPickup } = useUpdateQueryIdPickup({
    preventNavigate: true,
  });

  const isUpdating = isUpdatingStatus || isUpdatingFinalDetails || isUpdatingPickup;
  const isFormStatusPartDirty = dirtyFields[EditPickupFormField.Status];
  const isFormDeliveryPartDirty =
    dirtyFields[EditPickupFormField.TruckTypeId] ||
    dirtyFields[EditPickupFormField.Comments] ||
    dirtyFields[EditPickupFormField.PickupDate] ||
    dirtyFields[EditPickupFormField.PickupTime];
  const isFormFinalDetailsPartDirty = dirtyFields[EditPickupFormField.MetersReal];

  const onSubmit = async (values: EditPickupFormValues) => {
    const newDate = startOfDayUTC();
    if (isFormDeliveryPartDirty) {
      const updatePickupPayload: UpdatePickupDTO = {
        userId,
        warehouseTypeId: values[EditPickupFormField.StorageType]?.value,
        truckTypeId: values[EditPickupFormField.TruckTypeId]?.value,
        comments: values[EditPickupFormField.Comments],
        pickupDate: utcDateToDbString(newDate),
      };

      if (values[EditPickupFormField.PickupDate] && values[EditPickupFormField.PickupTime]) {
        updatePickupPayload.pickupDate = utcDateToDbString(
          localTimezoneToUTC(
            mergeDateAndTimeToDateObject(
              values[EditPickupFormField.PickupDate],
              values[EditPickupFormField.PickupTime],
            ).toISOString(),
          ),
        );
      }
      updatePickup(updatePickupPayload);
    }

    if (data?.id) {
      if (isFormFinalDetailsPartDirty && values[EditPickupFormField.MetersReal]) {
        const updateFinalDetailsPayload: UpdatePickupFinalDetailsDTO = {
          id: data?.id.toString(),
          metersReal: values[EditPickupFormField.MetersReal],
        };
        updateFinalDetails(updateFinalDetailsPayload);
      }

      if (isFormStatusPartDirty && data?.status !== values[EditPickupFormField.Status]?.value) {
        const updateStatusPayload: UpdatePickupStatusDTO = {
          id: data.id.toString(),
          userId,
          status: values[EditPickupFormField.Status]?.value,
        };
        if (values[EditPickupFormField.StatusComment]) {
          updateStatusPayload.comment = values[EditPickupFormField.StatusComment];
        }
        updateStatus(updateStatusPayload);
      }
    }
  };

  useEffect(() => {
    if (isGetPickupSuccess && isGetTruckTypesSuccess && !defaultValuesSet) {
      const status = pickupStatusOptions.find((status) => status.value === data.status);
      const initialTruckType = truckTypesOptions.find((truck) => truck.value === data?.truckTypeId);

      const storageType = storageTypeOptions.find(
        (storage) => storage.value === data.warehouseTypeId,
      );

      reset({
        [EditPickupFormField.Status]: status,
        [EditPickupFormField.StorageType]: storageType,
        [EditPickupFormField.MetersReal]: data?.metersReal,
        [EditPickupFormField.TruckTypeId]: initialTruckType,
        [EditPickupFormField.PickupTime]: data?.pickupDate
          ? UTCtoLocalTimezone(data.pickupDate)
          : undefined,
        [EditPickupFormField.PickupDate]: data?.pickupDate
          ? UTCtoLocalTimezone(data.pickupDate)
          : undefined,
        [EditPickupFormField.Comments]: data?.comments,
      });
      setDefaultValuesSet(true);
    }
  }, [
    data,
    defaultValuesSet,
    isGetPickupSuccess,
    isGetTruckTypesSuccess,
    reset,
    storageTypeOptions,
    truckTypesOptions,
  ]);

  if (isLoading) {
    return <TableSkeletonLoader />;
  }

  return (
    <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
      <FormControl title={i18n.screens.pickupOverview.formFields.status.label}>
        <Controller
          name={EditPickupFormField.Status}
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              isDisabled={isUpdating}
              options={pickupStatusOptions}
              placeholder={i18n.screens.pickupOverview.formFields.status.placeholder}
            />
          )}
        />
      </FormControl>
      <FormControl title={i18n.screens.pickupOverview.formFields.statusComment.label}>
        <Controller
          name={EditPickupFormField.StatusComment}
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              placeholder={i18n.screens.pickupOverview.formFields.statusComment.placeholder}
              fullWidth
            />
          )}
        />
      </FormControl>
      <FormControl title={i18n.screens.pickupOverview.formFields.storageType.label}>
        <Controller
          name={EditPickupFormField.StorageType}
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              isDisabled
              placeholder={i18n.screens.pickupOverview.formFields.storageType.placeholder}
            />
          )}
        />
      </FormControl>
      <FormControl title={i18n.screens.pickupOverview.formFields.realStorage.label}>
        <Controller
          name={EditPickupFormField.MetersReal}
          control={control}
          render={({ field }) => (
            <NumberInput
              min={0}
              controlled
              fullWidth
              {...field}
              disabled={isUpdating}
              placeholder={i18n.screens.pickupOverview.formFields.realStorage.placeholder}
            />
          )}
        />
      </FormControl>
      <Divider text={i18n.screens.pickupOverview.divider.transport} />
      <FormControl title={i18n.screens.pickupOverview.formFields.pickupDate.label}>
        <Controller
          name={EditPickupFormField.PickupDate}
          control={control}
          render={({ field }) => (
            <div className={classes.calendar}>
              <Calendar
                {...field}
                disabled={isUpdating}
                value={field.value && formatDate({ date: field.value, dateFormat: 'date' })}
                date={field.value}
                placeholderText={i18n.screens.pickupOverview.formFields.pickupDate.placeholder}
              />
            </div>
          )}
        />
      </FormControl>
      <FormControl title={i18n.screens.pickupOverview.formFields.pickupTime.label}>
        <Controller
          name={EditPickupFormField.PickupTime}
          control={control}
          render={({ field }) => (
            <div className={classes.calendar}>
              <Calendar
                {...field}
                disabled={isUpdating}
                value={field.value && formatTime({ time: field.value, timeFormat: 'time' })}
                showTimeSelect={true}
                showTimeSelectOnly={true}
                timeIntervals={60}
                dateFormat='h:mm aa'
                date={field.value}
                placeholderText={i18n.screens.pickupOverview.formFields.pickupTime.placeholder}
              />
            </div>
          )}
        />
      </FormControl>
      <FormControl title={i18n.screens.pickupOverview.formFields.truckType.label}>
        <Controller
          name={EditPickupFormField.TruckTypeId}
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              isDisabled={isUpdating}
              options={truckTypesOptions}
              placeholder={i18n.screens.pickupOverview.formFields.truckType.placeholder}
            />
          )}
        />
      </FormControl>
      <FormControl title={i18n.screens.pickupOverview.formFields.comment.label}>
        <Controller
          name={EditPickupFormField.Comments}
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              disabled={isUpdating}
              onChange={(value) => field.onChange(value)}
              fullWidth
              controlled
              value={field.value}
              placeholder={i18n.screens.pickupOverview.formFields.comment.placeholder}
            />
          )}
        />
      </FormControl>

      <CustomButton disabled={isUpdating} variant={'contained'}>
        {i18n.screens.pickupOverview.submit}
      </CustomButton>
    </form>
  );
};
