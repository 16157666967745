import { apiClient, ApiClientConfig, PaginatedResponse } from 'api';
import { REST_API_ENDPOINTS } from 'api/constants';
import { TruckType } from 'types/truck-type';

export type TruckTypesResponse = PaginatedResponse<TruckType>;

export const TruckApiService = () => {
  const getTruckTypes = async (callConfig?: Partial<ApiClientConfig>) => {
    const response = await apiClient<TruckTypesResponse>({
      ...callConfig,
      endpoint: REST_API_ENDPOINTS.TRUCK_TYPES,
    });

    return response.data;
  };

  return {
    getTruckTypes,
  };
};
