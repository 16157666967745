import { PredefinedHour } from 'components/transport-date/predefined-hours/predefined-hours';
import { startOfDay } from 'date-fns';
import { formatTZ } from 'utils/format-date';

export const getPredefinedHours = (hours: number[]): PredefinedHour[] =>
  hours.map((utcHour) => {
    /**
     * Only hour must be correct, that's why we use "new Date()"
     */
    const date = startOfDay(new Date());
    date.setUTCHours(utcHour);

    const label = formatTZ(date, 'time');
    return {
      label,
      hour: utcHour,
    };
  });
