import { QueryFunction, QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { handleError } from 'api';
import {
  GetUsersParams,
  UserApiService,
  UsersResponse,
} from 'features/users/api/users-api-service';
import { isPowerUser } from 'features/users/permissions';
import { userQueryKeys } from 'features/users/use-cases/query-keys';
import { useUserContext } from 'features/users/user-context';
import { enqueueSnackbar } from 'notistack';

const { getUsers } = UserApiService();

export const getUsersQuery = (
  params: GetUsersParams,
  options?: UseQueryOptions<UsersResponse>,
): {
  queryKey: QueryKey;
  queryFn: QueryFunction<UsersResponse>;
  options?: UseQueryOptions<UsersResponse>;
} => ({
  queryKey: userQueryKeys.list(params),
  queryFn: ({ signal }) => getUsers(params, { config: { signal } }),
  ...options,
});

export const useGetUsers = (params: GetUsersParams, options?: UseQueryOptions<UsersResponse>) => {
  const { user } = useUserContext();

  const isEnabled = Boolean(user && isPowerUser(user));

  return useQuery<UsersResponse, Error>(
    getUsersQuery(params, {
      onError: (error) => {
        const uiError = handleError(error);
        enqueueSnackbar(uiError, { variant: 'error' });
      },
      ...options,
      enabled: isEnabled,
    }),
  );
};
