import { CheckIcon } from '@stryberventures/gaia-react.icons';
import NumberInput from '@stryberventures/gaia-react.number-input';
import { FormControl, Select } from 'components';
import LoadingButton from 'components/LoadingButton';
import { useCreateDeliveryItem } from 'features/delivery/use-cases/create-delivery-item';
import i18n from 'i18n';
import React, { useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { Options } from 'react-select';
import { Option } from 'types/option';
import { WarehouseItem } from 'types/warehouse-item';
import { getItemQuantityLabel } from 'utils/get-item-quantity-label';

import useStyles from './styles';

type AddItemFormProps = {
  warehouseItems: WarehouseItem[];
  isStorageTypeSelected: boolean;
};

enum AddItemFormField {
  quantity = 'quantity',
  selectedOption = 'selectedOption',
}

type FormData = {
  [AddItemFormField.quantity]: number;
  [AddItemFormField.selectedOption]: Option | null;
};

const initialValues: FormData = {
  [AddItemFormField.quantity]: 1,
  [AddItemFormField.selectedOption]: null,
};

const mapWarehouseItemsToOptions = (items: WarehouseItem[]) => {
  return items.map((item) => ({
    label: `${item.item.name} (${item.item.sku}) / ${getItemQuantityLabel(item.quantity)}`,
    value: item.itemId,
  }));
};

const getSelectedItem = (items: WarehouseItem[], selectedOption: Option | null) => {
  if (!selectedOption) return null;
  return items.find((item) => item.itemId === selectedOption.value);
};

export const AddItemForm: React.FC<AddItemFormProps> = ({
  warehouseItems,
  isStorageTypeSelected,
}) => {
  const { id } = useParams();
  const { control, watch, handleSubmit, reset, setError } = useForm<FormData>({
    defaultValues: initialValues,
  });
  const [options, setOptions] = React.useState<Options<Option>>([]);
  const selectedOption = watch(AddItemFormField.selectedOption);
  const quantity = watch(AddItemFormField.quantity);
  const itemQuantity = getSelectedItem(warehouseItems, selectedOption)?.quantity;
  const classes = useStyles();

  const { mutate: addDeliveryItem, isLoading, isSuccess } = useCreateDeliveryItem(id);

  const onSubmit: SubmitHandler<FormData> = (data) => {
    if (!data.selectedOption || !data.quantity) return;
    if (typeof data.selectedOption.value !== 'number') return;
    addDeliveryItem({
      deliveryId: Number(id),
      itemId: data.selectedOption.value,
      quantity: data.quantity,
    });
  };

  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleNumberInputChange = (quantity: number, onFieldChange: (...e: any[]) => void) => {
    setError(AddItemFormField.quantity, { message: '' });
    if (itemQuantity && itemQuantity < quantity) {
      setError(AddItemFormField.quantity, {
        message: i18n.screens.deliveryStorage.addItemForm.quantity.errorMessage(itemQuantity),
      });
      return;
    }
    onFieldChange(quantity);
  };

  useEffect(() => {
    setOptions(mapWarehouseItemsToOptions(warehouseItems));
  }, [warehouseItems]);

  useEffect(() => {
    if (isSuccess) {
      reset();
    }
  }, [isSuccess, reset]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.addItemContainer}>
      <FormControl
        title={i18n.screens.deliveryStorage.addItemForm.title}
        hint={!isStorageTypeSelected ? i18n.screens.deliveryStorage.addItemForm.hint : ''}
      >
        <div className={classes.addItemForm}>
          <Controller
            name={AddItemFormField.selectedOption}
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                options={options}
                placeholder={i18n.screens.deliveryStorage.addItemForm.select.placeholder}
                isDisabled={!isStorageTypeSelected}
              />
            )}
          />
          <Controller
            name={AddItemFormField.quantity}
            control={control}
            render={({ field, fieldState }) => (
              <NumberInput
                {...field}
                fullWidth
                quantityCounter
                onChange={(quantity) => handleNumberInputChange(quantity, field.onChange)}
                errorMessage={fieldState.error?.message}
                disabled={!selectedOption}
                max={itemQuantity}
                controlled
              />
            )}
          />
          <LoadingButton
            type='submit'
            icon={<CheckIcon />}
            loading={isLoading}
            className={classes.addItemButton}
            disabled={!quantity || !selectedOption}
          />
        </div>
      </FormControl>
    </form>
  );
};
