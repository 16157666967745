import { UseQueryOptions } from '@tanstack/react-query';
import { GetPickupRequestParams } from 'features/pickup/api/pickup-api.service';
import { useGetPickup } from 'features/pickup/use-cases/get-pickup';
import { defaultTo } from 'ramda';
import { useParams } from 'react-router-dom';
import { Pickup } from 'types/pickup';

export const useGetQueryIdPickup = (options?: UseQueryOptions<Pickup>) => {
  const { id } = useParams();

  if (!id) {
    throw new Error('Can not get pickup, because pickup id in the url is missing');
  }

  const params: GetPickupRequestParams = { id };

  return useGetPickup(params, {
    ...options,
    enabled: defaultTo(options?.enabled, true) && Boolean(id),
  });
};
