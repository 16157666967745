import { GoogleMap, GoogleMapProps, useJsApiLoader } from '@react-google-maps/api';
import env from 'env';
import React from 'react';
import Skeleton from 'react-loading-skeleton';

export const GoogleMaps = ({ ...props }: GoogleMapProps) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: env.googleMapsApiKey,
  });
  const dimensions = {
    width: props.mapContainerStyle?.width || '100%',
    height: props.mapContainerStyle?.height || 400,
  };

  if (!isLoaded) return <Skeleton width={dimensions.width} height={dimensions.height} />;

  return (
    <GoogleMap
      options={{
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: true,
      }}
      mapContainerStyle={dimensions}
      zoom={10}
      {...props}
    />
  );
};
