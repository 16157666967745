import { QueryFunction, QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { handleError } from 'api';
import {
  DeliveriesResponse,
  DeliveryApiService,
  GetDeliveriesRequestParams,
} from 'features/delivery/api/delivery-api.service';
import { useReactQueryPowerUserUserIdManager } from 'features/users';
import { enqueueSnackbar } from 'notistack';

import { deliveriesQueryKeys } from './query-keys';

const { getDeliveries } = DeliveryApiService();

const getDeliveriesQuery = (
  params: GetDeliveriesRequestParams,
  options?: UseQueryOptions<DeliveriesResponse>,
): {
  queryKey: QueryKey;
  queryFn: QueryFunction<DeliveriesResponse>;
  options?: UseQueryOptions<DeliveriesResponse>;
} => ({
  queryKey: deliveriesQueryKeys.list(params),
  queryFn: ({ signal }) => getDeliveries(params, { config: { signal } }),
  ...options,
});

export const useGetDeliveries = (
  params: GetDeliveriesRequestParams,
  options?: UseQueryOptions<DeliveriesResponse>,
) => {
  const { enabled, updatedParams } = useReactQueryPowerUserUserIdManager<
    GetDeliveriesRequestParams,
    DeliveriesResponse
  >(params, options);

  return useQuery<DeliveriesResponse, Error>(
    getDeliveriesQuery(updatedParams, {
      onError: (error) => {
        const uiError = handleError(error);
        enqueueSnackbar(uiError, { variant: 'error' });
      },
      ...options,
      enabled,
    }),
  );
};
