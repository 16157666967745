import { DashboardIcon } from 'components/Icons/DashboardIcon';
import { InventoryIcon } from 'components/Icons/InventoryIcon';
import { LogisticsIcon } from 'components/Icons/LogisticsIcon';
import { Permissions } from 'features/users/permissions';
import i18n from 'i18n';
import { ROUTES } from 'routes/constants';

export const getMenuItems = (permissions: Permissions | null) => {
  return [
    {
      key: ROUTES.HOME,
      label: i18n.components.sidebar.menu.dashboard,
      icon: <DashboardIcon />,
    },
    ...(permissions?.logistics.view
      ? [
          {
            key: ROUTES.LOGISTICS,
            label: i18n.components.sidebar.menu.logistics,
            icon: <LogisticsIcon />,
          },
        ]
      : []),
    ...(permissions?.inventory.view
      ? [
          {
            key: ROUTES.INVENTORY,
            label: i18n.components.sidebar.menu.inventory,
            icon: <InventoryIcon />,
          },
        ]
      : []),
  ];
};
