import Text from '@stryberventures/gaia-react.text';
import LoadingButton from 'components/LoadingButton';
import { useResendVerificationEmail } from 'features/auth/use-cases/resend-verification-email';
import i18n from 'i18n';
import React from 'react';
import { useLocation } from 'react-router-dom';

import useStyles from './styles';

type ISignUpSuccess = object;

export const SignUpConfirm: React.FC<ISignUpSuccess> = () => {
  const location = useLocation();
  const username = location.state.username;

  const { mutate: resendVerification, isLoading } = useResendVerificationEmail();

  const handleResendVerification = () => {
    resendVerification({ username });
  };

  const classes = useStyles();

  return (
    <div className={classes.signUpSuccess}>
      <div className={classes.screen}>
        <div className={classes.content}>
          <Text className={classes.title} align='center'>
            {i18n.screens.signUpConfirm.title}
          </Text>
          <Text className={classes.description} align='center'>
            {i18n.screens.signUpConfirm.description}
            <b>{username}</b>
          </Text>
          <LoadingButton
            className={classes.resendButton}
            loading={isLoading}
            onClick={handleResendVerification}
          >
            {i18n.screens.signUpConfirm.resendVerification}
          </LoadingButton>
        </div>
      </div>
    </div>
  );
};
