import { apiClient, ApiClientConfig } from 'api';
import { REST_API_ENDPOINTS } from 'api/constants';

export type ForgotPasswordConfirmDTO = {
  token: string;
  password: string;
};

export type ForgotPasswordConfirmResponse = unknown;

export type ForgotPasswordDTO = {
  username: string;
};

export type ForgotPasswordResponse = unknown;

export type VerificationDTO = {
  verificationToken: string;
};

export type VerificationResponse = unknown;

export type ResendVerificationDTO = {
  username: string;
};

export type ResendVerificationResponse = unknown;

export type SignUpDTO = {
  username: string;
  password: string;
  phone?: string;
};

export type SignUpResponse = {
  mail_verification_type: 'link';
};

export type LoginDTO = {
  username: string;
  password: string;
};

export type LoginResponse = {
  result: 'TOKENS';
  data: {
    accessToken: string;
    idToken: string;
    refreshToken: string;
  };
};

export const AuthApiService = () => {
  const login = async (payload: LoginDTO, callConfig?: Partial<ApiClientConfig>) => {
    const response = await apiClient<LoginResponse>({
      ...callConfig,
      endpoint: REST_API_ENDPOINTS.LOGIN,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };

  const signUp = async (payload: SignUpDTO, callConfig?: Partial<ApiClientConfig>) => {
    const response = await apiClient<SignUpResponse>({
      ...callConfig,
      endpoint: REST_API_ENDPOINTS.SIGNUP,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };

  const resendVerificationEmail = async (
    payload: ResendVerificationDTO,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<ResendVerificationResponse>({
      ...callConfig,
      endpoint: REST_API_ENDPOINTS.RESEND_VERIFICATION,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };

  const verify = async (payload: VerificationDTO, callConfig?: Partial<ApiClientConfig>) => {
    const response = await apiClient<VerificationResponse>({
      ...callConfig,
      endpoint: REST_API_ENDPOINTS.VERIFICATION,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };

  const forgotPassword = async (
    payload: ForgotPasswordDTO,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<ForgotPasswordResponse>({
      ...callConfig,
      endpoint: REST_API_ENDPOINTS.FORGOT_PASSWORD,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };

  const forgotPasswordConfirm = async (
    payload: ForgotPasswordConfirmDTO,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<ForgotPasswordConfirmResponse>({
      ...callConfig,
      endpoint: REST_API_ENDPOINTS.FORGOT_PASSWORD_CONFIRM,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };

  return {
    login,
    signUp,
    resendVerificationEmail,
    verify,
    forgotPassword,
    forgotPasswordConfirm,
  };
};
