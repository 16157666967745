import Table, { TableBody, TableCell, TableRow } from '@stryberventures/gaia-react.table';
import { HeadCell, TableHead, TableNoDataRow, Text } from 'components';
import { ItemImage } from 'features/warehouse/components/item-image/item-image';
import i18n from 'i18n';
import React from 'react';
import { WarehouseItem } from 'types/warehouse-item';
import { getWarehouseTypeBadge } from 'utils/get-warehouseType-badge';

type InventoryTableProps = {
  warehouseItems: WarehouseItem[];
};

const headCells: HeadCell[] = [
  { id: 'photo', label: i18n.screens.inventory.table.headCells.photo },
  { id: 'sku', label: i18n.screens.inventory.table.headCells.sku },
  { id: 'name', label: i18n.screens.inventory.table.headCells.name },
  { id: 'quantity', label: i18n.screens.inventory.table.headCells.quantity },
  { id: 'warehouseType', label: i18n.screens.inventory.table.headCells.warehouseType },
];

export const InventoryTable: React.FC<InventoryTableProps> = ({ warehouseItems }) => (
  <Table>
    <TableHead headCells={headCells} />
    <TableBody>
      {!warehouseItems.length ? (
        <TableNoDataRow />
      ) : (
        warehouseItems.map((item) => (
          <TableRow key={item.id}>
            <TableCell>
              <ItemImage
                itemName={item.item.name}
                multimedia={item.item.multimedia}
                itemId={item.item.id}
              />
            </TableCell>
            <TableCell>{item.item.sku}</TableCell>
            <TableCell>
              <Text variant='components2' weight='medium'>
                {item.item.name}
              </Text>
            </TableCell>
            <TableCell>{item.quantity}</TableCell>
            <TableCell>{getWarehouseTypeBadge(item.warehouseType.label)}</TableCell>
          </TableRow>
        ))
      )}
    </TableBody>
  </Table>
);
