import { createStyles } from '@stryberventures/gaia-react.theme';

export default createStyles(() => ({
  quantityInput: {
    '& [class*="message"]': {
      position: 'absolute',
      bottom: -25,
      width: '200%',
    },
  },
}));
