import { createStyles } from '@stryberventures/gaia-react.theme';

export default createStyles((theme) => ({
  dangerStatus: {
    color: theme.colors.error.main500,
  },
  completedStatus: {
    color: theme.colors.success.dark600,
    borderRadius: '100px',
    background: theme.colors.success.extraLight50,
    padding: [theme.spacing[8], theme.spacing[4]],
  },
}));
