import { useMutation } from '@tanstack/react-query';
import { handleError } from 'api/error-handling';
import { DeliveryApiService, UpdateDeliveryDTO } from 'features/delivery/api/delivery-api.service';
import { useUserId } from 'features/users';
import { queryClient } from 'index';
import { enqueueSnackbar } from 'notistack';

import { deliveriesQueryKeys } from './query-keys';

const { updateDelivery } = DeliveryApiService();

export const useUpdateDelivery = (deliveryId: string) => {
  const userId = useUserId();

  return useMutation(
    (payload: UpdateDeliveryDTO) => {
      return updateDelivery(deliveryId, { userId, ...payload });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: deliveriesQueryKeys.detail(deliveryId),
        });
        queryClient.invalidateQueries(deliveriesQueryKeys.list({ deliveryId }));
      },
      onError: (error) => {
        const uiError = handleError(error);
        enqueueSnackbar(uiError, { variant: 'error' });
      },
    },
  );
};
