import { useMutation } from '@tanstack/react-query';
import { handleError } from 'api';
import {
  PickupItemsApiService,
  UploadPickupItemsCsvDTO,
} from 'features/pickup/api/pickup-items-api.service';
import { useUserId } from 'features/users';
import i18n from 'i18n';
import { queryClient } from 'index';
import { enqueueSnackbar } from 'notistack';

import { pickupsQueryKeys } from './query-keys';

const { uploadPickupItemsCsv } = PickupItemsApiService();

export const useUploadPickupItemsCsv = (pickupId?: string) => {
  const userId = useUserId();
  return useMutation(
    (payload: UploadPickupItemsCsvDTO) => {
      if (userId) {
        payload.append('userId', userId);
      }
      return uploadPickupItemsCsv(payload, {
        config: { headers: { 'Content-Type': 'multipart/form-data' } },
      });
    },
    {
      onSuccess: () => {
        if (pickupId) {
          queryClient.refetchQueries(pickupsQueryKeys.detail(pickupId.toString()));
          enqueueSnackbar(i18n.screens.pickupStorage.addItemForm.bulkUpload.successMessage, {
            variant: 'success',
          });
        }
      },
      onError: (error) => {
        const uiError = handleError(error);
        enqueueSnackbar(
          `${i18n.screens.pickupStorage.addItemForm.bulkUpload.errorMessage}: ${uiError}`,
          { variant: 'error' },
        );
      },
    },
  );
};
