import { createStyles } from '@stryberventures/gaia-react.theme';

export default createStyles((theme) => ({
  goBackButton: {
    display: 'flex',
    border: 'none',
    borderRadius: 8,
    gap: theme.spacing[4],
    padding: [theme.spacing[4], theme.spacing[8]],
    alignItems: 'center',
    color: theme.colors.neutralGray.main500,
    cursor: 'pointer',
    transition: 'background-color 0.2s ease-in-out',
    '& path': {
      fill: theme.colors.neutralGray.main500,
    },
    '&:hover': {
      backgroundColor: theme.colors.neutralGray.light100,
    },
  },
}));
