import { createStyles } from '@stryberventures/gaia-react.theme';

export default createStyles((theme) => ({
  buttonContainer: {
    '&[class*=button]': {
      borderRadius: 8,
      padding: [theme.spacing[8], theme.spacing[16]],
      boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
      fontFamily: theme.secondaryFont,
      '& path': {
        fill: theme.colors.primary.main500,
      },
    },
  },
  buttonContainerLight: {
    '&[class*=button]': {
      fontSize: '14px',
      fontWeight: '500',
      lineHeight: '120%',
      color: theme.colors.primary.main500,
      borderRadius: 8,
      padding: [theme.spacing[8], theme.spacing[16]],
      boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
      fontFamily: theme.secondaryFont,
      '& path': {
        fill: theme.colors.primary.main500,
      },
    },
  },
  containedLight: {
    '&[class*=button]': {
      color: theme.colors.primary.main500,
      backgroundColor: theme.colors.primary.extraLight50,
      border: `1px solid ${theme.colors.primary.main500}`,
      '&:hover': {
        color: theme.colors.primary.extraLight50,
      },
    },
  },
  outlined: {
    '&[class*=button]': {
      border: [1, 'solid', theme.colors.neutralGray.medium300],
      color: theme.colors.neutralGray.extraDark800,
    },
  },
  outlinedLight: {
    '&[class*=button]': {
      border: [1, 'solid', theme.colors.neutralGray.medium300],
      color: theme.colors.neutralGray.main500,
      '&:hover': {
        color: theme.colors.primary.main500,
      },
    },
  },
  primary: {
    '&[class*=button]': {
      '& path': {
        fill: theme.colors.primary.main500,
      },
    },
  },
  grey: {
    '&[class*=button]': {
      '& path': {
        fill: theme.colors.neutralGray.dark600,
      },
    },
  },
}));
