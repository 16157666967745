import Table, {
  TableBody,
  TableCell,
  TableRow,
  TableSection,
} from '@stryberventures/gaia-react.table';
import {
  Badge,
  HeadCell,
  TableContainer,
  TableHead,
  TableNoDataRow,
  TableSkeletonLoader,
  Text,
  TextColor,
} from 'components';
import { ButtonGroup } from 'components/button-group/button-group';
import { DeliveryIcon } from 'components/Icons/DeliveryIcon';
import { PickupIcon } from 'components/Icons/PickupIcon';
import useStyles from 'components/table/table.styles';
import { useStatisticsInProgress } from 'features/dashboard/use-cases/statistics-in-progress';
import i18n from 'i18n';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Option, Value } from 'types/option';
import { StatisticsInProgressItem } from 'types/statistics';
import { DateService, DEFAULT_TIMEZONE, formatDate, getReadableString } from 'utils';

type InProgressRequest = {
  type: string;
} & StatisticsInProgressItem;

const headCells: HeadCell[] = [
  { id: 'type', label: i18n.screens.dashboard.section.table.headCells.type },
  { id: 'id', label: i18n.screens.dashboard.section.table.headCells.id },
  { id: 'origin', label: i18n.screens.dashboard.section.table.headCells.origin },
  { id: 'destination', label: i18n.screens.dashboard.section.table.headCells.destination },
  { id: 'latestUpdate', label: i18n.screens.dashboard.section.table.headCells.latestUpdate },
  {
    id: 'estimatedArrival',
    label: i18n.screens.dashboard.section.table.headCells.estimatedArrival,
  },
  // TODO: SOR-121 Update and uncomment when backend is ready
  // { id: 'more', label: '' },
];

const { UTCtoLocalTimezone } = DateService(DEFAULT_TIMEZONE);

export const DashboardTable: React.FC = () => {
  const { data, isLoading } = useStatisticsInProgress({});
  const pickupsArray = useMemo<InProgressRequest[]>(
    () => data?.pickups.map((pickup) => ({ type: 'pickup', ...pickup })) || [],
    [data?.pickups],
  );
  const deliveriesArray = useMemo<InProgressRequest[]>(
    () => data?.deliveries.map((delivery) => ({ type: 'delivery', ...delivery })) || [],
    [data?.deliveries],
  );
  const [requestsArray, setRequestArray] = useState<InProgressRequest[]>([]);
  const [activeType, setActiveType] = useState<Value>('all');
  const classes = useStyles();

  const requestTypeOptions: Option[] = [
    { label: 'All requests', value: 'all' },
    { label: 'Pickups', value: 'pickups' },
    { label: 'Deliveries', value: 'deliveries' },
  ];

  useEffect(() => {
    setRequestArray([...pickupsArray, ...deliveriesArray]);
  }, [data, deliveriesArray, pickupsArray]);

  const handleRequestTypeChange = useCallback(
    (value: Value) => {
      setActiveType(value);
      if (value === 'all') {
        setRequestArray([...pickupsArray, ...deliveriesArray]);
      } else if (value === 'pickups') {
        setRequestArray(pickupsArray);
      } else if (value === 'deliveries') {
        setRequestArray(deliveriesArray);
      }
    },
    [deliveriesArray, pickupsArray],
  );

  if (isLoading) {
    return <TableSkeletonLoader />;
  }

  return (
    <TableContainer>
      <TableSection>
        <div className={classes.tableHeader}>
          <Text variant='h4' weight='bold'>
            {i18n.screens.dashboard.section.table.title}
          </Text>
          <ButtonGroup
            options={requestTypeOptions}
            onChange={handleRequestTypeChange}
            activeValue={activeType}
          />
        </div>
      </TableSection>
      <Table>
        <TableHead headCells={headCells} />
        <TableBody>
          {!requestsArray.length ? (
            <TableNoDataRow />
          ) : (
            requestsArray.map((item) => (
              <TableRow key={item.id}>
                <TableCell>
                  <Badge
                    icon={item.type === 'pickup' ? <PickupIcon /> : <DeliveryIcon />}
                    mode={item.type === 'pickup' ? 'primary' : 'neutralGray'}
                  >
                    {item.type === 'pickup'
                      ? i18n.screens.dashboard.section.table.requestType.pickup
                      : i18n.screens.dashboard.section.table.requestType.delivery}
                  </Badge>
                </TableCell>
                <TableCell>
                  <div className={classes.tableOriginCellWrapper}>
                    <Text variant='components2' weight='medium'>
                      {getReadableString(item.id)}
                    </Text>
                  </div>
                </TableCell>
                <TableCell>
                  <div className={classes.tableOriginCellWrapper}>
                    <Text variant='components2' weight='medium'>
                      {getReadableString(item.origin)}
                    </Text>
                  </div>
                </TableCell>
                <TableCell>
                  <Text variant='components2' weight='medium'>
                    {getReadableString(item.destination)}
                  </Text>
                </TableCell>
                <TableCell>
                  <Text variant='components2' color={TextColor.Secondary}>
                    {getReadableString(item.latestUpdate)}
                  </Text>
                </TableCell>
                <TableCell>
                  <Text variant='components2' color={TextColor.Secondary}>
                    {item.estimatedArrival
                      ? formatDate({
                          date: UTCtoLocalTimezone(item.estimatedArrival),
                          dateFormat: 'dateTime',
                        })
                      : getReadableString()}
                  </Text>
                </TableCell>
                {/* TODO: SOR-121 Update and uncomment when backend is ready */}
                {/* <TableCell> */}
                {/*   <MoreIcon /> */}
                {/* </TableCell> */}
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
