import { getAccessToken } from 'api/axios-instance';
import { ReactElement } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { ROUTES } from './constants';

export const UnauthenticatedRoute = ({ children }: { children: ReactElement }) => {
  const isLoggedIn = getAccessToken();
  const location = useLocation();

  const origin = location.state?.from?.pathname || ROUTES.HOME;

  if (isLoggedIn) {
    return <Navigate to={origin} replace />;
  }

  return children;
};
