import { StorageType } from 'utils';

import { PriceService } from './price.service';

const { getStorageTypePrice } = PriceService();

export const getStorageTypeOptions = (storageTypes: StorageType[], storageUnit?: string) =>
  storageTypes.map((storageType) => ({
    value: storageType.id,
    label: getStorageTypePrice(storageType.priceCents, storageType.name, storageUnit),
  }));
