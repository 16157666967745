import React, { createContext, ReactNode, useContext, useMemo } from 'react';
import { User } from 'types/user';

import { useGetUsers } from './use-cases/get-users';

export type UsersContextValue = {
  users: User[];
};

export const UsersContext = createContext<UsersContextValue | null>(null);

export const useUsersContext = () => {
  const context = useContext(UsersContext);

  if (!context) {
    throw new Error('You tried to use UsersContext outside the scope of the UsersContextProvider.');
  }

  return context;
};

export function UsersContextProvider({ children }: { children: ReactNode }) {
  const { data: usersData } = useGetUsers({ limit: 1000, offset: 1 });

  const contextValue = useMemo(
    () =>
      ({
        users: usersData?.data || [],
      }) as UsersContextValue,
    [usersData?.data],
  );

  return <UsersContext.Provider value={contextValue}>{children}</UsersContext.Provider>;
}
