import NumberInput from '@stryberventures/gaia-react.number-input';
import { StorageItem } from 'components';
import { DeleteDeliveryItem } from 'features/delivery/components/delivery-items-list/delivery-item/delete-delivery-item/delete-delivery-item';
import { useUpdateDeliveryItem } from 'features/delivery/use-cases/update-delivery-item';
import i18n from 'i18n';
import React, { useEffect } from 'react';
import { ItemToDelivery } from 'types/item-to-delivery';

import useStyles from './styles';

type DeliveryItemProps = {
  item: ItemToDelivery;
  maxQuantity: number;
};

export const DeliveryItem: React.FC<DeliveryItemProps> = ({ item, maxQuantity }) => {
  const [quantity, setQuantity] = React.useState<number>(item.quantity);
  const [error, setError] = React.useState<string>('');
  const classes = useStyles();

  const { mutate: updateItem, isLoading: isItemUpdateLoading } = useUpdateDeliveryItem(
    item.deliveryId,
  );

  const handleChange = (value: number) => {
    setError('');
    if (value > maxQuantity)
      return setError(
        i18n.screens.deliveryStorage.deliveryItemsList.deliveryItem.maxQuantityError(maxQuantity),
      );
    setQuantity(value);
  };

  const handleSaveQuantity = () => {
    updateItem({
      deliveryId: item.deliveryId,
      itemId: item.itemId,
      quantity,
    });
  };

  useEffect(() => {
    setQuantity(item.quantity);
  }, [item.quantity]);

  return (
    <StorageItem
      name={item.item.name}
      sku={item.item.sku}
      multimedia={item.item.multimedia}
      quantityComponent={
        <NumberInput
          quantityCounter
          min={1}
          max={maxQuantity}
          value={quantity}
          fullWidth
          onChange={handleChange}
          controlled
          errorMessage={error}
          className={classes.quantityInput}
        />
      }
      deleteItemComponent={<DeleteDeliveryItem itemId={item.itemId} deliveryId={item.deliveryId} />}
      isSubmitButtonDisabled={item.quantity === quantity}
      isSubmitButtonLoading={isItemUpdateLoading}
      onSubmitButtonClick={handleSaveQuantity}
    />
  );
};
