import { QUERY_KEYS } from 'features/query-keys';

/**
 * @see https://tkdodo.eu/blog/effective-react-query-keys#use-query-key-factories
 */
export const warehousesTypesQueryKeys = {
  all: [QUERY_KEYS.warehouseType] as const,
  lists: () => [...warehousesTypesQueryKeys.all, 'list'] as const,
  list: (filters: object) => [...warehousesTypesQueryKeys.lists(), { filters }] as const,
  details: () => [...warehousesTypesQueryKeys.all, 'detail'] as const,
  detail: (id: string) => [...warehousesTypesQueryKeys.details(), id] as const,
};

export const warehouseQueryKeys = {
  all: [QUERY_KEYS.warehouse] as const,
  lists: () => [...warehousesTypesQueryKeys.all, 'list'] as const,
  list: (filters: object) => [...warehousesTypesQueryKeys.lists(), { filters }] as const,
  details: () => [...warehousesTypesQueryKeys.all, 'detail'] as const,
  detail: (id: string) => [...warehousesTypesQueryKeys.details(), id] as const,
};

export const warehouseItemsQueryKeys = {
  all: [QUERY_KEYS.warehouseItems] as const,
  lists: () => [...warehouseItemsQueryKeys.all, 'list'] as const,
  list: (filters: object) => [...warehouseItemsQueryKeys.lists(), { filters }] as const,
  details: () => [...warehouseItemsQueryKeys.all, 'detail'] as const,
  detail: (id: string) => [...warehouseItemsQueryKeys.details(), id] as const,
};
