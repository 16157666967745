import { FormControl, Select } from 'components';
import { useUpdateQueryIdDelivery } from 'features/delivery/hooks/update-query-id-delivery';
import i18n from 'i18n';
import React, { useEffect } from 'react';
import { SingleValue } from 'react-select';
import { Option } from 'types/option';
import { getStorageTypeOptions, StorageType } from 'utils';

import useStyles from './styles';

type RequestedStorageProps = {
  storageTypes: StorageType[];
  isItemsToDelivery: boolean;
  warehouseTypeId?: number;
  storageUnit?: string;
};

export const RequestedStorage: React.FC<RequestedStorageProps> = ({
  storageTypes,
  isItemsToDelivery,
  warehouseTypeId,
  storageUnit,
}) => {
  const [selectedOption, setSelectedOption] = React.useState<Option | null>(null);
  const [options, setOptions] = React.useState<Option[]>([]);

  const classes = useStyles();

  const { mutate: updateDelivery, isLoading } = useUpdateQueryIdDelivery();

  const handleStorageTypeChange = (option: SingleValue<Option>) => {
    if (!option) return;
    updateDelivery({
      warehouseTypeId: Number(option.value),
    });
    setSelectedOption(option);
  };

  useEffect(() => {
    setOptions(getStorageTypeOptions(storageTypes, storageUnit));
  }, [storageTypes, storageUnit]);

  useEffect(() => {
    const selectedOption = options.find((option) => option.value === warehouseTypeId);
    if (selectedOption) {
      setSelectedOption(selectedOption);
    }
  }, [options, warehouseTypeId]);

  return (
    <FormControl
      title={i18n.screens.deliveryStorage.requestedStorage.storageType.title}
      subtitle={i18n.screens.deliveryStorage.requestedStorage.storageType.subtitle}
      hint={isItemsToDelivery ? i18n.screens.deliveryStorage.requestedStorage.storageType.hint : ''}
    >
      <Select
        className={classes.select}
        options={options}
        onChange={handleStorageTypeChange}
        value={selectedOption}
        isDisabled={isLoading || isItemsToDelivery}
        isSearchable={false}
      />
    </FormControl>
  );
};
