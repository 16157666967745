import Tabs from '@stryberventures/gaia-react.tabs';
import { ITab } from '@stryberventures/gaia-react.tabs/Tab';
import { DeliveriesTable } from 'features/logistics/components/table/deliveries-table/deliveries-table';
import { PickupsTable } from 'features/logistics/components/table/pickups-table/pickups-table';
import React from 'react';

import useStyles from './styles';

enum TabsID {
  PICKUPS = 'pickups',
  DELIVERIES = 'deliveries',
}

export const TableTabs: React.FC = () => {
  const [activeTab, setActiveTab] = React.useState<TabsID>(TabsID.PICKUPS);
  const classes = useStyles();

  const tabs: ITab[] = [
    {
      label: 'Pickups',
      id: TabsID.PICKUPS,
      active: activeTab === TabsID.PICKUPS,
    },
    {
      label: 'Deliveries',
      id: TabsID.DELIVERIES,
      active: activeTab === TabsID.DELIVERIES,
    },
  ];

  return (
    <>
      <Tabs
        className={classes.tabs}
        size='medium'
        onChange={(tab) => setActiveTab(tab as TabsID)}
        tabs={tabs}
      />
      {activeTab === TabsID.PICKUPS && <PickupsTable />}
      {activeTab === TabsID.DELIVERIES && <DeliveriesTable />}
    </>
  );
};
