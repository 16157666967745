import { useMutation } from '@tanstack/react-query';
import { handleError } from 'api/error-handling';
import { AuthApiService, ForgotPasswordConfirmDTO } from 'features/auth/auth-api.service';
import i18n from 'i18n';
import { enqueueSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes/constants';

const { forgotPasswordConfirm } = AuthApiService();

export const useForgotPasswordConfirm = () => {
  const navigate = useNavigate();
  const options = (payload: ForgotPasswordConfirmDTO) => {
    return forgotPasswordConfirm(payload);
  };

  return useMutation(options, {
    onError: (error) => {
      const uiError = handleError(error);
      enqueueSnackbar(uiError, { variant: 'error' });
    },
    onSuccess: () => {
      navigate(ROUTES.LOGIN);
      enqueueSnackbar(i18n.screens.forgotPasswordConfirm.successMessage, { variant: 'success' });
    },
  });
};
