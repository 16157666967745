import { createStyles } from '@stryberventures/gaia-react.theme';

export default createStyles((theme) => ({
  buttonWrapper: {
    display: 'flex',
    gap: theme.spacing[24],
    justifyContent: 'flex-end',
  },
  button: {
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
  },
  buttonEdit: {
    '&:hover svg *': {
      fill: theme.colors.primary.main500,
    },
  },
  buttonDelete: {
    '&:hover svg *': {
      fill: theme.colors.error.main500,
    },
  },
}));
