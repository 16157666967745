import { UserPrice } from 'types/user-price';
import { PriceService } from 'utils/price.service';

export type StorageType = {
  id: number;
  name: string;
  priceCents: number;
  storageUnit: string;
};

const { getStorageTypePrice } = PriceService();

export const mappedStorageTypes = (userPrices: UserPrice[], storageUnit: string): StorageType[] =>
  userPrices.map((userPrice) => ({
    id: userPrice.warehouseTypeId,
    name: userPrice.warehouseType.name,
    priceCents: userPrice.priceCents,
    storageUnit: storageUnit,
  }));

export const mapStorageTypesToOptions = (storageTypes: StorageType[]) =>
  storageTypes.map((storageType) => ({
    value: storageType.id,
    label: getStorageTypePrice(storageType.priceCents, storageType.name, storageType.storageUnit),
  }));
