import { Text, TextColor } from 'components';
import { ItemImage, ItemImageVariant } from 'features/warehouse/components/item-image/item-image';
import React from 'react';
import { Multimedia } from 'types/multimedia';
import { WarehouseTypeLabel } from 'types/warehouse-type-label';
import { getItemQuantityLabel } from 'utils/get-item-quantity-label';
import { getWarehouseTypeBadge } from 'utils/get-warehouseType-badge';

import useStyles from './styles';

type InventoryListItemProps = {
  sku: string;
  itemName: string;
  itemId: number;
  multimedia: Multimedia[];
  quantity: number;
  warehouseTypeLabel: WarehouseTypeLabel;
};

export const InventoryListItem: React.FC<InventoryListItemProps> = ({
  sku,
  itemName,
  itemId,
  multimedia,
  quantity,
  warehouseTypeLabel,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.imageWrapper}>
        <div className={classes.badgeWrapper}>{getWarehouseTypeBadge(warehouseTypeLabel)}</div>
        <ItemImage
          variant={ItemImageVariant.large}
          itemName={itemName}
          itemId={itemId}
          multimedia={multimedia}
        />
      </div>
      <div className={classes.infoWrapper}>
        <Text color={TextColor.Tint} variant='caption1' weight='medium'>
          {sku}
        </Text>
        <Text variant='body2'>{itemName}</Text>
        <Text variant='body3' color={TextColor.Secondary}>
          {getItemQuantityLabel(quantity)}
        </Text>
      </div>
    </div>
  );
};
