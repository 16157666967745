import { ArrowIcon } from '@stryberventures/gaia-react.icons';
import { useTheme } from '@stryberventures/gaia-react.theme';
import React from 'react';
import ReactSelect, { components, DropdownIndicatorProps, Props } from 'react-select';
import { Option } from 'types/option';

export type SelectProps = Props<Option, false>;

const DropdownIndicator = (props: DropdownIndicatorProps<Option, false>) => {
  const { theme } = useTheme();
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <ArrowIcon
          fill={theme.colors.text.body}
          variant={props.selectProps.menuIsOpen ? 'up' : 'down'}
        />
      </components.DropdownIndicator>
    )
  );
};

export const Select: React.FC<SelectProps> = (props) => {
  const { theme } = useTheme();

  const customStyles: SelectProps['styles'] = {
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused
        ? theme.colors.primary.main500
        : theme.colors.neutralGray.medium300,
      height: 48,
      padding: [theme.spacing[8], theme.spacing[4]],
      '&:hover': {
        borderColor: theme.colors.primary.main500,
        backgroundColor: theme.colors.neutralGray.extraLight50,
      },
      boxShadow: state.isFocused ? `0 0 0 1px ${theme.colors.primary.main500}` : 'none',
    }),
    option: (provided, state) => ({
      ...provided,
      padding: theme.spacing[16],
      color: theme.colors.text.headline,
      backgroundColor: state.isSelected
        ? theme.colors.neutralGray.light200
        : state.isFocused
        ? theme.colors.neutralGray.extraLight50
        : theme.colors.contrast.white,
      '&:hover': {
        backgroundColor: state.isSelected
          ? theme.colors.neutralGray.light200
          : theme.colors.neutralGray.extraLight50,
      },
    }),
  };

  const customComponents: SelectProps['components'] = {
    IndicatorSeparator: () => null,
    DropdownIndicator,
  };

  return <ReactSelect components={customComponents} styles={customStyles} {...props} />;
};
