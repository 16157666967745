import { createStyles } from '@stryberventures/gaia-react.theme';

export default createStyles((theme) => ({
  wrapper: {
    display: 'grid',
    gap: theme.spacing[8],
  },
  controls: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: theme.spacing[8],
    gridTemplateRows: '40px',
  },
}));
