import React from 'react';
import Modal from 'react-modal';

type CustomModalProps = {
  children: React.ReactNode;
};

const customStyles: Modal.Styles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
  overlay: { zIndex: 1000 },
};

export const CustomModal = ({ children }: CustomModalProps) => {
  return (
    <Modal isOpen style={customStyles}>
      {children}
    </Modal>
  );
};
