import Button from '@stryberventures/gaia-react.button';
import CircularProgress from '@stryberventures/gaia-react.circular-progress';
import Text from '@stryberventures/gaia-react.text';
import classNames from 'classnames';
import { VerificationIcon } from 'components/Icons/VerificationIcon';
import { useVerify } from 'features/auth/use-cases/verify';
import i18n from 'i18n';
import { RouteName } from 'navigation/routeNames';
import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import useStyles from './styles';

type ISignUpVerification = object;

export const SignUpVerification: React.FC<ISignUpVerification> = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const navigate = useNavigate();

  const { mutate: verify, isLoading, isSuccess, isError } = useVerify();
  const classes = useStyles();

  const handleLoginNavigate = () => {
    navigate(RouteName.Login);
  };

  useEffect(() => {
    if (token) {
      verify({ verificationToken: token });
    }
  }, [token, verify]);

  return (
    <div
      className={classNames(classes.signUpVerification, {
        [classes.successVerification as unknown as string]: isSuccess,
        [classes.errorVerification as unknown as string]: isError,
      })}
    >
      <div className={classes.screen}>
        <div className={classes.content}>
          {isLoading && <CircularProgress size={50} thickness={5} />}
          {isError && (
            <>
              <Text align='center' className={classes.title}>
                {i18n.screens.signUpVerification.errorTitle}
              </Text>
              <Text align='center' className={classes.description}>
                {i18n.screens.signUpVerification.errorDescription}
              </Text>
            </>
          )}
          {isSuccess && (
            <>
              <VerificationIcon />
              <Text align='center' className={classes.title}>
                {i18n.screens.signUpVerification.successTitle}
              </Text>
              <Text align='center' className={classes.description}>
                {i18n.screens.signUpVerification.successDescription}
              </Text>
              <Button fullWidth onClick={handleLoginNavigate}>
                {i18n.screens.signUpVerification.login}
              </Button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
