import {
  apiClient,
  ApiClientConfig,
  baseUrl,
  PaginatedRequestParams,
  PaginatedResponse,
} from 'api';
import { REST_API_ENDPOINTS } from 'api/constants';
import { User } from 'types/user';
import { UserPrice } from 'types/user-price';

export type UserPrices = {
  userId: string;
  storageUnit: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  usersToPrices: UserPrice[];
};

export type GetUserPricesParams = {
  userId?: string;
} & PaginatedRequestParams;

export type GetUsersParams = {
  limit: number;
  offset: number;
};

export type UsersResponse = {
  count: number;
  timestamp: string;
  data: User[];
};

export const UserApiService = () => {
  const getMe = async (callConfig?: Partial<ApiClientConfig>) => {
    const response = await apiClient<User>({
      ...callConfig,
      endpoint: '/users/me',
      baseURL: baseUrl.authApi,
    });

    return response.data;
  };

  const getUsers = async (params: GetUsersParams, callConfig?: Partial<ApiClientConfig>) => {
    const response = await apiClient<UsersResponse>({
      ...callConfig,
      endpoint: '/users',
      baseURL: baseUrl.authApi,
      params,
    });

    const data = response.data.data.filter(
      (user) =>
        (String(user.customAttributes.isActive) === '1' ||
          String(user.customAttributes.isActive) === 'true') &&
        user.enabled &&
        user.emailVerified,
    );
    return { ...response.data, data };
  };

  const getUserPrices = async (
    params: GetUserPricesParams,
    apiConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<UserPrices | PaginatedResponse<UserPrices>>({
      ...apiConfig,
      endpoint: REST_API_ENDPOINTS.USER_PRICES,
      params,
    });
    // Admin receives PaginatedResponse<UserPrices> but user UserPrices, we have to map response to the same type
    if ('data' in response.data) {
      const userPrices = response.data.data[0];
      if (!userPrices) {
        throw new Error('User prices are not available');
      }
      return userPrices;
    }

    return response.data;
  };
  return {
    getMe,
    getUsers,
    getUserPrices,
  };
};
