import { enUS } from 'date-fns/esm/locale';

/**
 * For more information about date formatting please go to: https://date-fns.org/docs/Getting-Started
 */

/**
 * "enUs" is used for development. For production "ar" locale shall be used
 */
export const DEFAULT_LOCALE = enUS;

/**
 * Example: 02:05AM
 */
const TIME_FORMAT = 'hh:mma';

/**
 * Example: 2000-02-02
 */
const DATE_FORMAT = 'yyyy-MM-dd';

/**
 * Example: Monday
 */
const DAY_OF_WEEK = 'EEEE';

export const DATE_FORMATS = {
  time: TIME_FORMAT,
  date: DATE_FORMAT,
  dateTime: `${DATE_FORMAT} ${TIME_FORMAT}`,
  dateInput: "yyyy-MM-dd'T'hh:mm",
  dayOfWeek: DAY_OF_WEEK,
  dbDate: 'yyyy-MM-dd HH:mm',
  dbHourDate: 'HH:mm:ss',
  shortMonthDay: 'MMM d',
  longFullDate: "MMM d',' yyy 'at' haaa",
} as const;

export type DateFormat = keyof typeof DATE_FORMATS;
