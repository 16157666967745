import { apiClient, ApiClientConfig, PaginatedRequestParams, PaginatedResponse } from 'api';
import { REST_API_ENDPOINTS } from 'api/constants';
import { Delivery } from 'types/delivery';
import { Status } from 'types/status';

export type CreateDeliveryDTO = {
  userId?: string;
  locationId?: number;
  truckType?: string;
  deliveryDate?: string;
  contactPersonId?: number;
  comments?: string;
};

export type UpdateDeliveryDTO = {
  userId?: string;
  warehouseTypeId?: number;
  locationId?: number;
  truckTypeId?: number;
  deliveryDate?: string;
  contactPersonId?: number;
  comments?: string;
};

export type UpdateDeliveryStatusDTO = {
  id: string;
  userId?: string;
  status: Status;
  comment?: string;
};

export type GetDeliveriesRequestParams = {
  userId?: string;
} & PaginatedRequestParams;

export type GetDeliveryRequestParams = {
  id: string;
  userId?: string;
};

export type DeliveriesResponse = PaginatedResponse<Delivery>;

export type DeleteDeliveryRequestParams = {
  id: string;
  userId?: string;
};

export const DeliveryApiService = () => {
  const createDelivery = async (
    payload: CreateDeliveryDTO,
    apiConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<Delivery>({
      ...apiConfig,
      endpoint: REST_API_ENDPOINTS.DELIVERY,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };

  const getDelivery = async (
    payload: GetDeliveryRequestParams,
    apiConfig: Partial<ApiClientConfig>,
  ) => {
    const { id, ...params } = payload;
    const response = await apiClient<Delivery>({
      ...apiConfig,
      endpoint: `${REST_API_ENDPOINTS.DELIVERY}/${id}`,
      params,
    });

    return response.data;
  };

  const getDeliveries = async (
    params: GetDeliveriesRequestParams,
    apiConfig: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<DeliveriesResponse>({
      ...apiConfig,
      endpoint: REST_API_ENDPOINTS.DELIVERY,
      params,
    });

    return response.data;
  };

  const updateDelivery = async (
    id: string,
    payload: UpdateDeliveryDTO,
    apiConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<Delivery>({
      ...apiConfig,
      endpoint: `${REST_API_ENDPOINTS.DELIVERY}/${id}`,
      method: 'PATCH',
      data: payload,
    });

    return response.data;
  };

  const deleteDelivery = async (
    payload: DeleteDeliveryRequestParams,
    apiConfig?: Partial<ApiClientConfig>,
  ) => {
    const { id, ...params } = payload;

    const response = await apiClient<Delivery>({
      ...apiConfig,
      endpoint: `${REST_API_ENDPOINTS.DELIVERY}/${id}`,
      method: 'DELETE',
      params,
    });

    return response.data;
  };

  const updateDeliveryStatus = async (
    payload: UpdateDeliveryStatusDTO,
    apiConfig?: Partial<ApiClientConfig>,
  ) => {
    const { id, ...data } = payload;
    const response = await apiClient<Delivery>({
      ...apiConfig,
      endpoint: `${REST_API_ENDPOINTS.DELIVERY}/${id}/status`,
      method: 'PATCH',
      data,
    });

    return response.data;
  };

  return {
    createDelivery,
    getDelivery,
    getDeliveries,
    updateDelivery,
    deleteDelivery,
    updateDeliveryStatus,
  };
};
