import { createStyles, toRem } from '@stryberventures/gaia-react.theme';

interface IUseStyles {
  dir: string;
}
//eslint-disable-next-line
export default createStyles<any, IUseStyles>((theme) => ({
  createPassword: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: `${theme.spacing[48]} 0 ${theme.spacing[80]}`,
    boxSizing: 'border-box',
    '*, *:after, *:before': {
      boxSizing: 'inherit',
    },
  },
  logoWrapper: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    marginBottom: theme.spacing[64],
    boxSizing: 'border-box',
  },
  screen: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: toRem(560),
    width: '100%',
    height: '100%',
  },
  title: {
    width: '100%',
    marginBottom: theme.spacing[48],
    color: theme.colors.text.headline,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flexGrow: 1,
  },
  passwordInput: {
    position: 'relative',
    marginBottom: theme.spacing[80],
    ...hintAndErrorStyles,
  },
  repeatPasswordInput: {
    position: 'relative',
    marginBottom: theme.spacing[64],
    ...hintAndErrorStyles,
  },
  submitButton: {},
  [`@media (max-width: ${theme.breakpoints.md}px)`]: {
    createPassword: {
      height: '100vh',
      padding: [theme.spacing[48], theme.spacing[24], theme.spacing[96]],
    },
    logoWrapper: {
      justifyContent: 'center',
      paddingRight: 0,
      marginBottom: theme.spacing[48],
    },
    title: {
      marginBottom: theme.spacing[32],
      fontSize: toRem(22),
      lineHeight: toRem(28),
      textAlign: 'left !important',
    },
    description: {
      marginBottom: theme.spacing[32],
      textAlign: 'left !important',
    },
    formContainer: {
      maxWidth: '100%',
    },
    passwordInput: {
      marginBottom: theme.spacing[80],
    },
    repeatPasswordInput: {
      marginBottom: theme.spacing[12],
    },
    submitButton: {
      marginTop: 'auto',
    },
  },
}));

const hintAndErrorStyles = {
  '& [class*=hint], & [class*=errorMessage]': {
    position: 'absolute',
    left: 0,
    top: '100%',
    width: '100%',
  },
};
