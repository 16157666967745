import { QueryFunction, QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { handleError } from 'api';
import {
  GetPickupsRequestParams,
  PickupApiService,
  PickupsResponse,
} from 'features/pickup/api/pickup-api.service';
import { useReactQueryPowerUserUserIdManager } from 'features/users';
import { enqueueSnackbar } from 'notistack';

import { pickupsQueryKeys } from './query-keys';

const { getPickups } = PickupApiService();

const getPickupsQuery = (
  params: GetPickupsRequestParams,
  options?: UseQueryOptions<PickupsResponse>,
): {
  queryKey: QueryKey;
  queryFn: QueryFunction<PickupsResponse>;
  options?: UseQueryOptions<PickupsResponse>;
} => ({
  queryKey: pickupsQueryKeys.list(params),
  queryFn: ({ signal }) => getPickups(params, { config: { signal } }),
  ...options,
});

export const useGetPickups = (
  params: GetPickupsRequestParams,
  options?: UseQueryOptions<PickupsResponse>,
) => {
  const { enabled, updatedParams } = useReactQueryPowerUserUserIdManager<
    GetPickupsRequestParams,
    PickupsResponse
  >(params, options);

  return useQuery<PickupsResponse, Error>(
    getPickupsQuery(updatedParams, {
      onError: (error) => {
        const uiError = handleError(error);
        enqueueSnackbar(uiError, { variant: 'error' });
      },
      ...options,
      enabled,
    }),
  );
};
