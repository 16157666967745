import { useMutation } from '@tanstack/react-query';
import { handleError } from 'api/error-handling';
import { AuthApiService, SignUpDTO } from 'features/auth/auth-api.service';
import { enqueueSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes/constants';

const { signUp } = AuthApiService();

export const useSignUp = () => {
  const navigate = useNavigate();

  const options = (payload: SignUpDTO) => {
    return signUp(payload);
  };

  return useMutation(options, {
    onError: (error) => {
      const uiError = handleError(error);
      enqueueSnackbar(uiError, { variant: 'error' });
    },
    onSuccess: (response, data) => {
      if (response.mail_verification_type === 'link') {
        navigate(ROUTES.SIGN_UP_CONFIRM, { state: { username: data.username } });
      }
    },
  });
};
