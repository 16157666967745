import env from 'env';

export type User = {
  email: string;
  phone: string;
  firstName: string;
  lastName: string;
  username: string;
  id: string;
  roles: string[];
  enabled: boolean;
  emailVerified: boolean;
  customAttributes: {
    isActive: number;
    lang: 'en-us' | 'ar-sa';
  };
};

export const userRole = {
  ADMIN_ROLE: env.ADMIN_ROLE,
  USER_ROLE: env.USER_ROLE,
};
