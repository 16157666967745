import { Badge } from 'components/badge/badge';
import { TopBar } from 'components/top-bar/top-bar';
import { PickupInfo } from 'features/pickup/components/pickup-info/pickup-info';
import { useGetQueryIdPickup } from 'features/pickup/hooks/get-query-id-pickup';
import { useSuccessGuard } from 'features/pickup/hooks/success-guard';
import { useGetTruckTypes } from 'features/truck/use-cases/get-truck-types';
import i18n from 'i18n';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useGetQueryIdUserPrices } from 'utils/hooks/get-query-id-user-prices';

import useStyles from './styles';

export const PickupCreatedInfoView: React.FC = () => {
  const { id } = useParams();

  const { data: pickupData } = useGetQueryIdPickup();
  const { data: userPrices } = useGetQueryIdUserPrices();
  const { data: truckTypes } = useGetTruckTypes({ enabled: Boolean(pickupData?.truckTypeId) });

  const classes = useStyles();

  useSuccessGuard();

  if (!pickupData || !userPrices || !truckTypes) return null;

  const truckType = truckTypes.data.find((truckType) => truckType.id === pickupData.truckTypeId);

  if (!truckType) return null;

  const pickupStatus = pickupData.status;
  const warehousePrice = userPrices.usersToPrices.find(
    (price) => price.warehouseTypeId === pickupData?.warehouseTypeId,
  );
  const truckPrice = truckType.priceCents;
  const storageUnit = userPrices.storageUnit;

  return (
    <div className={classes.createdPickupRoot}>
      <TopBar
        title={`${i18n.screens.pickupCreatedInfo.title} #${id}`}
        content={pickupStatus && <Badge>{pickupStatus}</Badge>}
      />
      {warehousePrice && (
        <PickupInfo
          pickup={pickupData}
          truckPrice={truckPrice}
          warehousePrice={warehousePrice}
          storageUnit={storageUnit}
          truckType={truckType}
        />
      )}
    </div>
  );
};
