import classNames from 'classnames';
import { DeleteIcon, EditIcon } from 'components/Icons';
import { useDeleteDelivery } from 'features/delivery/use-cases/delete-delivery';
import { DeleteRequestModal } from 'features/logistics/components/delete-pickup-modal/delete-request-modal';
import { useDeletePickup } from 'features/pickup/use-cases/delete-pickup';
import { usePermissions } from 'features/users/hooks/use-permissions';
import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ROUTES } from 'routes';
import { getRoute } from 'routes/utils/get-route';
import { Status } from 'types/status';

import useStyles from './styles';

export enum ActionsCellType {
  PICKUP = 'pickup',
  DELIVERY = 'delivery',
}

type ActionsProps = {
  status: Status;
  id: number;
  type: ActionsCellType;
};

export const Actions: React.FC<ActionsProps> = ({ status, id, type }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const permissions = usePermissions();
  const [searchParams] = useSearchParams();

  const { mutate: deletePickupItem } = useDeletePickup();
  const { mutate: deleteDeliveryItem } = useDeleteDelivery();
  const [deleteId, setDeleteId] = useState<number | null>(null);
  const doesUserHaveEditPermission =
    (type === ActionsCellType.PICKUP && permissions?.pickupOverview.edit) ||
    (type === ActionsCellType.DELIVERY && permissions?.deliveryOverview.edit);

  const navigationRoute =
    type === ActionsCellType.PICKUP
      ? permissions?.pickupOverview.edit && status !== Status.DRAFT
        ? ROUTES.PICKUP_OVERVIEW
        : ROUTES.PICKUP_STORAGE
      : permissions?.deliveryOverview.edit && status !== Status.DRAFT
      ? ROUTES.DELIVERY_OVERVIEW
      : ROUTES.DELIVERY_STORAGE;
  const handleDeleteRequest = () => {
    const deleteArgs = { id: String(id) };
    if (type === ActionsCellType.PICKUP) {
      deletePickupItem(deleteArgs);
    } else {
      deleteDeliveryItem(deleteArgs);
    }
  };

  if (doesUserHaveEditPermission || status === Status.DRAFT) {
    return (
      <div className={classes.buttonWrapper}>
        <button
          className={classNames(classes.button, classes.buttonEdit)}
          type={'button'}
          onClick={() => {
            navigate({
              pathname: getRoute(navigationRoute, id),
              search: searchParams.toString(),
            });
          }}
        >
          <EditIcon />
        </button>
        {status === Status.DRAFT && (
          <button
            onClick={() => setDeleteId(id)}
            className={classNames(classes.button, classes.buttonDelete)}
            type={'button'}
          >
            <DeleteIcon />
          </button>
        )}
        {deleteId && (
          <DeleteRequestModal onOk={handleDeleteRequest} onClose={() => setDeleteId(null)} />
        )}
      </div>
    );
  }

  return null;
};
