import classNames from 'classnames';
import { Text } from 'components';
import { SortedIcon } from 'components/Icons/SortedIcon';
import useStyles from 'components/sidebar/styles';
import { useUserContext } from 'features';
import { usePermissions } from 'features/users/hooks/use-permissions';
import { isPowerUser } from 'features/users/permissions';
import i18n from 'i18n';
import { FC } from 'react';
import { NavLink, useSearchParams } from 'react-router-dom';
import { ROUTES } from 'routes/constants';

import { getMenuItems } from './get-menu-items';

const Sidebar: FC = () => {
  const classes = useStyles();
  const permissions = usePermissions();
  const [searchParams] = useSearchParams();

  const { user } = useUserContext();

  const menuItems = getMenuItems(permissions);

  const isAdmin = user && isPowerUser(user);

  return (
    <div className={classes.container}>
      <div className={classes.sidebar}>
        <div>
          <NavLink
            to={`${ROUTES.HOME}?${searchParams.toString()}`}
            className={classes.sidebarLogoContainer}
          >
            <SortedIcon />
            <span className={classes.sidebarTitle}>{i18n.components.sidebar.title}</span>
            {isAdmin && (
              <Text className={classes.role} variant={'body2'}>
                {i18n.role.admin}
              </Text>
            )}
          </NavLink>
          <span className={classes.sectionTitle}>{i18n.components.sidebar.menu.title}</span>

          {menuItems.map((item) => (
            <NavLink
              key={item.key}
              to={`${item.key}?${searchParams.toString()}`}
              className={({ isActive }) =>
                classNames(classes.sidebarItem, { [classes.sidebarItemActive]: isActive })
              }
            >
              <div className={classes.iconWrapper}>{item.icon}</div>
              {item.label}
            </NavLink>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
