import { useMutation } from '@tanstack/react-query';
import {
  PickupApiService,
  UpdatePickupFinalDetailsDTO,
} from 'features/pickup/api/pickup-api.service';
import { useUserId } from 'features/users';

const { updatePickupFinalDetails } = PickupApiService();

export const useUpdatePickupFinalDetails = () => {
  const userId = useUserId();

  return useMutation((payload: UpdatePickupFinalDetailsDTO) => {
    return updatePickupFinalDetails({ userId, ...payload });
  });
};
