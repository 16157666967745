import { TableCell, TableRow } from '@stryberventures/gaia-react.table';
import { Text, TextColor } from 'components/text/text';
import i18n from 'i18n';
import React from 'react';

import useStyles from './table.styles';

export const TableNoDataRow: React.FC = () => {
  const classes = useStyles();
  return (
    <TableRow>
      <TableCell colSpan={6} className={classes.noDataCell}>
        <Text color={TextColor.Secondary}>{i18n.components.table.noData}</Text>
      </TableCell>
    </TableRow>
  );
};
