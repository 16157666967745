import { TableSkeletonLoader } from 'components';
import { WarehouseCollection } from 'components/transport-date/warehouse-collection/warehouse-collection';
import React, { useState } from 'react';
import { TransportVariant } from 'types/transport-variant';

interface TransportDateProps {
  title: string;
  startTime?: string;
  endTime?: string;
  initialTime?: string;
  isLoading?: boolean;
  isUpdating?: boolean;
  onUpdate: (newDate: string) => void;
}

export const TransportDate = (props: TransportDateProps) => {
  const [variant] = useState(TransportVariant.Truck);
  const { startTime, endTime, isLoading } = props;

  if (isLoading || !startTime || !endTime) {
    return <TableSkeletonLoader />;
  }

  return (
    <section>
      {variant === TransportVariant.Truck && (
        <WarehouseCollection {...props} startTime={startTime} endTime={endTime} />
      )}
      {variant === TransportVariant.SelfDrop && <div />}
    </section>
  );
};
