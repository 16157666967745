import { PickupItemsApiService } from 'features/pickup/api/pickup-items-api.service';
import { useUserId } from 'features/users';

const { downloadPickupItemsCsv: downloadPickupItemsCsvApi } = PickupItemsApiService();

export const useDownloadPickupItemsCsv = () => {
  const userId = useUserId();

  const downloadPickupItemsCsv = async (id: number) => {
    const response = await downloadPickupItemsCsvApi(id, { userId });

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const a = document.createElement('a');

    a.href = url;
    // We have to manually set name, because axios doesn't expose Content-Disposition
    // header which contains filename set in BE
    a.download = `pickup-${id}.csv`;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  };

  return { downloadPickupItemsCsv };
};
