import { Select } from 'components/select/select';
import { useUserId } from 'features';
import { useGetUsers } from 'features/users/use-cases/get-users';
import React from 'react';
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { User } from 'types/user';

const getUsersOptions = (users: User[]) =>
  users.map((user) => ({
    label: user.email,
    value: user.id,
  }));
export const UsersSelect = () => {
  const { data: users } = useGetUsers({ limit: 1000, offset: 1 });
  const [, setSearchParams] = useSearchParams();
  const userId = useUserId();

  const options = useMemo(() => {
    if (!users?.data) return [];
    return getUsersOptions(users.data);
  }, [users]);

  const value = useMemo(() => {
    const userExist = users?.data?.find((user) => user.id === userId);
    if (userExist) {
      return {
        label: userExist.email,
        value: userExist.id,
      };
    }
    return undefined;
  }, [userId, users?.data]);

  return (
    <Select
      options={options}
      value={value}
      onChange={(newValue) => {
        const userExists = users?.data?.find((user) => user.id === newValue?.value);
        if (userExists) {
          setSearchParams({
            userId: userExists.id,
          });
        }
      }}
    />
  );
};
