import { PageNavigationBar } from 'components/page-navigation-bar/page-navigation-bar';
import { Stepper } from 'components/stepper/stepper';
import { Price } from 'features/pickup/routes/pickup-storage';
import i18n from 'i18n';
import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
import { getRoute } from 'routes/utils/get-route';

import { ContinueButton } from './continue-button/continue-button';
import { StoragePrice } from './storage-price/storage-price';

type PickupNavigationProps = {
  price: Price;
  isProceedDisabled: boolean;
  onProceedClick: () => void;
};

const getPickupNavigationSubpages = () => {
  const storage = ROUTES.PICKUP_STORAGE;
  const logistics = ROUTES.PICKUP_LOGISTICS;

  return {
    storage,
    logistics,
  } as const;
};

export const PickupNavigation: React.FC<PickupNavigationProps> = ({
  price,
  isProceedDisabled,
  onProceedClick,
}) => {
  const { id } = useParams();
  const location = useLocation();

  const subPages = getPickupNavigationSubpages();

  const steps = useMemo(
    () => [
      {
        key: subPages.storage,
        label: i18n.screens.pickupStorage.steps.pickupItems,
        priceLabel: i18n.screens.pickupStorage.storagePrice,
      },
      {
        key: subPages.logistics,
        label: i18n.screens.pickupStorage.steps.logistics,
        priceLabel: i18n.screens.pickupLogistics.logisticsPrice,
      },
    ],
    [subPages.logistics, subPages.storage],
  );

  const [activeStep, setActiveStep] = useState<{
    key: string;
    label: string;
    priceLabel: string;
  } | null>(null);

  useEffect(() => {
    if (location.pathname === getRoute(ROUTES.PICKUP_STORAGE, id || '')) {
      setActiveStep(steps.find((step) => step.key === subPages.storage) || null);
    }
    if (location.pathname === getRoute(ROUTES.PICKUP_LOGISTICS, id || '')) {
      setActiveStep(steps.find((step) => step.key === subPages.logistics) || null);
    }
  }, [id, location.pathname, steps, subPages.logistics, subPages.storage]);

  return (
    <PageNavigationBar
      renderLeftSide={activeStep && <Stepper steps={steps} activeStep={activeStep} />}
      renderRightSide={
        <>
          <StoragePrice
            price={price}
            title={
              activeStep?.key === subPages.storage
                ? i18n.screens.pickupStorage.storagePrice
                : i18n.screens.pickupLogistics.logisticsPrice
            }
          />
          <ContinueButton onClick={onProceedClick} disabled={isProceedDisabled} />
        </>
      }
    />
  );
};
