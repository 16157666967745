import { InfoIcon } from '@stryberventures/gaia-react.icons';
import Text from '@stryberventures/gaia-react.text';
import { useTheme } from '@stryberventures/gaia-react.theme';
import Tooltip from '@stryberventures/gaia-react.tooltip';
import React from 'react';

import useStyles from './styles';

type FormControlProps = {
  title?: string;
  subtitle?: string;
  children: React.ReactNode;
  hint?: string;
  className?: string;
  tooltipTitle?: string;
  tooltipContent?: React.ReactElement;
};

export const FormControl: React.FC<FormControlProps> = ({
  title,
  subtitle,
  hint,
  children,
  tooltipTitle,
  tooltipContent,
  className,
}) => {
  const { theme } = useTheme();

  const classes = useStyles();

  const renderHint = () => {
    if (!hint) return null;

    if (!tooltipContent || !tooltipTitle)
      return (
        <div className={classes.hint}>
          <InfoIcon fill={theme.colors.neutralGray.medium400} />
          <Text variant='components2'>{hint}</Text>
        </div>
      );

    return (
      <Tooltip
        className={classes.tooltip}
        position='bottomStart'
        title={tooltipTitle}
        text={tooltipContent}
        version='dark'
      >
        <div className={classes.hint}>
          <InfoIcon fill={theme.colors.neutralGray.medium400} />
          <Text variant='components2'>{hint}</Text>
        </div>
      </Tooltip>
    );
  };

  return (
    <div className={className}>
      <div className={classes.titleWrapper}>
        {title && (
          <Text weight='medium' className={classes.title} variant='components1'>
            {title}
          </Text>
        )}
        {subtitle && (
          <Text className={classes.subtitle} variant='caption1'>
            {subtitle}
          </Text>
        )}
      </div>
      {children}
      {hint && renderHint()}
    </div>
  );
};
