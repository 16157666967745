import Divider from '@stryberventures/gaia-react.divider';
import { TopBar } from 'components/top-bar/top-bar';
import { DashboardOverview } from 'features/dashboard/components/dashboard-overview/dashboard-overview';
import { DashboardTable } from 'features/dashboard/components/dashboard-table/dashboard-table';
import i18n from 'i18n';
import React from 'react';

import useStyles from './styles';

export const DashboardView = () => {
  const classes = useStyles();
  return (
    <div>
      <TopBar title={i18n.title} titleColor='neutralGrey' />
      <DashboardOverview />
      <Divider />
      {/* TODO: SOR-121 Update and uncomment when backend is ready */}
      <section className={classes.sections}>
        {/* <StorageUtilization /> */}
        <DashboardTable />
      </section>
    </div>
  );
};
