import GaiaText, { IText } from '@stryberventures/gaia-react.text';
import classNames from 'classnames';

import useStyles from './styles';

export enum TextColor {
  Secondary = 'secondary',
  Tint = 'tint',
}

type TextProps = {
  color?: TextColor;
} & IText;

export const Text = ({ color = TextColor.Secondary, className, ...props }: TextProps) => {
  const classes = useStyles();
  return <GaiaText className={classNames(classes[color], className)} {...props} />;
};
