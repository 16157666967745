import { useMutation } from '@tanstack/react-query';
import { handleError } from 'api/error-handling';
import { PickupApiService, UpdatePickupDTO } from 'features/pickup/api/pickup-api.service';
import { useUserId } from 'features/users';
import { queryClient } from 'index';
import { enqueueSnackbar } from 'notistack';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ROUTES } from 'routes';
import { getRoute } from 'routes/utils/get-route';

import { pickupsQueryKeys } from './query-keys';

const { updatePickup } = PickupApiService();

type UseUpdatePickupProps = {
  id: string;
  preventNavigate?: boolean;
};

export const useUpdatePickup = ({ id, preventNavigate }: UseUpdatePickupProps) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const userId = useUserId();

  return useMutation(
    (payload: UpdatePickupDTO) => {
      return updatePickup(id, { userId, ...payload });
    },
    {
      onSuccess: (data) => {
        !preventNavigate &&
          navigate({
            pathname: getRoute(ROUTES.PICKUP_LOGISTICS, data.id),
            search: searchParams.toString(),
          });
        queryClient.invalidateQueries({
          queryKey: pickupsQueryKeys.detail(id),
        });
      },
      onError: (error) => {
        const uiError = handleError(error);
        enqueueSnackbar(uiError, { variant: 'error' });
      },
    },
  );
};
