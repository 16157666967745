import { usePermissions } from 'features/users/hooks/use-permissions';
import { PermissionKey } from 'features/users/permissions';
import { pathOr } from 'ramda';
import { ReactElement } from 'react';
import { Navigate } from 'react-router-dom';

type RestrictedRouteProps = {
  children: ReactElement;
  requiredPermission: PermissionKey;
  redirectPath: string;
};

export const RestrictedRoute = ({
  children,
  redirectPath,
  requiredPermission,
}: RestrictedRouteProps) => {
  const permissions = usePermissions();

  if (!permissions) return null;

  const isAllowed = pathOr(null, requiredPermission.split('.'), permissions);

  if (isAllowed) {
    return children;
  }

  return <Navigate to={redirectPath} replace />;
};
