import { createStyles } from '@stryberventures/gaia-react.theme';

export default createStyles((theme) => ({
  requestedStorage: {
    display: 'flex',
    gap: theme.spacing[32],
  },
  formControl: {
    width: '100%',
  },
  selectWrapper: {
    maxWidth: 420,
  },
  inputWrapper: {
    maxWidth: 280,
  },
}));
