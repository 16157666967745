import { QueryFunction, QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { handleError } from 'api/error-handling';
import {
  GetUserPricesParams,
  UserApiService,
  UserPrices,
} from 'features/users/api/users-api-service';
import { useReactQueryPowerUserUserIdManager } from 'features/users/hooks';
import { enqueueSnackbar } from 'notistack';

import { pricesQueryKeys } from './query-keys';

const { getUserPrices } = UserApiService();

const getUserPricesQuery = (
  params: GetUserPricesParams,
  options?: UseQueryOptions<UserPrices>,
): {
  queryKey: QueryKey;
  queryFn: QueryFunction<UserPrices>;
  options?: UseQueryOptions<UserPrices>;
} => ({
  queryKey: pricesQueryKeys.detail(params),
  queryFn: ({ signal }) => getUserPrices(params, { config: { signal } }),
  ...options,
});

export const useGetUserPrices = (
  params: GetUserPricesParams,
  options?: UseQueryOptions<UserPrices>,
) => {
  const { updatedParams, enabled } = useReactQueryPowerUserUserIdManager(params, options);

  return useQuery<UserPrices, Error>(
    getUserPricesQuery(updatedParams, {
      onError: (error) => {
        const uiError = handleError(error);
        enqueueSnackbar(uiError, { variant: 'error' });
      },
      ...options,
      retry: false,
      enabled,
    }),
  );
};
