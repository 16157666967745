import { MoreIcon } from '@stryberventures/gaia-react.icons';
import { ContextMenu } from 'components/context-menu/context-menu';
import { useDeleteDeliveryItem } from 'features/delivery/use-cases/delete-delivery-item';
import i18n from 'i18n';
import React from 'react';

import useStyles from './styles';

type DeleteDeliveryItemProps = {
  itemId: number;
  deliveryId: number;
};

export const DeleteDeliveryItem: React.FC<DeleteDeliveryItemProps> = ({ itemId, deliveryId }) => {
  const { mutate: deleteItem } = useDeleteDeliveryItem();
  const classes = useStyles();

  const contextMenuOptions = [
    {
      label: i18n.screens.deliveryStorage.deliveryItemsList.deliveryItem.deleteButton,
      onClick: () =>
        deleteItem({
          itemId,
          deliveryId,
        }),
    },
  ];

  return (
    <ContextMenu position='right' options={contextMenuOptions}>
      <div className={classes.contextMenuButton}>
        <MoreIcon />
      </div>
    </ContextMenu>
  );
};
