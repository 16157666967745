import { TableCell, TableHead as GaiaTableHead, TableRow } from '@stryberventures/gaia-react.table';
import { Text } from 'components/text/text';
import React from 'react';

import { HeadCell } from './types';

type TableHeadProps = {
  headCells: HeadCell[];
};
export const TableHead = ({ headCells }: TableHeadProps) => {
  return (
    <GaiaTableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id}>
            <Text variant={'caption1'} weight={'medium'}>
              {headCell.label}
            </Text>
          </TableCell>
        ))}
      </TableRow>
    </GaiaTableHead>
  );
};
