import { SVGProps } from 'react';

export const FrozenWarehouseIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='14'
      height='14'
      viewBox='0 0 14 14'
      fill='none'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7 0.496094C7.20941 0.496094 7.37917 0.665852 7.37917 0.87526V2.21972C7.73292 2.16961 8.07435 2.04878 8.38255 1.86302C8.5619 1.75492 8.79493 1.81268 8.90302 1.99203C9.01112 2.17138 8.95336 2.4044 8.77401 2.5125C8.34742 2.76962 7.87128 2.92986 7.37917 2.98393V6.34352L10.2888 4.66368C10.09 4.21034 9.99071 3.71803 9.99949 3.22014C10.0032 3.01077 10.1759 2.84403 10.3853 2.84772C10.5947 2.8514 10.7614 3.02413 10.7577 3.2335C10.7514 3.5935 10.8175 3.94976 10.9507 4.28152L12.1148 3.60939C12.2962 3.50469 12.5281 3.56682 12.6328 3.74818C12.7375 3.92953 12.6754 4.16142 12.494 4.26613L11.3297 4.93832C11.5503 5.21958 11.8257 5.45501 12.1405 5.62961C12.3236 5.73117 12.3898 5.96196 12.2882 6.14509C12.1866 6.32822 11.9558 6.39435 11.7727 6.29279C11.3374 6.05135 10.9609 5.71919 10.6678 5.32046L7.75833 7.00026L10.6678 8.68006C10.9609 8.28133 11.3374 7.94917 11.7727 7.70773C11.9558 7.60617 12.1866 7.6723 12.2882 7.85543C12.3898 8.03856 12.3236 8.26935 12.1405 8.37091C11.8257 8.5455 11.5503 8.78094 11.3297 9.0622L12.494 9.73439C12.6754 9.8391 12.7375 10.071 12.6328 10.2523C12.5281 10.4337 12.2962 10.4958 12.1148 10.3911L10.9507 9.719C10.8175 10.0508 10.7514 10.407 10.7577 10.767C10.7614 10.9764 10.5947 11.1491 10.3853 11.1528C10.1759 11.1565 10.0032 10.9898 9.99949 10.7804C9.99071 10.2825 10.09 9.79018 10.2888 9.33684L6.81042 7.32863C6.6931 7.2609 6.62083 7.13572 6.62083 7.00026V2.98393C6.12872 2.92986 5.65258 2.76962 5.22599 2.5125C5.04664 2.4044 4.98888 2.17138 5.09698 1.99203C5.20508 1.81268 5.4381 1.75492 5.61745 1.86302C5.92565 2.04878 6.26708 2.16961 6.62083 2.21972V0.87526C6.62083 0.665852 6.79059 0.496094 7 0.496094ZM7 3.00443C6.9998 3.00443 6.9996 3.00443 6.99939 3.00443L7 2.62526L7.00061 3.00443C7.0004 3.00443 7.0002 3.00443 7 3.00443Z'
        fill='#005889'
      />
      <path
        opacity='0.5'
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.61473 2.84772C3.82411 2.84403 3.99683 3.01077 4.00052 3.22014C4.00929 3.71803 3.90999 4.21034 3.71125 4.66368L7.18959 6.67189C7.3069 6.73962 7.37917 6.8648 7.37917 7.00026L7.37919 11.017C7.87097 11.0715 8.34681 11.2314 8.77352 11.4877C8.95304 11.5955 9.01118 11.8285 8.90336 12.008C8.79555 12.1875 8.56262 12.2456 8.38309 12.1378C8.07452 11.9525 7.73301 11.8318 7.37919 11.7813V13.1253C7.37919 13.3347 7.20944 13.5044 7.00003 13.5044C6.79062 13.5044 6.62086 13.3347 6.62086 13.1253V11.7813C6.26704 11.8318 5.92553 11.9525 5.61696 12.1378C5.43744 12.2456 5.20451 12.1875 5.09669 12.008C4.98888 11.8285 5.04701 11.5955 5.22653 11.4877C5.65324 11.2314 6.12908 11.0715 6.62086 11.017L6.62084 7.657L3.71125 9.33685C3.90999 9.79019 4.00929 10.2825 4.00052 10.7804C3.99683 10.9898 3.82411 11.1565 3.61473 11.1528C3.40535 11.1491 3.23861 10.9764 3.2423 10.767C3.24865 10.407 3.18254 10.0508 3.04934 9.719L1.88517 10.3911C1.70382 10.4958 1.47193 10.4337 1.36722 10.2523C1.26252 10.071 1.32465 9.8391 1.50601 9.73439L2.67027 9.06221C2.44968 8.78095 2.17433 8.54551 1.85951 8.37091C1.67638 8.26935 1.61025 8.03856 1.71181 7.85543C1.81338 7.6723 2.04416 7.60617 2.2273 7.70774C2.66264 7.94917 3.03912 8.28133 3.33216 8.68006L6.24167 7.00026L3.33216 5.32046C3.03912 5.71919 2.66264 6.05136 2.2273 6.29279C2.04416 6.39435 1.81338 6.32822 1.71181 6.14509C1.61025 5.96196 1.67638 5.73117 1.85951 5.62961C2.17433 5.45502 2.44968 5.21958 2.67027 4.93832L1.50601 4.26613C1.32465 4.16143 1.26252 3.92953 1.36722 3.74818C1.47193 3.56683 1.70382 3.50469 1.88517 3.60939L3.04934 4.28152C3.18254 3.94976 3.24865 3.5935 3.2423 3.23351C3.23861 3.02413 3.40535 2.85141 3.61473 2.84772Z'
        fill='#005889'
      />
    </svg>
  );
};
