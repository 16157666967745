import { toRem } from '@stryberventures/gaia-react.theme';

const secondaryFont = 'DM Sans, sans-serif';

/**
 * Some of the colors are defined in components/calendar/styles.scss (primary, neutralGrey)
 * If design changes please update them as well
 */

const theme = {
  colors: {
    primary: {
      dark600: '#993D15',
      main500: '#C7501B',
      medium400: '#DD834D',
      medium300: '#EEA972',
      light200: '#F9CEA3',
      light100: '#FCE9D0',
      extraLight50: '#FDF4E7',
    },
    secondary: {
      dark600: '#052934',
      main500: '#1A5B6A',
      medium400: '#479BA5',
      medium300: '#73CED2',
      light200: '#A7F0ED',
      light100: '#D2F7F3',
      extraLight50: '#E9FBF9',
    },
    violet: {
      dark: '#7E2AD1',
      main: '#be94e8',
      light: '#DAC9EC',
      extraLight: '#f5eefc',
    },
    blue: {
      dark: '#2A77D1',
      main: '#95bbe8',
      light: '#C9E4EC',
      extraLight: '#eff5fc',
    },
    darkViolet: {
      dark: '#4B2AD1',
      main: '#a494e8',
      light: '#CCC9EC',
      extraLight: '#eff5fc',
    },
    error: {
      dark600: '#6B0D29',
      main500: '#BA2A2F',
      medium400: '#D56059',
      medium300: '#EA8C7D',
      light200: '#F8BCAB',
      light100: '#FBE1D4',
      extraLight50: '#FDF0E9',
    },
    success: {
      dark600: '#036555',
      main500: '#0AAF62',
      medium400: '#3CCF7C',
      medium300: '#64E78F',
      light200: '#99F7AF',
      light100: '#CBFBD1',
      extraLight50: '#E5FDE8',
    },
    warning: {
      dark600: '#894100',
      main500: '#ED8E00',
      medium400: '#F4B23D',
      medium300: '#F9C963',
      light200: '#FDE097',
      light100: '#FEF2CB',
      extraLight50: '#FFF9E5',
    },
    contrast: {
      white: '#FFFFFF',
      black: '#000000',
    },
    neutralGray: {
      extraDark900: '#101828',
      extraDark800: '#1D2939',
      dark700: '#344054',
      dark600: '#475467',
      main500: '#667085',
      medium400: '#98A2B3',
      medium300: '#D0D5DD',
      light200: '#E4E7EC',
      light100: '#F2F4F7',
      extraLight50: '#F9FAFB',
    },
    text: {
      headline: '#101828',
      secondary: '#475467',
      disabled: '#D0D5DD',
      tint: '#98A2B3',
      body: '#667085',
    },
    background: {
      white: '#FFFFFF',
      extraLightGrey: '#F9FAFB',
    },
    badge: {
      chilled: {
        backgroundColor: '#CBD6FE',
        color: '#002F89',
      },
      frozen: {
        backgroundColor: '#CBECFE',
        color: '#005889',
      },
    },
  },
  grid: {
    columns: {
      xs: 4,
      sm: 4,
      md: 8,
      lg: 12,
      xl: 12,
      xxl: 12,
    },
    margin: {
      xs: toRem(16),
      sm: toRem(16),
      md: toRem(32),
      lg: toRem(74),
      xl: toRem(150),
      xxl: toRem(150),
    },
    gap: {
      xs: toRem(16),
      sm: toRem(16),
      md: toRem(24),
      lg: toRem(24),
      xl: toRem(40),
      xxl: toRem(40),
    },
    maxWidth: 1320,
  },
  breakpoints: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1400,
  },
  spacing: {
    2: toRem(2),
    4: toRem(4),
    8: toRem(8),
    12: toRem(12),
    16: toRem(16),
    24: toRem(24),
    32: toRem(32),
    40: toRem(40),
    48: toRem(48),
    64: toRem(64),
    80: toRem(80),
    96: toRem(96),
    160: toRem(160),
  },
  font: 'Inter, sans-serif',
  secondaryFont,
  text: {
    h4: {
      fontFamily: secondaryFont,
      fontSize: 22,
      lineHeight: '28px',
      letterSpacing: -0.44,
    },
    h3: {
      fontFamily: secondaryFont,
      fontSize: 28,
      lineHeight: '34px',
      letterSpacing: -0.56,
    },
    h2: {
      fontFamily: secondaryFont,
      fontSize: 32,
      lineHeight: '40px',
      letterSpacing: -0.76,
    },
    h1: {
      fontFamily: secondaryFont,
      fontSize: 34,
      lineHeight: '40px',
      letterSpacing: -0.68,
    },
    body1: {
      fontFamily: secondaryFont,
      lineHeight: '28px',
      letterSpacing: -0.09,
    },
    buttonLabelMini: {
      fontFamily: secondaryFont,
      fontSize: 12,
      lineHeight: '18px',
      letterSpacing: -0.06,
    },
    body2: {
      fontFamily: secondaryFont,
      fontSize: 16,
      lineHeight: '24px',
      letterSpacing: -0.32,
    },
    body3: {
      fontFamily: secondaryFont,
      fontSize: 14,
      lineHeight: '20px',
      letterSpacing: -0.28,
    },
  },
};

type ProjectThemeType = typeof theme;

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace DesignSystem {
    interface IProjectTheme extends ProjectThemeType {}
  }
}

export default theme;
