import { apiClient, ApiClientConfig, PaginatedRequestParams, PaginatedResponse } from 'api';
import { REST_API_ENDPOINTS } from 'api/constants';
import { Pickup } from 'types/pickup';
import { Status } from 'types/status';

export type CreatePickupDTO = {
  userId?: string;
  locationId?: number;
  warehouseTypeId?: number;
  truckType?: string;
  metersRequested?: number;
  metersReal?: number;
  pickupDate?: string;
  contactPersonId?: number;
  comments?: string;
};

export type UpdatePickupDTO = {
  warehouseTypeId?: number;
  userId?: string;
  locationId?: number;
  truckTypeId?: number;
  metersRequested?: number;
  metersReal?: number;
  pickupDate?: string;
  contactPersonId?: number;
  comments?: string;
};

export type UpdatePickupStatusDTO = {
  id: string;
  userId?: string;
  status: Status;
  comment?: string;
};

export type UpdatePickupFinalDetailsDTO = {
  id: string;
  userId?: string;
  metersReal: number;
};

export type GetPickupsRequestParams = {
  userId?: string;
} & PaginatedRequestParams;

export type GetPickupRequestParams = {
  id: string;
  userId?: string;
};

export type DeletePickupRequestParams = {
  id: string;
  userId?: string;
};

export type PickupsResponse = PaginatedResponse<Pickup>;

export const PickupApiService = () => {
  const createPickup = async (payload?: CreatePickupDTO, apiConfig?: Partial<ApiClientConfig>) => {
    const response = await apiClient<Pickup>({
      ...apiConfig,
      endpoint: REST_API_ENDPOINTS.PICKUP,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };

  const getPickup = async (
    payload: GetPickupRequestParams,
    apiConfig?: Partial<ApiClientConfig>,
  ) => {
    const { id, ...params } = payload;
    const response = await apiClient<Pickup>({
      ...apiConfig,
      endpoint: `${REST_API_ENDPOINTS.PICKUP}/${id}`,
      params,
    });

    return response.data;
  };

  const getPickups = async (
    params: GetPickupsRequestParams,
    apiConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<PickupsResponse>({
      ...apiConfig,
      endpoint: REST_API_ENDPOINTS.PICKUP,
      params,
    });

    return response.data;
  };

  const updatePickup = async (
    id: string,
    payload: UpdatePickupDTO,
    apiConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<Pickup>({
      ...apiConfig,
      endpoint: `${REST_API_ENDPOINTS.PICKUP}/${id}`,
      method: 'PATCH',
      data: payload,
    });

    return response.data;
  };

  const deletePickup = async (
    payload: DeletePickupRequestParams,
    apiConfig?: Partial<ApiClientConfig>,
  ) => {
    const { id, ...params } = payload;

    const response = await apiClient<Pickup>({
      ...apiConfig,
      endpoint: `${REST_API_ENDPOINTS.PICKUP}/${id}`,
      method: 'DELETE',
      params,
    });

    return response.data;
  };

  const updatePickupStatus = async (
    payload: UpdatePickupStatusDTO,
    apiConfig?: Partial<ApiClientConfig>,
  ) => {
    const { id, ...data } = payload;

    const response = await apiClient<Pickup>({
      ...apiConfig,
      endpoint: `${REST_API_ENDPOINTS.PICKUP}/${id}/status`,
      method: 'PATCH',
      data,
    });

    return response.data;
  };

  const updatePickupFinalDetails = async (
    payload: UpdatePickupFinalDetailsDTO,
    apiConfig?: Partial<ApiClientConfig>,
  ) => {
    const { id, ...data } = payload;

    const response = await apiClient<Pickup>({
      ...apiConfig,
      endpoint: `${REST_API_ENDPOINTS.PICKUP}/${id}/final-details`,
      method: 'PATCH',
      data,
    });

    return response.data;
  };

  return {
    createPickup,
    getPickup,
    getPickups,
    updatePickup,
    deletePickup,
    updatePickupStatus,
    updatePickupFinalDetails,
  };
};
