import 'react-datepicker/dist/react-datepicker.css';
import './styles.scss';

import classNames from 'classnames';
import React from 'react';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import { DEFAULT_LOCALE } from 'types/date';

type CalendarProps = {
  date: Date | null;
  onChange: (date: Date | null) => void;
  isHighlighted?: boolean;
} & ReactDatePickerProps;
export const Calendar = ({ isHighlighted, onChange, date, ...props }: CalendarProps) => {
  return (
    <div className={classNames(isHighlighted && 'isHighlighted')}>
      <DatePicker selected={date} onChange={onChange} locale={DEFAULT_LOCALE} {...props} />
    </div>
  );
};
