import { createStyles } from '@stryberventures/gaia-react.theme';

export default createStyles((theme) => ({
  buttonGroupContainer: {
    borderRadius: 8,
    border: [1, 'solid', theme.colors.neutralGray.medium300],
    display: 'inline-block',
    overflow: 'hidden',
  },
  buttonGroupButton: {
    backgroundColor: theme.colors.contrast.white,
    color: theme.colors.text.tint,
    border: 'none',
    padding: [theme.spacing[8], theme.spacing[16]],
    borderRight: [1, 'solid', theme.colors.neutralGray.medium300],
    '&:last-child': {
      borderRight: 'none',
    },
    '&:hover': {
      backgroundColor: theme.colors.neutralGray.light100,
    },
  },
  buttonGroupActiveButton: {
    color: theme.colors.text.secondary,
    backgroundColor: theme.colors.neutralGray.light100,
  },
}));
