import { LatLngLiteral } from 'components/location/types';

export const DEFAULT_LAT_LNG = {
  lat: 24.701983779508424,
  lng: 46.67670519604528,
};

export const getLatLng = (value: string | LatLngLiteral): LatLngLiteral => {
  if (!value) {
    return DEFAULT_LAT_LNG;
  }

  const isLocationAlreadyLiteral = typeof value !== 'string';
  if (isLocationAlreadyLiteral) {
    return value;
  }
  const separatedString = value.split(',');
  const hasTwoPieces = separatedString.length === 2;
  const lat = Number(separatedString[0]);
  const lng = Number(separatedString[1]);
  const isLatValid = !isNaN(lat);
  const isLngValid = !isNaN(lng);

  if (hasTwoPieces && isLatValid && isLngValid) {
    return {
      lat,
      lng,
    };
  }
  return DEFAULT_LAT_LNG;
};

export const getLatLngString = (pin: LatLngLiteral | string): string => {
  if (typeof pin === 'string') return pin;
  return `${pin.lat},${pin.lng}`;
};
