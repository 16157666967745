import { QueryFunction, QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { handleError } from 'api/error-handling';
import {
  DeliveryApiService,
  GetDeliveryRequestParams,
} from 'features/delivery/api/delivery-api.service';
import { useReactQueryPowerUserUserIdManager } from 'features/users';
import { enqueueSnackbar } from 'notistack';
import { Delivery } from 'types/delivery';

import { deliveriesQueryKeys } from './query-keys';

const { getDelivery } = DeliveryApiService();

const getDeliveryQuery = (
  params: GetDeliveryRequestParams,
  options?: UseQueryOptions<Delivery>,
): {
  queryKey: QueryKey;
  queryFn: QueryFunction<Delivery>;
  options?: UseQueryOptions<Delivery>;
} => ({
  queryKey: deliveriesQueryKeys.detail(params.id),
  queryFn: ({ signal }) => getDelivery(params, { config: { signal } }),
  ...options,
});

export const useGetDelivery = (
  params: GetDeliveryRequestParams,
  options?: UseQueryOptions<Delivery>,
) => {
  const { enabled, updatedParams } = useReactQueryPowerUserUserIdManager(params, options);

  return useQuery<Delivery, Error>(
    getDeliveryQuery(updatedParams, {
      onError: (error) => {
        const uiError = handleError(error);
        enqueueSnackbar(uiError, { variant: 'error' });
      },
      ...options,
      enabled,
    }),
  );
};
