import { QUERY_KEYS } from 'features/query-keys';

/**
 * @see https://tkdodo.eu/blog/effective-react-query-keys#use-query-key-factories
 */
export const statisticsInProgressQueryKeys = {
  all: [QUERY_KEYS.statisticsInProgress] as const,
  lists: () => [...statisticsInProgressQueryKeys.all, 'list'] as const,
  list: (filters: object) => [...statisticsInProgressQueryKeys.lists(), { filters }] as const,
  details: () => [...statisticsInProgressQueryKeys.all, 'detail'] as const,
  detail: (id: string) => [...statisticsInProgressQueryKeys.details(), id] as const,
};

export const statisticsOverviewQueryKeys = {
  all: [QUERY_KEYS.statisticsOverview] as const,
  lists: () => [...statisticsOverviewQueryKeys.all, 'list'] as const,
  list: (filters: object) => [...statisticsOverviewQueryKeys.lists(), { filters }] as const,
  details: () => [...statisticsOverviewQueryKeys.all, 'detail'] as const,
  detail: (id: string) => [...statisticsOverviewQueryKeys.details(), id] as const,
};
