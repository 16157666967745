import { QueryFunction, QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query';
import {
  GetLocationsRequestParams,
  LocationApiService,
  LocationsResponse,
} from 'features/location/api/location-api.service';
import { useReactQueryPowerUserUserIdManager } from 'features/users';

import { locationQueryKeys } from './query-keys';

const { getLocations } = LocationApiService();

const getLocationsQuery = (
  params: GetLocationsRequestParams,
  options?: UseQueryOptions<LocationsResponse>,
): {
  queryKey: QueryKey;
  queryFn: QueryFunction<LocationsResponse>;
  options?: UseQueryOptions<LocationsResponse>;
} => ({
  queryKey: locationQueryKeys.list(params),
  queryFn: ({ signal }) => getLocations(params, { config: { signal } }),
  ...options,
});

export const useGetLocations = (
  params: GetLocationsRequestParams,
  options?: UseQueryOptions<LocationsResponse>,
) => {
  const { enabled, updatedParams } = useReactQueryPowerUserUserIdManager(params, options);

  return useQuery<LocationsResponse, Error>(
    getLocationsQuery(updatedParams, {
      onError: () => {},
      ...options,
      enabled,
    }),
  );
};
