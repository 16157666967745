import Text from '@stryberventures/gaia-react.text';
import { Badge } from 'components';
import useStyles from 'features/logistics/components/table/status/styles';
import React from 'react';
import { Status } from 'types/status';

type StatusProps = {
  status: Status;
};
export const StatusCell = ({ status }: StatusProps) => {
  const classes = useStyles();

  switch (status) {
    case Status.DRAFT:
      return <Badge mode={'neutralGray'}>{status}</Badge>;
    case Status.SUBMITTED:
    case Status.IN_TRANSIT:
    case Status.CONFIRMED:
      return <Badge mode={'primary'}>{status}</Badge>;

    case Status.FAILED:
    case Status.CANCELLED:
    case Status.REJECTED:
      return (
        <Text
          align={'center'}
          className={classes.dangerStatus}
          variant='components2'
          weight='regular'
        >
          {status}
        </Text>
      );
    case Status.DELIVERED:
    case Status.COMPLETED:
      return (
        <Text
          align={'center'}
          className={classes.completedStatus}
          variant='components2'
          weight='regular'
        >
          {status}
        </Text>
      );
  }
};
