import { SVGProps } from 'react';

export const LogisticsIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M21.67 18.66C21.48 18.29 21.03 18.15 20.66 18.33C19.65 18.85 18.62 19.13 17.6 19.21L21.1 15.15C21.56 14.63 21.67 13.91 21.38 13.27C21.09 12.63 20.48 12.24 19.79 12.24H18.75V9.98999C18.75 9.57999 18.41 9.23999 18 9.23999H14C13.59 9.23999 13.25 9.57999 13.25 9.98999V12.24H11.75V9.98999C11.75 9.02999 10.96 8.23999 10 8.23999H9.75V6.98999C9.75 6.02999 8.96 5.23999 8 5.23999H7.75V3.98999C7.75 3.57999 7.41 3.23999 7 3.23999C6.59 3.23999 6.25 3.57999 6.25 3.98999V5.23999H6C5.04 5.23999 4.25 6.02999 4.25 6.98999V8.23999H4C3.04 8.23999 2.25 9.02999 2.25 9.98999V18.99C2.25 18.99 2.27 19.06 2.27 19.09C2.27 19.14 2.28 19.2 2.3 19.25C2.32 19.29 2.34 19.33 2.37 19.37C2.4 19.41 2.42 19.45 2.45 19.49C2.49 19.53 2.53 19.55 2.57 19.59C2.6 19.61 2.62 19.64 2.66 19.66C2.67 19.66 2.69 19.67 2.7 19.68C4.11 20.39 5.51 20.73 6.99 20.74C8.26 20.74 9.05 20.45 9.75 20.19C10.42 19.95 10.99 19.74 11.99 19.74C12.99 19.74 13.56 19.95 14.23 20.19C14.93 20.44 15.72 20.73 16.99 20.74H17.03C18.47 20.74 19.92 20.38 21.33 19.66C21.7 19.47 21.85 19.02 21.66 18.65L21.67 18.66ZM14.75 10.75H17.25V12.25H14.75V10.75ZM5.75 6.99999C5.75 6.85999 5.86 6.74999 6 6.74999H8C8.14 6.74999 8.25 6.85999 8.25 6.99999V8.24999H5.75V6.99999ZM3.75 9.99999C3.75 9.85999 3.86 9.74999 4 9.74999H10C10.14 9.74999 10.25 9.85999 10.25 9.99999V12.25H3.75V9.99999ZM12 18.25C10.74 18.25 9.95 18.54 9.25 18.79C8.58 19.03 8 19.24 7 19.25C5.94 19.23 4.84 18.99 3.75 18.51V13.75H19.79C19.93 13.75 19.99 13.84 20.02 13.9C20.04 13.96 20.07 14.06 19.97 14.17L15.72 19.1C15.38 19.02 15.08 18.91 14.75 18.79C14.05 18.54 13.26 18.25 12 18.25Z'
        fill='#667085'
      />
    </svg>
  );
};
