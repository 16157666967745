import { createStyles } from '@stryberventures/gaia-react.theme';

export default createStyles((theme) => ({
  container: {
    height: 185,
    padding: theme.spacing[12],
    display: 'grid',
    gap: theme.spacing[12],
    borderBottom: [1, 'solid', theme.colors.neutralGray.light100],
    borderRight: [1, 'solid', theme.colors.neutralGray.light100],
  },
  imageWrapper: {
    position: 'relative',
  },
  badgeWrapper: {
    position: 'absolute',
    top: 6,
    left: 6,
    zIndex: 1,
  },
  infoWrapper: {
    display: 'grid',
  },
}));
