import { SearchIcon } from '@stryberventures/gaia-react.icons';
import Input from '@stryberventures/gaia-react.input';
import i18n from 'i18n';

import useStyles from './styles';

interface SearchProps {
  value: string;
  onValueChange: (value: string) => void;
}

export const Search = ({ onValueChange, value }: SearchProps) => {
  const classes = useStyles();

  return (
    <Input
      value={value}
      placeholder={i18n.screens.inventory.searchPlaceholder}
      leftIcon={<SearchIcon className={classes.searchIcon} />}
      onChange={(e) => onValueChange(e.target.value)}
      clearButton
    />
  );
};
