import { Location } from 'components/location/location';
import { useLocationContext } from 'components/location/location-context';
import { LocationMode } from 'components/location/types';
import { TopBar } from 'components/top-bar/top-bar';
import { TransportDate } from 'components/transport-date/transport-date';
import { TruckTypeForm } from 'components/truck-type-form/truck-type-form';
import { UpdatePickupStatusDTO } from 'features/pickup/api/pickup-api.service';
import { PickupNavigation } from 'features/pickup/components/pickup-navigation';
import { useGetQueryIdPickup } from 'features/pickup/hooks/get-query-id-pickup';
import { useGetQueryIdWarehouse } from 'features/pickup/hooks/get-query-id-warehouse';
import { usePickupLogisticsGuard } from 'features/pickup/hooks/logistics-guard';
import { useUpdateQueryIdPickup } from 'features/pickup/hooks/update-query-id-pickup';
import { Price } from 'features/pickup/routes/pickup-storage';
import { useUpdatePickupStatus } from 'features/pickup/use-cases/update-pickup-status';
import i18n from 'i18n';
import React, { useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
import { getRoute } from 'routes/utils/get-route';
import { Status } from 'types/status';

import useClasses from './styles';

export const PickupLogisticsView = () => {
  const classes = useClasses();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { id } = useParams();
  const { setMode, setSelectedLocationId } = useLocationContext();
  const { mutate: updatePickupStatus } = useUpdatePickupStatus();
  const { mutate: updatePickup, isLoading: isUpdatingPickup } = useUpdateQueryIdPickup();
  const [price, setPrice] = useState<Price>(null);

  const { isSuccess, data, isLoading } = useGetQueryIdPickup();
  const { data: warehouseData, isSuccess: isGetWarehouseSuccess } = useGetQueryIdWarehouse();

  usePickupLogisticsGuard();
  if (!isSuccess) return null;

  const isProceedDisabled = !data.locationId || !data.pickupDate || !data.truckTypeId;

  const navigateToStorage = () => {
    navigate({
      pathname: getRoute(ROUTES.PICKUP_STORAGE, data.id),
      search: searchParams.toString(),
    });
  };

  const handlePickupDateUpdate = (newDate: string) => {
    updatePickup({ pickupDate: newDate });
  };

  const handleLocationUpdate = (locationId: number) => {
    updatePickup({ locationId });
  };

  const handleCreateLocationSuccess = (locationId: number) => {
    updatePickup(
      { locationId },
      {
        onSuccess: () => {
          setSelectedLocationId(locationId);
          setMode(LocationMode.Select);
        },
      },
    );
  };

  const handleProceed = () => {
    if (id) {
      const payload: UpdatePickupStatusDTO = {
        id,
        status: Status.SUBMITTED,
      };
      updatePickupStatus(payload);
    }
  };

  return (
    <>
      <TopBar title={i18n.screens.pickupLogistics.title} onGoBackButtonClick={navigateToStorage} />
      <PickupNavigation
        isProceedDisabled={isProceedDisabled}
        onProceedClick={handleProceed}
        price={price}
      />
      <div className={classes.sections}>
        <TruckTypeForm
          truckTypeId={data.truckTypeId}
          comments={data.comments}
          isLoading={isUpdatingPickup}
          onTruckTypeChange={(truckTypeId) => {
            updatePickup({
              truckTypeId,
            });
          }}
          onCommentsBlur={(comments) => {
            updatePickup({ comments });
          }}
          onPriceChange={setPrice}
        />
        <TransportDate
          title={i18n.screens.pickupLogistics.transportTitle}
          startTime={warehouseData?.pickupStartTime}
          endTime={warehouseData?.pickupEndTime}
          initialTime={data.pickupDate}
          isLoading={!isGetWarehouseSuccess || isLoading}
          isUpdating={isUpdatingPickup}
          onUpdate={handlePickupDateUpdate}
        />
        <Location
          locationId={data.locationId}
          title={i18n.screens.pickupLogistics.locationTitle}
          onUpdate={handleLocationUpdate}
          onCreateLocation={handleCreateLocationSuccess}
          isUpdating={isUpdatingPickup}
          isLoading={isLoading}
        />
      </div>
    </>
  );
};
