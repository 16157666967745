import { createStyles } from '@stryberventures/gaia-react.theme';

export default createStyles((theme) => ({
  switchContainer: {
    padding: [theme.spacing[8], 14],
    display: 'flex',
    gap: theme.spacing[12],
    borderRadius: theme.spacing[8],
    border: [1, 'solid', theme.colors.neutralGray.medium300],
  },
  viewVariant: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  viewTableVariant: {
    '& path': {
      fill: theme.colors.neutralGray.medium300,
    },
  },
  activeTableView: {
    '& path': {
      fill: theme.colors.neutralGray.dark700,
    },
  },
  viewListVariant: {
    '& path:nth-child(2)': {
      fill: theme.colors.neutralGray.medium300,
    },
  },
  activeListView: {
    '& path:nth-child(2)': {
      fill: theme.colors.neutralGray.dark700,
    },
  },
}));
