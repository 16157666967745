import { useGetWarehouse } from 'features/warehouse/use-cases/get-warehouse';

import { useGetQueryIdPickup } from './get-query-id-pickup';

export const useGetQueryIdWarehouse = () => {
  const { data } = useGetQueryIdPickup();

  const warehouseId = data?.warehouseId;

  return useGetWarehouse(
    {
      id: warehouseId || -1,
    },
    {
      enabled: Boolean(warehouseId),
    },
  );
};
