import env from 'env';

export const REST_API_ENDPOINTS = {
  LOGIN: `${env.authApiUrl}/auth/login`,
  FORGOT_PASSWORD: `${env.authApiUrl}/auth/forgot-password`,
  FORGOT_PASSWORD_CONFIRM: `${env.authApiUrl}/auth/confirm-forgot-password-token`,
  NEW_PASSWORD_CHALLENGE: `${env.authApiUrl}/auth/new-password-challenge`,
  CHANGE_PASSWORD: `${env.authApiUrl}/auth/change-password`,
  SIGNUP: `${env.authApiUrl}/auth/signup`,
  VERIFICATION: `${env.authApiUrl}/auth/verification-token`,
  RESEND_VERIFICATION: `${env.authApiUrl}/auth/resend-verification`,
  OAUTH_LOGIN: `${env.authApiUrl}/auth/oauth/login`,

  USER_PRICES: '/user/prices',

  WAREHOUSES: '/warehouses',
  WAREHOUSE_TYPE: '/warehouses/warehouse-types',
  WAREHOUSE_ITEMS: '/warehouse-items',
  PICKUP: '/pickup',
  PICKUP_ITEMS: '/pickup-items',
  UPLOAD_PICKUP_ITEMS_CSV: '/pickup-items/csv',
  DOWNLOAD_PICKUP_ITEMS_CSV: '/pickup-items/:id/csv',
  EXISTING_PICKUP_ITEM: '/pickup-items/existing-item',
  DELIVERY: '/delivery',
  DELIVERY_ITEMS: '/delivery-items',
  DOWNLOAD_DELIVERY_ITEMS_CSV: '/delivery-items/:id/csv',
  LOCATION: '/location',
  CONTACT_PERSON: '/contact-person',
  TRUCK_TYPES: '/truck-types',

  STATISTICS_OVERVIEW: '/statistics/overview',
  STATISTICS_IN_PROGRESS: '/statistics/in-progress',

  MULTIMEDIA: '/multimedia',
};
