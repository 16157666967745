import Table, { TableBody } from '@stryberventures/gaia-react.table';
import { TableNoDataRow } from 'components';
import { InventoryListItem } from 'features/warehouse/components/inventory-list-item/inventory-list-item';
import React from 'react';
import { WarehouseItem } from 'types/warehouse-item';

import useStyles from './styles';

type InventoryListProps = {
  warehouseItems: WarehouseItem[];
};

export const InventoryList: React.FC<InventoryListProps> = ({ warehouseItems }) => {
  const classes = useStyles();

  if (!warehouseItems.length) {
    return (
      <Table>
        <TableBody>
          <TableNoDataRow />
        </TableBody>
      </Table>
    );
  }

  return (
    <div className={classes.itemsWrapper}>
      {warehouseItems.map((item) => (
        <InventoryListItem
          key={item.id}
          sku={item.item.sku}
          itemName={item.item.name}
          multimedia={item.item.multimedia}
          quantity={item.quantity}
          warehouseTypeLabel={item.warehouseType.label}
          itemId={item.itemId}
        />
      ))}
    </div>
  );
};
