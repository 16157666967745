import { usePermissions } from 'features/users/hooks/use-permissions';

import { useUserId } from './use-user-id';

export const useParams = <T extends { userId?: string }>(params: T) => {
  const userId = useUserId();
  const permissions = usePermissions();

  if (!permissions?.users.view) return params;

  if (userId) {
    return { ...params, userId };
  }
  return params;
};
