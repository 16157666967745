import { SVGProps } from 'react';

export const ClockIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='61'
      height='61'
      viewBox='0 0 61 61'
      fill='none'
      {...props}
    >
      <path
        d='M38.374 32.1114L33.0416 29.0335V17.7917C33.0416 17.1176 32.7738 16.4711 32.2972 15.9944C31.8205 15.5178 31.174 15.25 30.4999 15.25C29.8258 15.25 29.1794 15.5178 28.7027 15.9944C28.226 16.4711 27.9583 17.1176 27.9583 17.7917V30.5C27.9583 30.9461 28.0757 31.3844 28.2988 31.7708C28.5219 32.1572 28.8427 32.478 29.2291 32.7011L35.8323 36.5136C36.1215 36.6831 36.4414 36.7938 36.7735 36.8392C37.1056 36.8846 37.4434 36.8638 37.7674 36.7782C38.0915 36.6925 38.3954 36.5436 38.6617 36.3399C38.9279 36.1363 39.1513 35.8821 39.3189 35.5918C39.4865 35.3015 39.595 34.9809 39.6382 34.6485C39.6815 34.3161 39.6585 33.9785 39.5707 33.655C39.4828 33.3315 39.3319 33.0286 39.1265 32.7637C38.9211 32.4988 38.6654 32.2771 38.374 32.1114ZM30.4999 5.08333C25.473 5.08333 20.5589 6.57399 16.3792 9.36681C12.1994 12.1596 8.94172 16.1292 7.01799 20.7735C5.09426 25.4177 4.59093 30.5282 5.57164 35.4585C6.55235 40.3889 8.97305 44.9177 12.5276 48.4723C16.0822 52.0269 20.611 54.4476 25.5414 55.4283C30.4717 56.409 35.5822 55.9057 40.2265 53.9819C44.8708 52.0582 48.8403 48.8005 51.6331 44.6207C54.4259 40.441 55.9166 35.5269 55.9166 30.5C55.9092 23.7613 53.229 17.3008 48.4641 12.5359C43.6991 7.77092 37.2386 5.09073 30.4999 5.08333ZM30.4999 50.8333C26.4784 50.8333 22.5471 49.6408 19.2033 47.4065C15.8595 45.1723 13.2534 41.9967 11.7144 38.2812C10.1754 34.5658 9.77273 30.4774 10.5573 26.5332C11.3419 22.5889 13.2784 18.9658 16.1221 16.1222C18.9658 13.2785 22.5888 11.3419 26.5331 10.5574C30.4774 9.7728 34.5657 10.1755 38.2812 11.7144C41.9966 13.2534 45.1722 15.8596 47.4065 19.2034C49.6407 22.5472 50.8333 26.4784 50.8333 30.5C50.8265 35.8907 48.6821 41.0586 44.8703 44.8704C41.0586 48.6822 35.8906 50.8266 30.4999 50.8333Z'
        fill='#D0D5DD'
      />
    </svg>
  );
};
