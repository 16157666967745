import { createStyles, toRem } from '@stryberventures/gaia-react.theme';

interface IUseStyles {
  dir: string;
}

//eslint-disable-next-line
const useStyles = createStyles<any, IUseStyles>((theme) => ({
  loginEmail: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: `${theme.spacing[48]} 0 ${theme.spacing[80]}`,
    boxSizing: 'border-box',
    '*, *:after, *:before': {
      boxSizing: 'inherit',
    },
    height: '100vh',
  },
  logoWrapper: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    marginBottom: theme.spacing[24],
    boxSizing: 'border-box',
  },
  screen: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: toRem(560),
    width: '100%',
  },
  title: {
    width: '100%',
    marginBottom: theme.spacing[8],
    color: theme.colors.text.headline,
    fontSize: 28,
    fontFamily: theme.secondaryFont,
    fontWeight: 700,
  },
  description: {
    fontWeight: 400,
    color: theme.colors.neutralGray.main500,
    marginBottom: theme.spacing[32],
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flexGrow: 1,
  },
  emailInput: {
    position: 'relative',
    marginBottom: theme.spacing[48],
    ...hintAndErrorStyles,
  },
  passwordInput: {
    position: 'relative',
    marginBottom: theme.spacing[48],
    ...hintAndErrorStyles,
  },
  checkboxInput: {
    alignSelf: 'flex-start',
    marginBottom: theme.spacing[48],
    position: 'relative',
    ...hintAndErrorStyles,
  },
  submitButton: {
    marginBottom: theme.spacing[32],
  },
  textLink: {
    marginBottom: theme.spacing[40],
    fontSize: 14,
  },
  rememberMeContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  link: {
    '&>span': {
      fontSize: 14,
    },
  },
  signUpText: {
    fontSize: 14,
    color: theme.colors.neutralGray.main500,
  },
  [`@media (max-width: ${theme.breakpoints.md}px)`]: {
    loginEmail: {
      padding: [theme.spacing[48], theme.spacing[16]],
    },
    formContainer: {
      maxWidth: '100%',
      height: '100%',
    },
    emailInput: {
      marginBottom: theme.spacing[24],
    },
    passwordInput: {
      marginBottom: theme.spacing[24],
    },
    checkboxInput: {
      marginBottom: theme.spacing[24],
    },
    submitButton: {
      marginTop: 'auto',
    },
    textLink: {
      marginBottom: theme.spacing[48],
    },
  },
}));

const hintAndErrorStyles = {
  '& [class*=hint], & [class*=errorMessage]': {
    position: 'absolute',
    left: 0,
    top: '100%',
    width: '100%',
  },
};

export default useStyles;
