import { isSaudiWeekend } from 'utils';

import { getWarehouseTheDayAfterTomorrowOpeningHour } from './get-warehouse-the-day-after-tomorrow-opening-hour';
import { getWarehouseTomorrowOpeningHour } from './get-warehouse-tomorrow-opening-hour';
import { isWarehouseStillOpenToday } from './is-warehouse-still-open-today';
import { saudiFirstDayOfWeekOpeningHour } from './saudi-first-day-of-week-opening-hour';

export const getWarehouseNextAvailableDate = (openingHourUTC: number, closeHourUTC: number) => {
  const openingDateUTC = isWarehouseStillOpenToday(closeHourUTC)
    ? getWarehouseTomorrowOpeningHour(openingHourUTC)
    : getWarehouseTheDayAfterTomorrowOpeningHour(openingHourUTC);

  if (isSaudiWeekend(openingDateUTC)) {
    return saudiFirstDayOfWeekOpeningHour(openingHourUTC).toISOString();
  }
  return openingDateUTC.toISOString();
};
