import { apiClient, ApiClientConfig, PaginatedRequestParams, PaginatedResponse } from 'api';
import { REST_API_ENDPOINTS } from 'api/constants';
import { PickupItem } from 'types/pickup-item';
import { TypedFormData } from 'types/typed-form-data';

export type CreatePickupItemDTO = {
  userId?: string;
  name: string;
  quantity: number;
  pickupId: number;
};

export type AddExistingPickupItemDTO = {
  userId?: string;
  pickupId: number;
  itemId: number;
  quantity: number;
};

export type UpdatePickupItemDTO = {
  userId?: string;
  name?: string;
  sku?: string;
  description?: string;
  quantity?: number;
  pickupId?: number;
  warehouseTypeId?: number;
};

export type GetPickupItemsRequestParams = {
  userId?: string;
  name?: string;
  category?: string;
  description?: string;
  widthFrom?: number;
  widthTo?: number;
  depthFrom?: number;
  depthTo?: number;
  heightFrom?: number;
  heightTo?: number;
  warehouseTypeId?: number;
} & PaginatedRequestParams;

export type PickupItemsResponse = PaginatedResponse<PickupItem>;

export type DeletePickupItemDTO = {
  userId?: string;
  pickupId?: number;
  itemId: number;
};

export type DeletePickupItemResponse = {
  itemId: number;
  pickupId: number;
  deletedAt: string;
};

export type UploadPickupItemsCsvParams = { userId?: string; file: File; pickupId: string };
export type UploadPickupItemsCsvDTO = TypedFormData<UploadPickupItemsCsvParams>;

export type DownloadPickupItemsCsvDTO = {
  userId?: string;
};

export const PickupItemsApiService = () => {
  const createPickupItem = async (
    payload: CreatePickupItemDTO,
    apiConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient({
      ...apiConfig,
      endpoint: REST_API_ENDPOINTS.PICKUP_ITEMS,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };

  const addExistingPickupItem = async (
    payload: AddExistingPickupItemDTO,
    apiConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient({
      ...apiConfig,
      endpoint: REST_API_ENDPOINTS.EXISTING_PICKUP_ITEM,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };

  const getPickupItem = async (id: string, apiConfig?: Partial<ApiClientConfig>) => {
    const response = await apiClient({
      ...apiConfig,
      endpoint: `${REST_API_ENDPOINTS.PICKUP_ITEMS}/${id}`,
    });

    return response.data;
  };

  const getPickupItems = async (
    params: GetPickupItemsRequestParams,
    apiConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<PickupItemsResponse>({
      ...apiConfig,
      endpoint: REST_API_ENDPOINTS.PICKUP_ITEMS,
      params,
    });

    return response.data;
  };

  const updatePickupItem = async (
    id: number,
    payload: UpdatePickupItemDTO,
    apiConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient({
      ...apiConfig,
      endpoint: `${REST_API_ENDPOINTS.PICKUP_ITEMS}/${id}`,
      method: 'PATCH',
      data: payload,
    });

    return response.data;
  };

  const deletePickupItem = async (
    payload: DeletePickupItemDTO,
    apiConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<DeletePickupItemResponse>({
      ...apiConfig,
      endpoint: REST_API_ENDPOINTS.PICKUP_ITEMS,
      method: 'DELETE',
      data: payload,
    });

    return response.data;
  };

  const uploadPickupItemsCsv = async (
    payload: UploadPickupItemsCsvDTO,
    apiConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient({
      ...apiConfig,
      endpoint: REST_API_ENDPOINTS.UPLOAD_PICKUP_ITEMS_CSV,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };

  const downloadPickupItemsCsv = async (
    id: number,
    payload: DownloadPickupItemsCsvDTO,
    apiConfig?: Partial<ApiClientConfig>,
  ) => {
    return await apiClient<string>({
      ...apiConfig,
      endpoint: REST_API_ENDPOINTS.DOWNLOAD_PICKUP_ITEMS_CSV.replace(':id', id.toString()),
      method: 'GET',
      responseType: 'blob',
      params: {
        userId: payload.userId,
      },
    });
  };

  return {
    createPickupItem,
    addExistingPickupItem,
    getPickupItem,
    getPickupItems,
    updatePickupItem,
    deletePickupItem,
    uploadPickupItemsCsv,
    downloadPickupItemsCsv,
  };
};
