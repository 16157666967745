import { UseQueryOptions } from '@tanstack/react-query';
import { useMissingUserIdError } from 'features/auth';
import { useEnabled } from 'features/users/hooks/use-enabled';
import { useParams } from 'features/users/hooks/use-params';

export const useReactQueryPowerUserUserIdManager = <
  P extends { userId?: string },
  T extends object,
>(
  params: P,
  options?: UseQueryOptions<T>,
) => {
  const enabled = useEnabled<T>(options);
  const updatedParams = useParams(params);
  useMissingUserIdError(updatedParams, enabled);
  return {
    enabled,
    updatedParams,
  };
};
