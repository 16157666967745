import { QueryFunction, QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { handleError } from 'api';
import { useReactQueryPowerUserUserIdManager } from 'features/users';
import {
  GetWarehouseItemsRequestParams,
  WarehouseItemsApiService,
  WarehouseItemsResponse,
} from 'features/warehouse/api/warehouse-items-api.service';
import { warehouseItemsQueryKeys } from 'features/warehouse/use-cases/query-keys';
import { enqueueSnackbar } from 'notistack';

const { getWarehouseItems } = WarehouseItemsApiService();

const getWarehouseItemsQuery = (
  params: GetWarehouseItemsRequestParams,
  options?: UseQueryOptions<WarehouseItemsResponse>,
): {
  queryKey: QueryKey;
  queryFn: QueryFunction<WarehouseItemsResponse>;
  options?: UseQueryOptions<WarehouseItemsResponse>;
} => ({
  queryKey: warehouseItemsQueryKeys.list(params),
  queryFn: ({ signal }) => getWarehouseItems(params, { config: { signal } }),
  ...options,
});

export const useGetWarehouseItems = (
  params: GetWarehouseItemsRequestParams,
  options?: UseQueryOptions<WarehouseItemsResponse>,
) => {
  const { enabled, updatedParams } = useReactQueryPowerUserUserIdManager(params, options);

  return useQuery<WarehouseItemsResponse, Error>(
    getWarehouseItemsQuery(updatedParams, {
      onError: (error) => {
        const uiError = handleError(error);
        enqueueSnackbar(uiError, { variant: 'error' });
      },
      ...options,
      enabled,
    }),
  );
};
