import { useMutation } from '@tanstack/react-query';
import { handleError } from 'api/error-handling';
import {
  CreateDeliveryItemDTO,
  DeliveryItemsApiService,
} from 'features/delivery/api/delivery-items-api.service';
import { deliveriesQueryKeys } from 'features/delivery/use-cases/query-keys';
import { useUserId } from 'features/users';
import { queryClient } from 'index';
import { enqueueSnackbar } from 'notistack';

const { createDeliveryItem } = DeliveryItemsApiService();

export const useCreateDeliveryItem = (deliveryId?: string, onSuccess?: () => void) => {
  const userId = useUserId();

  return useMutation(
    (payload: CreateDeliveryItemDTO) => {
      return createDeliveryItem({ userId, ...payload });
    },
    {
      onSuccess: () => {
        if (deliveryId) {
          queryClient.invalidateQueries(deliveriesQueryKeys.detail(deliveryId));
          queryClient.invalidateQueries(deliveriesQueryKeys.list({ deliveryId: `${deliveryId}` }));
        }
        onSuccess && onSuccess();
      },
      onError: (error) => {
        const uiError = handleError(error);
        enqueueSnackbar(uiError, { variant: 'error' });
      },
    },
  );
};
