import axios from 'axios';
import { AuthStorageKey } from 'features/auth/auth-storage';
import i18n from 'i18n';
import { enqueueSnackbar } from 'notistack';
import { ROUTES } from 'routes/constants';

export function getAccessToken() {
  return (
    localStorage.getItem(AuthStorageKey.AccessToken) ||
    sessionStorage.getItem(AuthStorageKey.AccessToken)
  );
}

export function clearStorage() {
  localStorage.removeItem(AuthStorageKey.AccessToken);
}

const axiosInstance = axios.create({
  withCredentials: false,
});

axiosInstance.interceptors.request.use((request) => {
  if (request.headers) {
    const accessToken = getAccessToken();

    if (accessToken) {
      request.headers['Authorization'] = `Bearer ${accessToken}`;
    }
  }

  return request;
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401 || error.response.status === 403) {
      const isActivated = error?.response?.data?.message?.includes('is not activated');

      if (isActivated) {
        window.location.href = ROUTES.ACCOUNT_NOT_ACTIVATED;
      } else {
        clearStorage();
        enqueueSnackbar(i18n.commonErrors.sessionExpired, { variant: 'error' });
        window.location.href = ROUTES.LOGIN;
      }
    }

    throw error;
  },
);

export { axiosInstance };
