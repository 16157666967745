import { useUpdatePickup } from 'features/pickup/use-cases/update-pickup';
import { useParams } from 'react-router-dom';

type UseUpdateQueryIdPickupProps = {
  preventNavigate?: boolean;
};

export const useUpdateQueryIdPickup = ({ preventNavigate }: UseUpdateQueryIdPickupProps = {}) => {
  const { id } = useParams();

  if (!id) {
    throw new Error('Can not update pickup, because pickup id in the url is missing');
  }

  return useUpdatePickup({ id, preventNavigate });
};
