import { UseQueryOptions } from '@tanstack/react-query';
import { GetDeliveryRequestParams } from 'features/delivery/api/delivery-api.service';
import { useGetDelivery } from 'features/delivery/use-cases/get-delivery';
import { defaultTo } from 'ramda';
import { useParams } from 'react-router-dom';
import { Delivery } from 'types/delivery';

export const useGetQueryIdDelivery = (options?: UseQueryOptions<Delivery>) => {
  const { id } = useParams();

  if (!id) {
    throw new Error('Can not get delivery, because delivery id in the url is missing');
  }

  const params: GetDeliveryRequestParams = { id };

  return useGetDelivery(params, {
    ...options,
    enabled: defaultTo(options?.enabled, true) && Boolean(id),
  });
};
