import { SVGProps } from 'react';

export const DashboardIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='18'
      height='20'
      viewBox='0 0 18 20'
      fill='none'
      {...props}
    >
      <path
        d='M17.48 5.17999L16.45 2.59999C15.88 1.16999 14.51 0.23999 12.97 0.23999H5.03C3.49 0.23999 2.12 1.16999 1.55 2.59999L0.52 5.17999C0.34 5.62999 0.25 6.08999 0.25 6.56999V15.99C0.25 18.06 1.93 19.74 4 19.74H14C16.07 19.74 17.75 18.06 17.75 15.99V6.57999C17.75 6.09999 17.66 5.62999 17.48 5.18999V5.17999ZM15.06 3.15999L15.89 5.24999H9.75V1.74999H12.97C13.9 1.74999 14.72 2.30999 15.06 3.15999ZM2.94 3.15999C3.28 2.29999 4.1 1.74999 5.03 1.74999H8.25V5.24999H2.11L2.94 3.15999ZM14 18.25H4C2.76 18.25 1.75 17.24 1.75 16V6.74999H16.25V16C16.25 17.24 15.24 18.25 14 18.25Z'
        fill='white'
      />
      <path
        d='M7 14.25H4C3.59 14.25 3.25 14.59 3.25 15C3.25 15.41 3.59 15.75 4 15.75H7C7.41 15.75 7.75 15.41 7.75 15C7.75 14.59 7.41 14.25 7 14.25Z'
        fill='white'
      />
    </svg>
  );
};
