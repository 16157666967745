import { StorageItemsList } from 'components';
import { Text } from 'components/text/text';
import { DeliveryItem } from 'features/delivery/components/delivery-items-list/delivery-item/delivery-item';
import i18n from 'i18n';
import React, { useEffect } from 'react';
import { ItemToDelivery } from 'types/item-to-delivery';
import { WarehouseItem } from 'types/warehouse-item';

import useStyles from './styles';

type DeliveryItemsListProps = {
  items: ItemToDelivery[];
  warehouseItems: WarehouseItem[];
};

export const DeliveryItemsList: React.FC<DeliveryItemsListProps> = ({ items, warehouseItems }) => {
  const [itemList, setItemList] = React.useState<ItemToDelivery[]>([]);
  const classes = useStyles();

  useEffect(() => {
    setItemList(items);
  }, [items]);

  return (
    <section className={classes.wrapper}>
      <Text variant='components1' weight='medium'>
        {i18n.screens.deliveryStorage.deliveryItemsList.title}
      </Text>
      <StorageItemsList isEmpty={!!itemList.length}>
        {itemList.map((item) => {
          const warehouseItem = warehouseItems.find(
            (warehouseItem) => warehouseItem.itemId === item.itemId,
          );
          if (!warehouseItem) return null;
          return <DeliveryItem maxQuantity={warehouseItem.quantity} key={item.id} item={item} />;
        })}
      </StorageItemsList>
    </section>
  );
};
