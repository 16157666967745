import { User, userRole } from 'types/user';

export const isPowerUser = (user: User) => user.roles.includes(userRole.ADMIN_ROLE);
export const isUser = (user: User) => user.roles.includes(userRole.USER_ROLE);
export const getUserPermissions = (user: User) => {
  return {
    users: {
      view: isPowerUser(user),
    },
    pickupStorage: {
      view: true,
      edit: true,
    },
    pickupLogistics: {
      view: true,
      edit: true,
    },
    pickupCreatedInfo: {
      view: true,
      edit: true,
    },
    deliveryStorage: {
      view: true,
      edit: true,
    },
    deliveryLogistics: {
      view: true,
      edit: true,
    },
    deliveryCreatedInfo: {
      view: true,
      edit: true,
    },
    pickupOverview: {
      view: isPowerUser(user),
      edit: isPowerUser(user),
    },
    deliveryOverview: {
      view: isPowerUser(user),
      edit: isPowerUser(user),
    },
    logistics: {
      view: true,
      edit: true,
    },
    inventory: {
      view: true,
      edit: true,
    },
  };
};

export type Permissions = ReturnType<typeof getUserPermissions>;
export type PermissionKey = NestedKeyOf<Permissions>;

export const hasRightsToUseUserId = (user: User) => isPowerUser(user);
