import { QueryFunction, QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { handleError } from 'api/error-handling';
import {
  GetWarehouseRequestParams,
  WarehouseApiService,
} from 'features/warehouse/api/warehouse-api.service';
import { enqueueSnackbar } from 'notistack';
import { Warehouse } from 'types/warehouse';

import { warehouseQueryKeys } from './query-keys';

const { getWarehouse } = WarehouseApiService();

const getWarehouseQuery = (
  params: GetWarehouseRequestParams,
  options?: UseQueryOptions<Warehouse>,
): {
  queryKey: QueryKey;
  queryFn: QueryFunction<Warehouse>;
  options?: UseQueryOptions<Warehouse>;
} => ({
  queryKey: warehouseQueryKeys.list(params),
  queryFn: ({ signal }) => getWarehouse(params, { config: { signal } }),
  ...options,
});

export const useGetWarehouse = (
  params: GetWarehouseRequestParams,
  options?: UseQueryOptions<Warehouse>,
) => {
  return useQuery<Warehouse, Error>(
    getWarehouseQuery(params, {
      onError: (err) => {
        const errorMassage = handleError(err);
        enqueueSnackbar(errorMassage, { variant: 'error' });
      },
      ...options,
    }),
  );
};
