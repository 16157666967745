import { createStyles } from '@stryberventures/gaia-react.theme';

export default createStyles((theme) => ({
  pageNavigationBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: [theme.spacing['12'], theme.spacing['32']],
    backgroundColor: theme.colors.neutralGray.extraLight50,
  },
  side: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing['16'],
  },
}));
