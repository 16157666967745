import { useMutation } from '@tanstack/react-query';
import { AuthApiService, VerificationDTO } from 'features/auth/auth-api.service';

const { verify } = AuthApiService();

export const useVerify = () => {
  const options = (payload: VerificationDTO) => {
    return verify(payload);
  };

  return useMutation(options);
};
