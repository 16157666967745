import { CustomButton, Text } from 'components';
import { CountIcon } from 'components/Icons/Count';
import { useCreateDelivery } from 'features/delivery/use-cases/create-delivery';
import { useCreatePickup } from 'features/pickup';
import i18n from 'i18n';
import React from 'react';

import useStyles from './styles';

export const LogisticsControls = () => {
  const classes = useStyles();
  const { mutate: createPickup } = useCreatePickup();
  const { mutate: createDelivery } = useCreateDelivery();

  const handleCreatePickup = () => {
    createPickup({});
  };

  const handleCreateDelivery = () => {
    createDelivery({});
  };

  return (
    <section className={classes.controls}>
      <Text weight={'bold'}>{i18n.screens.logistics.table.title}</Text>

      {/* Pickups / Deliveries buttons will be placed here*/}
      <div className={classes.switch} />

      <div className={classes.buttonWrapper}>
        <CustomButton
          className={classes.button}
          onClick={handleCreatePickup}
          size='small'
          iconLeft={<CountIcon />}
          variant='outlined'
        >
          {i18n.screens.logistics.table.buttons.newPickup}
        </CustomButton>
        <CustomButton
          className={classes.button}
          onClick={handleCreateDelivery}
          size='small'
          iconLeft={<CountIcon />}
          variant='outlined'
        >
          {i18n.screens.dashboard.section.overview.newDelivery}
        </CustomButton>
      </div>
    </section>
  );
};
