import { createStyles } from '@stryberventures/gaia-react.theme';

export default createStyles((theme) => ({
  container: {
    display: 'grid',
    gap: theme.spacing[16],
  },
  titleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  image: {
    objectFit: 'contain',
    width: 520,
    height: 435,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  closeButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 30,
    height: 30,
    '& svg path': {
      fill: theme.colors.primary.main500,
    },
    cursor: 'pointer',
  },
  actionButtonWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    gap: theme.spacing[12],
  },
}));
