import RadioButton from '@stryberventures/gaia-react.radio-button';
import Text from '@stryberventures/gaia-react.text';
import { Order } from 'api';
import { useGetPickupItems } from 'features/pickup/use-cases/get-pickup-items';
import i18n from 'i18n';
import React from 'react';
import { useParams } from 'react-router-dom';

import { AddExistingItemForm } from './add-existing-item-form/add-existing-item-form';
import { AddNewItemForm } from './add-new-item-form/add-new-item-form';
import { BulkUploadItemsInputs } from './bulk-upload-items-input/bulk-upload-items-inputs';
import useStyles from './styles';

enum AddItemFormType {
  showNewItemForm = 'showNewItemForm',
  showExistingItemForm = 'showExistingItemForm',
  showBulkUploadItemsInput = 'showBulkUploadItemsInput',
}

const initialParams = { order: Order.asc, page: 1, take: 50 };

export const AddItemForm: React.FC = () => {
  const bulkUploadRef = React.useRef<HTMLInputElement>(null);
  const [addItemFormType, setAddItemFormType] = React.useState<AddItemFormType>(
    AddItemFormType.showNewItemForm,
  );
  const { id } = useParams();
  const { data } = useGetPickupItems(initialParams);
  const classes = useStyles();

  const handleAddItemFormTypeChange = (e: React.BaseSyntheticEvent) => {
    const value = e.target.value;
    setAddItemFormType(value);
  };

  const handleBulkUpload = () => {
    if (bulkUploadRef.current) {
      bulkUploadRef.current.click();
    }
  };

  const renderForm = () => {
    switch (addItemFormType) {
      case AddItemFormType.showNewItemForm:
        return <AddNewItemForm />;
      case AddItemFormType.showBulkUploadItemsInput:
        return (
          <BulkUploadItemsInputs pickupId={id} ref={bulkUploadRef} onClick={handleBulkUpload} />
        );
      case AddItemFormType.showExistingItemForm:
        return <AddExistingItemForm pickupItems={data?.data || []} />;
      default:
        return null;
    }
  };

  return (
    <section className={classes.addItemSection}>
      <Text>{i18n.screens.pickupStorage.addItemForm.formControl.title}</Text>
      <div className={classes.radioButtonWrapper}>
        <RadioButton
          name='addItem'
          label={i18n.screens.pickupStorage.addItemForm.newRadioButton.label}
          value={AddItemFormType.showNewItemForm}
          onChange={handleAddItemFormTypeChange}
          checked={addItemFormType === AddItemFormType.showNewItemForm}
        />
        <RadioButton
          name='addItem'
          label={i18n.screens.pickupStorage.addItemForm.existingRadioButton.label}
          value={AddItemFormType.showExistingItemForm}
          onChange={handleAddItemFormTypeChange}
          checked={addItemFormType === AddItemFormType.showExistingItemForm}
        />
        <RadioButton
          name='addItem'
          label={i18n.screens.pickupStorage.addItemForm.bulkUploadRadioButton.label}
          value={AddItemFormType.showBulkUploadItemsInput}
          onChange={handleAddItemFormTypeChange}
          checked={addItemFormType === AddItemFormType.showBulkUploadItemsInput}
        />
      </div>
      {renderForm()}
    </section>
  );
};
