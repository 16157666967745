import { useMutation } from '@tanstack/react-query';
import { AuthApiService, ResendVerificationDTO } from 'features/auth/auth-api.service';
import { enqueueSnackbar } from 'notistack';

const { resendVerificationEmail } = AuthApiService();

export const useResendVerificationEmail = () => {
  const options = (payload: ResendVerificationDTO) => {
    return resendVerificationEmail(payload);
  };

  return useMutation(options, {
    onError: () => {
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    },
    onSuccess: () => {
      enqueueSnackbar('Confirmation email sent successfully', { variant: 'success' });
    },
  });
};
