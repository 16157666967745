import { QueryFunction, QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { UserApiService } from 'features/users/api/users-api-service';
import { userQueryKeys } from 'features/users/use-cases/query-keys';
import { User } from 'types/user';

const { getMe } = UserApiService();

export const getMeQuery = (
  options?: UseQueryOptions<User>,
): {
  queryKey: QueryKey;
  queryFn: QueryFunction<User>;
  options?: UseQueryOptions<User>;
} => ({
  queryKey: userQueryKeys.detail('me'),
  queryFn: ({ signal }) => getMe({ config: { signal } }),
  ...options,
});

export const useGetMe = (options?: UseQueryOptions<User>) => {
  return useQuery<User, Error>(
    getMeQuery({
      ...{ retry: false, ...options },
    }),
  );
};
