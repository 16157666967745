import { useMutation } from '@tanstack/react-query';
import { handleError } from 'api/error-handling';
import {
  DeliveryApiService,
  UpdateDeliveryStatusDTO,
} from 'features/delivery/api/delivery-api.service';
import { useUserId } from 'features/users';
import { enqueueSnackbar } from 'notistack';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
import { getRoute } from 'routes/utils/get-route';

const { updateDeliveryStatus } = DeliveryApiService();

interface UseUpdateDeliveryStatusProps {
  preventNavigate?: boolean;
}

export const useUpdateDeliveryStatus = ({ preventNavigate }: UseUpdateDeliveryStatusProps = {}) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const userId = useUserId();

  return useMutation(
    (payload: UpdateDeliveryStatusDTO) => {
      return updateDeliveryStatus({ userId, ...payload });
    },
    {
      onSuccess: (data) => {
        if (preventNavigate) return;
        navigate({
          pathname: getRoute(ROUTES.DELIVERY_CREATED_INFO, data.id),
          search: searchParams.toString(),
        });
      },
      onError: (error) => {
        const uiError = handleError(error);
        enqueueSnackbar(uiError, { variant: 'error' });
      },
    },
  );
};
