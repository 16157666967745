import Divider from '@stryberventures/gaia-react.divider';
import { PointArrowIcon } from '@stryberventures/gaia-react.icons';
import { PaginatedResponseMeta } from 'api';
import classNames from 'classnames';
import { Text, TextColor } from 'components/text/text';
import i18n from 'i18n';
import React, { useEffect } from 'react';

import useStyles from './table.styles';

type TablePaginationProps = {
  meta: PaginatedResponseMeta;
  onPageChange: (page: number) => void;
};

export const TablePagination: React.FC<TablePaginationProps> = ({ meta, onPageChange }) => {
  const [currentPage, setCurrentPage] = React.useState(meta.page);
  const classes = useStyles();

  const handleNextPage = () => {
    setCurrentPage((prev) => prev + 1);
  };

  const handlePrevPage = () => {
    setCurrentPage((prev) => prev - 1);
  };

  useEffect(() => {
    onPageChange(currentPage);
  }, [currentPage, onPageChange]);

  const getPaginationLabel = () => {
    const firstItem = meta.take * (meta.page - 1) + 1;
    const lastItem = Math.min(meta.take * meta.page, meta.itemCount);

    return i18n.components.table.paginationLabel(firstItem, lastItem, meta.itemCount);
  };

  if (meta.page === 1 && !meta.hasNextPage) {
    return null;
  }

  return (
    <>
      <Divider />
      <div className={classes.paginationContainer}>
        <Text color={TextColor.Tint} variant='components2'>
          {getPaginationLabel()}
        </Text>
        <div className={classes.iconsContainer}>
          <div
            className={classNames(classes.iconWrapper, {
              [classes.disabledIcon]: !meta.hasPreviousPage,
            })}
            onClick={handlePrevPage}
          >
            <PointArrowIcon variant={'left'} />
          </div>
          <div
            className={classNames(classes.iconWrapper, {
              [classes.disabledIcon]: !meta.hasNextPage,
            })}
            onClick={handleNextPage}
          >
            <PointArrowIcon variant={'right'} />
          </div>
        </div>
      </div>
    </>
  );
};
