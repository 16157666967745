import { QueryFunction, QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { handleError } from 'api/error-handling';
import { TruckApiService, TruckTypesResponse } from 'features/truck/api/truck-api-service';
import { enqueueSnackbar } from 'notistack';

import { truckTypesQueryKeys } from './query-keys';

const { getTruckTypes } = TruckApiService();

const getTruckTypesQuery = (
  options?: UseQueryOptions<TruckTypesResponse>,
): {
  queryKey: QueryKey;
  queryFn: QueryFunction<TruckTypesResponse>;
  options?: UseQueryOptions<TruckTypesResponse>;
} => ({
  queryKey: truckTypesQueryKeys.all,
  queryFn: ({ signal }) => getTruckTypes({ config: { signal } }),
  ...options,
});

export const useGetTruckTypes = (options?: UseQueryOptions<TruckTypesResponse>) => {
  return useQuery<TruckTypesResponse, Error>(
    getTruckTypesQuery({
      onError: (err) => {
        const errorMassage = handleError(err);
        enqueueSnackbar(errorMassage, { variant: 'error' });
      },
      ...options,
    }),
  );
};
