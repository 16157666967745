import { useState } from 'react';

export const usePagination = () => {
  const [page, setPage] = useState(1);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  return {
    page,
    onPageChange,
  };
};
