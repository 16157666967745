import { createStyles } from '@stryberventures/gaia-react.theme';

export default createStyles((theme) => ({
  addExistingItemForm: {
    display: 'grid',
    gap: theme.spacing[16],
    gridTemplateColumns: '1fr 170px 80px',
  },
  addItemButton: {
    '& path': {
      fill: theme.colors.neutralGray.medium300,
    },
  },
}));
