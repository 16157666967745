import Text from '@stryberventures/gaia-react.text';
import classNames from 'classnames';
import React from 'react';
import { Option, Value } from 'types/option';

import useStyles from './styles';

type ButtonGroupProps = {
  options: Option[];
  onChange: (value: Value) => void;
  activeValue: Value;
};

export const ButtonGroup: React.FC<ButtonGroupProps> = ({ options, onChange, activeValue }) => {
  const classes = useStyles();
  return (
    <div className={classes.buttonGroupContainer}>
      {options.map(({ label, value }) => (
        <button
          key={label}
          className={classNames(classes.buttonGroupButton, {
            [classes.buttonGroupActiveButton]: activeValue === value,
          })}
          onClick={() => onChange(value)}
        >
          <Text weight='medium' variant='components2'>
            {label}
          </Text>
        </button>
      ))}
    </div>
  );
};
