import { useMutation } from '@tanstack/react-query';
import { AuthApiService, ForgotPasswordDTO } from 'features/auth/auth-api.service';
import i18n from 'i18n';
import { enqueueSnackbar } from 'notistack';

const { forgotPassword } = AuthApiService();

export const useForgotPassword = () => {
  const options = (credentials: ForgotPasswordDTO) => {
    return forgotPassword(credentials);
  };

  return useMutation(options, {
    onError: () => {
      enqueueSnackbar(i18n.commonErrors.somethingWentWrong, { variant: 'error' });
    },
    onSuccess: () => {
      enqueueSnackbar(i18n.screens.forgotPassword.successMessage, {
        variant: 'success',
      });
    },
  });
};
