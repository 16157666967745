import { CustomButton } from 'components/custom-button/custom-button';
import { DashboardStatText } from 'components/dashboard-stat-text/dashboard-stat-text';
import { CountIcon } from 'components/Icons';
import useStyles from 'features/dashboard/components/dashboard-overview/styles';
import { useStatisticsOverview } from 'features/dashboard/use-cases/statistics-overview';
import { useCreateDelivery } from 'features/delivery/use-cases/create-delivery';
import { useCreatePickup } from 'features/pickup';
import i18n from 'i18n';
import React from 'react';

export const DashboardOverviewStatistics: React.FC = () => {
  const { data, isLoading } = useStatisticsOverview({});
  const { mutate: createPickup } = useCreatePickup();
  const { mutate: createDelivery } = useCreateDelivery();
  const classes = useStyles();

  const handleCreatePickup = () => {
    createPickup({});
  };

  const handleCreateDelivery = () => {
    createDelivery({});
  };

  return (
    <div className={classes.actionContainer}>
      <div className={classes.statContainer}>
        <DashboardStatText
          loading={isLoading}
          title={i18n.screens.dashboard.section.overview.statistics.items.title}
          count={data?.itemsCount}
          type={i18n.screens.dashboard.section.overview.statistics.items.type}
        />
        <DashboardStatText
          loading={isLoading}
          title={i18n.screens.dashboard.section.overview.statistics.request.title}
          count={data?.inProgress}
          type={i18n.screens.dashboard.section.overview.statistics.request.type}
        />
      </div>
      {/* TODO: SOR-121 Update and uncomment when backend is ready */}
      {/* <Text className={classes.subtitle}>{i18n.screens.dashboard.section.overview.links}</Text> */}
      <div className={classes.buttonWrapper}>
        <CustomButton onClick={handleCreatePickup} iconLeft={<CountIcon />} variant='outlined'>
          {i18n.screens.dashboard.section.overview.newPickup}
        </CustomButton>
        <CustomButton onClick={handleCreateDelivery} iconLeft={<CountIcon />} variant='outlined'>
          {i18n.screens.dashboard.section.overview.newDelivery}
        </CustomButton>
        {/* TODO: SOR-121 Update and uncomment when delivery flow is ready */}
        {/* <CustomButton iconLeft={<LogisticsIcon />} variant='outlined'> */}
        {/*   {i18n.screens.dashboard.section.overview.deliverItems} */}
        {/* </CustomButton> */}
      </div>
    </div>
  );
};
