import { createStyles } from '@stryberventures/gaia-react.theme';

export default createStyles((theme) => ({
  addItemSection: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing[12],
  },
  radioButtonWrapper: {
    display: 'flex',
  },
}));
