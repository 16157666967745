import { createStyles } from '@stryberventures/gaia-react.theme';

export default createStyles((theme) => ({
  sidesText: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  overviewBottom: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing[8],
  },
}));
