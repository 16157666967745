import { TruckType } from 'types/truck-type';

export const getTruckTypesOptions = (truckTypes?: TruckType[]) => {
  if (!truckTypes) return [];

  return truckTypes.map((truckType) => ({
    label: truckType.name,
    value: truckType.id,
  }));
};
