import { createStyles } from '@stryberventures/gaia-react.theme';

interface IUseStyles {
  dir: string;
}
//eslint-disable-next-line
export default createStyles<any, IUseStyles>((theme) => ({
  switch: {
    display: 'grid',
    gridTemplateColumns: 'min-content max-content',
    gap: '8px',
  },
}));
