import { QueryFunction, QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { handleError } from 'api';
import {
  GetPickupItemsRequestParams,
  PickupItemsApiService,
  PickupItemsResponse,
} from 'features/pickup/api/pickup-items-api.service';
import { pickupItemsQueryKeys } from 'features/pickup/use-cases/items-query-keys';
import { useReactQueryPowerUserUserIdManager } from 'features/users';
import { enqueueSnackbar } from 'notistack';

const { getPickupItems } = PickupItemsApiService();

const getPickupItemsQuery = (
  params: GetPickupItemsRequestParams,
  options?: UseQueryOptions<PickupItemsResponse>,
): {
  queryKey: QueryKey;
  queryFn: QueryFunction<PickupItemsResponse>;
  options?: UseQueryOptions<PickupItemsResponse>;
} => ({
  queryKey: pickupItemsQueryKeys.list(params),
  queryFn: ({ signal }) => getPickupItems(params, { config: { signal } }),
  ...options,
});

export const useGetPickupItems = (
  params: GetPickupItemsRequestParams,
  options?: UseQueryOptions<PickupItemsResponse>,
) => {
  const { enabled, updatedParams } = useReactQueryPowerUserUserIdManager(params, options);

  return useQuery<PickupItemsResponse, Error>(
    getPickupItemsQuery(updatedParams, {
      onError: (error) => {
        const uiError = handleError(error);
        enqueueSnackbar(uiError, { variant: 'error' });
      },
      ...options,
      enabled,
    }),
  );
};
