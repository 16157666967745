import { createStyles, toRem } from '@stryberventures/gaia-react.theme';

export default createStyles((theme) => ({
  signUpSuccess: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: `${theme.spacing[48]} 0 ${theme.spacing[80]}`,
    boxSizing: 'border-box',
    '*, *:after, *:before': {
      boxSizing: 'inherit',
    },
  },
  screen: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: toRem(560),
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    textAlign: 'center',
  },
  title: {
    width: '100%',
    marginBottom: theme.spacing[24],
    color: theme.colors.text.headline,
    fontSize: 28,
    fontFamily: theme.secondaryFont,
    fontWeight: 700,
  },
  description: {
    color: theme.colors.text.body,
    marginBottom: theme.spacing[48],
  },
  resendButton: {
    marginBottom: theme.spacing[24],
  },
  [`@media (max-width: ${theme.breakpoints.md}px)`]: {
    signUpSuccess: {
      height: '100vh',
      padding: [theme.spacing[48], theme.spacing[16]],
    },
  },
}));
