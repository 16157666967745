import { SVGProps } from 'react';

export const PickupIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M13.3333 10.1667H2.66667C2.02667 10.1667 1.5 10.6933 1.5 11.3333V12.6667C1.5 13.3067 2.02667 13.8333 2.66667 13.8333H13.3333C13.9733 13.8333 14.5 13.3067 14.5 12.6667V11.3333C14.5 10.6933 13.9733 10.1667 13.3333 10.1667ZM13.5 12.6667C13.5 12.76 13.4267 12.8333 13.3333 12.8333H2.66667C2.57333 12.8333 2.5 12.76 2.5 12.6667V11.3333C2.5 11.24 2.57333 11.1667 2.66667 11.1667H13.3333C13.4267 11.1667 13.5 11.24 13.5 11.3333V12.6667Z'
        fill='#C7501B'
      />
      <path
        d='M3.02 5.02L4.16667 3.87333V8.66667C4.16667 8.94 4.39334 9.16667 4.66667 9.16667C4.94 9.16667 5.16667 8.94 5.16667 8.66667V3.87333L6.31334 5.02C6.41334 5.12 6.54 5.16667 6.66667 5.16667C6.79333 5.16667 6.92 5.12 7.02 5.02C7.21334 4.82667 7.21334 4.50667 7.02 4.31333L5.02 2.31333C4.82667 2.12 4.50667 2.12 4.31333 2.31333L2.31333 4.31333C2.12 4.50667 2.12 4.82667 2.31333 5.02C2.50667 5.21334 2.82667 5.21334 3.02 5.02Z'
        fill='#C7501B'
      />
      <path
        d='M9.68669 5.02L10.8334 3.87333V8.66667C10.8334 8.94 11.06 9.16667 11.3334 9.16667C11.6067 9.16667 11.8334 8.94 11.8334 8.66667V3.87333L12.98 5.02C13.08 5.12 13.2067 5.16667 13.3334 5.16667C13.46 5.16667 13.5867 5.12 13.6867 5.02C13.88 4.82667 13.88 4.50667 13.6867 4.31333L11.6867 2.31333C11.4934 2.12 11.1734 2.12 10.98 2.31333L8.98002 4.31333C8.78669 4.50667 8.78669 4.82667 8.98002 5.02C9.17336 5.21334 9.49335 5.21334 9.68669 5.02Z'
        fill='#C7501B'
      />
    </svg>
  );
};
