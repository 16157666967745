import { createStyles } from '@stryberventures/gaia-react.theme';

export default createStyles((theme) => ({
  tableHeader: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  tableContainer: {
    boxShadow: 'none',
  },
  tableOriginCellWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  },
  noDataCell: {
    textAlign: 'center',
  },
  primary: {
    borderLeft: `2px solid ${theme.colors.primary.main500}`,
    backgroundColor: theme.colors.primary.extraLight50,
  },
  danger: {
    backgroundColor: theme.colors.error.extraLight50,
  },
  muted: {
    backgroundColor: theme.colors.neutralGray.extraLight50,
  },
  standard: {
    backgroundColor: theme.colors.contrast.white,
  },
  paginationContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: [theme.spacing[12], theme.spacing[24]],
  },
  iconsContainer: {
    display: 'flex',
    gap: theme.spacing[24],
  },
  iconWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 40,
    height: 40,
    cursor: 'pointer',
  },
  disabledIcon: {
    pointerEvents: 'none',
    opacity: 0.5,
  },
}));
