import { useGetQueryIdPickup } from 'features/pickup/hooks/get-query-id-pickup';
import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
import { getRoute } from 'routes/utils/get-route';
import { Status } from 'types/status';

export const useStorageGuard = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { data, isLoading, isSuccess } = useGetQueryIdPickup();

  if (!id) {
    navigate({
      pathname: ROUTES.HOME,
      search: searchParams.toString(),
    });
    return;
  }

  if (isLoading) {
    return <Skeleton width={'100%'} height={'100%'} />;
  }

  if (isSuccess) {
    const isSuccessStatus = data.status === Status.SUBMITTED;
    if (isSuccessStatus) {
      navigate({
        pathname: getRoute(ROUTES.PICKUP_CREATED_INFO, id),
        search: searchParams.toString(),
      });
      return;
    }

    const isDraftStatus = data.status === Status.DRAFT;
    if (!isDraftStatus) {
      navigate({
        pathname: ROUTES.HOME,
        search: searchParams.toString(),
      });
      return;
    }
  }
};
