import { CheckIcon } from '@stryberventures/gaia-react.icons';
import { CustomButton } from 'components/custom-button/custom-button';
import { Text } from 'components/text/text';
import i18n from 'i18n';
import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ROUTES } from 'routes';

import useStyles from './styles';

export enum SuccessfulRequestType {
  PICKUP = 'pickup',
  DELIVERY = 'delivery',
}

type SuccessfulRequestProps = {
  id: string;
  type: SuccessfulRequestType;
  children?: React.ReactNode;
};

export const SuccessfulRequest: React.FC<SuccessfulRequestProps> = ({ id, children, type }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const classes = useStyles();

  const handleContactSupportClick = () => {
    window.open(
      `mailto:support@get-sorted.co?subject=${i18n.components.successfulRequest.contactSupportEmail[type].subject} #${id}&body=${i18n.components.successfulRequest.contactSupportEmail[type].body} #${id}`,
    );
  };

  return (
    <div className={classes.pickupInfoContainer}>
      <div className={classes.pickupInfo}>
        <CheckIcon className={classes.icon} />
        <Text variant='h1' weight='bold' align='center' className={classes.title}>
          {i18n.components.successfulRequest.title}
        </Text>
        <Text variant='components2' className={classes.subtitle}>
          {i18n.components.successfulRequest.subtitle}
        </Text>
        <div className={classes.overview}>
          <Text variant='body1' weight='bold'>
            #{id} {i18n.components.successfulRequest.overview}
          </Text>
          <div className={classes.content}>{children}</div>
          <CustomButton
            variant='contained'
            onClick={() =>
              navigate({
                pathname: ROUTES.HOME,
                search: searchParams.toString(),
              })
            }
          >
            {i18n.components.successfulRequest.dashboardButton}
          </CustomButton>
          <CustomButton
            variant='outlined'
            className={classes.button}
            onClick={handleContactSupportClick}
          >
            {i18n.components.successfulRequest.contactSupport}
          </CustomButton>
        </div>
      </div>
    </div>
  );
};
