import { DEFAULT_LAT_LNG, getLatLngString } from 'components/google-maps/get-lat-lng';
import React, { createContext, ReactNode, useContext, useMemo, useState } from 'react';

import { LocationMode, LocationState } from './types';

type LocationContextValue = {
  selectedLocationId: number | null;
  setSelectedLocationId: (id: number | null) => void;
  pin: string; // Pin is only for the map
  setPin: (pin: string) => void;
  mode: LocationMode;
  setMode: (mode: LocationMode) => void;
};

const LocationContext = createContext<LocationContextValue | null>(null);

export const useLocationContext = () => {
  const context = useContext(LocationContext);

  if (!context) {
    throw new Error(
      'You tried to use LocationContext outside the scope of the LocationContextProvider.',
    );
  }

  return context;
};

const initialValues = {
  pin: getLatLngString(DEFAULT_LAT_LNG),
  selectedLocationId: null,
  mode: LocationMode.Select,
};

export function LocationContextProvider({ children }: { children: ReactNode }) {
  const [state, setState] = useState<LocationState>(initialValues);

  const setMode = (mode: LocationMode) => {
    setState((prevState) => ({
      ...prevState,
      mode,
      selectedLocationId: null,
    }));
  };

  const setPin = (pin: string) => {
    setState((prevState) => ({
      ...prevState,
      pin,
    }));
  };

  const setSelectedLocationId = (selectedLocationId: number | null) => {
    setState((prevState) => ({
      ...prevState,
      selectedLocationId,
    }));
  };

  const contextValue = useMemo(
    () => ({
      pin: state.pin,
      selectedLocationId: state.selectedLocationId,
      mode: state.mode,
      setMode,
      setPin,
      setSelectedLocationId,
    }),
    [state.mode, state.pin, state.selectedLocationId],
  );

  return <LocationContext.Provider value={contextValue}>{children}</LocationContext.Provider>;
}
