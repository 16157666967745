import Form from '@stryberventures/gaia-react.form';
import { IFormActions, IFormRef } from '@stryberventures/gaia-react.form/types';
import { CheckIcon } from '@stryberventures/gaia-react.icons';
import Input from '@stryberventures/gaia-react.input';
import NumberInput from '@stryberventures/gaia-react.number-input';
import { useTheme } from '@stryberventures/gaia-react.theme';
import LoadingButton from 'components/LoadingButton';
import { useCreatePickupItem } from 'features/pickup/use-cases/create-pickup-item';
import i18n from 'i18n';
import React, { useRef } from 'react';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';

import useStyles from './styles';

type FormData = {
  name: string;
  sku: string;
  quantity: number;
};

const validationSchema = yup.object().shape({
  name: yup
    .string()
    .required(
      i18n.screens.pickupStorage.addItemForm.addNewItemForm.name.errorMessages.nameIsRequired,
    ),
  sku: yup
    .string()
    .required(
      i18n.screens.pickupStorage.addItemForm.addNewItemForm.sku.errorMessages.skuIsRequired,
    ),
  quantity: yup
    .number()
    .min(
      1,
      i18n.screens.pickupStorage.addItemForm.addNewItemForm.quantity.errorMessages.minQuantity,
    )
    .required(
      i18n.screens.pickupStorage.addItemForm.addNewItemForm.quantity.errorMessages
        .quantityIsRequired,
    ),
});

const initialValues: FormData = {
  name: '',
  sku: '',
  quantity: 1,
};

export const AddNewItemForm: React.FC = () => {
  const { id } = useParams();
  const [isDisabled, setIsDisabled] = React.useState<boolean>(true);
  const { theme } = useTheme();
  const formRef: React.Ref<IFormRef> = useRef(null);
  const classes = useStyles();

  const onSuccess = () => {
    formRef.current?.reset();
  };

  const { mutate: createPickupItem, isLoading } = useCreatePickupItem(id, onSuccess);

  const handleSubmit = (data: FormData) => {
    createPickupItem({
      ...data,
      pickupId: Number(id),
    });
  };

  const handleFormChange = (_: FormData, { isValid }: IFormActions) => {
    setIsDisabled(!isValid);
  };

  return (
    <Form
      className={classes.addNewItemForm}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      onChange={handleFormChange}
      ref={formRef}
    >
      <Input
        name='name'
        placeholder={i18n.screens.pickupStorage.addItemForm.addNewItemForm.name.placeholder}
        fullWidth
      />
      <Input
        name='sku'
        min={1}
        placeholder={i18n.screens.pickupStorage.addItemForm.addNewItemForm.sku.placeholder}
        fullWidth
      />
      <NumberInput fullWidth quantityCounter name='quantity' />
      <LoadingButton
        className={classes.addItemButton}
        icon={<CheckIcon fill={theme.colors.neutralGray.medium300} />}
        type='submit'
        size='small'
        disabled={isDisabled}
        loading={isLoading}
      />
    </Form>
  );
};
