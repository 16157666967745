import { createStyles } from '@stryberventures/gaia-react.theme';
import { itemImageVariantToSize } from 'features/warehouse/components/item-image/item-image';

export default createStyles((theme) => ({
  image: {
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    borderRadius: theme.spacing[4],
    cursor: 'pointer',
    overflow: 'hidden',
    '&:hover': {
      '& $zoom': {
        opacity: 1,
      },
    },
  },
  zoom: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, .5)',
    opacity: 0,
    transition: 'opacity .3s ease-in-out',
  },
  smallZoom: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  largeZoom: {
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    paddingBottom: theme.spacing[12],
    paddingRight: theme.spacing[12],
  },
  ...itemImageVariantToSize,
}));
