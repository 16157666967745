import { ButtonVariant, CustomButton } from 'components/custom-button/custom-button';
import React from 'react';

import useStyles from './styles';

export type PredefinedHour = { label: string; hour: number };

type PredefinedHoursProps = {
  predefinedHours: PredefinedHour[];
  onClick: (hour: number) => void;
  selectedHour: number | null;
  isDisabled: boolean;
};

export const PredefinedHours = ({
  isDisabled,
  predefinedHours,
  onClick,
  selectedHour = null,
}: PredefinedHoursProps) => {
  const classes = useStyles();

  const getButtonVariant = (hour: number): ButtonVariant => {
    const isHourSelected = selectedHour && selectedHour === hour;
    return isHourSelected ? ButtonVariant.Contained : ButtonVariant.Outlined;
  };

  return (
    <ul className={classes.predefined}>
      {predefinedHours.map((hour) => (
        <li key={hour.label}>
          <CustomButton
            disabled={isDisabled}
            design={'light'}
            fullWidth
            color='primary'
            size='small'
            type='button'
            variant={getButtonVariant(hour.hour)}
            onClick={() => onClick(hour.hour)}
          >
            {hour.label}
          </CustomButton>
        </li>
      ))}
    </ul>
  );
};
