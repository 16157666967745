type RoutesType = typeof ROUTES;
type RouteKeys = keyof RoutesType;
export type RoutePaths = RoutesType[RouteKeys];
export const ROUTES = {
  HOME: '/',

  LOGIN: '/login',
  SIGN_UP: '/signup',
  SIGN_UP_CONFIRM: '/signup/confirm',
  SIGN_UP_VERIFICATION: '/signup/verification',
  FORGOT_PASSWORD: '/forgot-password',
  FORGOT_PASSWORD_CONFIRM: '/forgot-password/confirm',
  ACCOUNT_NOT_ACTIVATED: '/account-not-activated',

  DELIVERIES: '/deliveries',
  DELIVERY_STORAGE: '/deliveries/:id/storage',
  DELIVERY_CREATED_INFO: '/deliveries/:id/success',
  DELIVERY_LOGISTICS: '/deliveries/:id/logistics',
  DELIVERY_OVERVIEW: '/deliveries/:id/overview',

  PICKUP: '/pickups/:id',
  PICKUP_STORAGE: '/pickups/:id/storage',
  PICKUP_LOGISTICS: '/pickups/:id/logistics',
  PICKUP_CREATED_INFO: '/pickups/:id/success',
  PICKUP_OVERVIEW: '/pickups/:id/overview',

  LOGISTICS: '/logistics',

  INVENTORY: '/inventory',

  NOT_FOUND: '*',
} as const;
