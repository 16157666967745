import { apiClient, ApiClientConfig } from 'api';
import { REST_API_ENDPOINTS } from 'api/constants';

export type CreateDeliveryItemDTO = {
  userId?: string;
  itemId: number;
  quantity: number;
  deliveryId: number;
};

export type UpdateDeliveryItemDTO = {
  userId?: string;
  itemId?: number;
  quantity?: number;
  deliveryId?: number;
};

export type DeleteDeliveryItemDTO = {
  userId?: string;
  deliveryId?: number;
  itemId: number;
};

export type DeleteDeliveryItemResponse = {
  itemId: number;
  deliveryId: number;
  deletedAt: string;
};

export type DownloadDeliveryItemsCsvDTO = {
  userId?: string;
};

export const DeliveryItemsApiService = () => {
  const createDeliveryItem = async (
    payload: CreateDeliveryItemDTO,
    apiConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient({
      ...apiConfig,
      endpoint: REST_API_ENDPOINTS.DELIVERY_ITEMS,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };

  const updateDeliveryItem = async (
    payload: UpdateDeliveryItemDTO,
    apiConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient({
      ...apiConfig,
      endpoint: REST_API_ENDPOINTS.DELIVERY_ITEMS,
      method: 'PATCH',
      data: payload,
    });

    return response.data;
  };

  const deleteDeliveryItem = async (
    payload: DeleteDeliveryItemDTO,
    apiConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<DeleteDeliveryItemResponse>({
      ...apiConfig,
      endpoint: REST_API_ENDPOINTS.DELIVERY_ITEMS,
      method: 'DELETE',
      data: payload,
    });

    return response.data;
  };

  const downloadDeliveryItemsCsv = async (
    id: number,
    payload: DownloadDeliveryItemsCsvDTO,
    apiConfig?: Partial<ApiClientConfig>,
  ) => {
    return await apiClient<string>({
      ...apiConfig,
      endpoint: REST_API_ENDPOINTS.DOWNLOAD_DELIVERY_ITEMS_CSV.replace(':id', id.toString()),
      method: 'GET',
      responseType: 'blob',
      params: {
        userId: payload.userId,
      },
    });
  };

  return {
    createDeliveryItem,
    updateDeliveryItem,
    deleteDeliveryItem,
    downloadDeliveryItemsCsv,
  };
};
