import { TopBar } from 'components/top-bar/top-bar';
import { useUpdateQueryIdPickup } from 'features';
import { AddItemForm } from 'features/pickup/components/add-item-form/add-item-form';
import { PickupItemList } from 'features/pickup/components/pickup-item-list/pickup-item-list';
import { PickupNavigation } from 'features/pickup/components/pickup-navigation';
import { RequestedStorage } from 'features/pickup/components/requested-storage/requested-storage';
import { useGetQueryIdPickup } from 'features/pickup/hooks/get-query-id-pickup';
import { useStorageGuard } from 'features/pickup/hooks/storage-guard';
import i18n from 'i18n';
import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
import { Option } from 'types/option';
import { useGetStorageTypes } from 'utils/hooks/get-storage-types';

import useStyles from './styles';

export type Price = number | null;

export const PickupStorageView = () => {
  const classes = useStyles();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useStorageGuard();

  const handleGoBack = () => {
    navigate({
      pathname: ROUTES.LOGISTICS,
      search: searchParams.toString(),
    });
  };

  const [price, setPrice] = useState<Price>(null);
  const [selectedOption, setSelectedOption] = useState<Option | null>(null);
  const [storageSize, setStorageSize] = useState<number | null>(null);

  const { data: pickupData } = useGetQueryIdPickup();
  const { mutate: updatePickup } = useUpdateQueryIdPickup();

  const { storageTypes, storageUnit } = useGetStorageTypes();

  const itemToPickup = pickupData?.itemToPickup || [];

  const isProceedDisabled = !selectedOption || !storageSize || !itemToPickup.length;

  const handleProceed = () => {
    if (selectedOption && storageSize) {
      updatePickup({
        warehouseTypeId: Number(selectedOption?.value),
        metersRequested: storageSize,
      });
    }
  };

  return (
    <div>
      <TopBar title={i18n.screens.pickupStorage.title} onGoBackButtonClick={handleGoBack} />
      <PickupNavigation
        isProceedDisabled={isProceedDisabled}
        onProceedClick={handleProceed}
        price={price}
      />
      <div className={classes.sections}>
        <RequestedStorage
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          storageSize={storageSize}
          setStorageSize={setStorageSize}
          storageTypes={storageTypes}
          setPrice={setPrice}
          storageUnit={storageUnit}
        />
        <AddItemForm />
        <PickupItemList items={itemToPickup} />
      </div>
    </div>
  );
};
