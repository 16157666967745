import { createStyles } from '@stryberventures/gaia-react.theme';

export default createStyles((theme) => ({
  secondary: {
    color: theme.colors.text.secondary,
  },
  tint: {
    color: theme.colors.text.tint,
  },
}));
