export enum Status {
  DRAFT = 'Draft',
  SUBMITTED = 'Submitted',
  CONFIRMED = 'Confirmed',
  IN_TRANSIT = 'In Transit',
  CANCELLED = 'Cancelled',
  REJECTED = 'Rejected',
  COMPLETED = 'Completed',
  DELIVERED = 'Delivered',
  FAILED = 'Failed',
}
