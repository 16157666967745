import Avatar from '@stryberventures/gaia-react.avatar';
import Divider from '@stryberventures/gaia-react.divider';
import Text from '@stryberventures/gaia-react.text';
import classNames from 'classnames';
import { ContextMenu, ContextMenuOption } from 'components/context-menu/context-menu';
import { UsersSelect } from 'components/users-select/users-select';
import { useUserId } from 'features';
import { usePermissions } from 'features/users/hooks/use-permissions';
import { isPowerUser } from 'features/users/permissions';
import { useUserContext } from 'features/users/user-context';
import i18n from 'i18n';
import React from 'react';

import { GoBackButton } from './go-back-button/go-back-button';
import useStyles from './styles';

type TopBarProps = {
  title: string;
  titleColor?: 'headline' | 'neutralGrey';
  onGoBackButtonClick?: () => void;
  content?: React.ReactNode;
};

export const TopBar: React.FC<TopBarProps> = ({
  title,
  titleColor = 'headline',
  onGoBackButtonClick,
  content,
}) => {
  const classes = useStyles();
  const { user, logout } = useUserContext();
  const userId = useUserId();
  const permissions = usePermissions();
  const isAdmin = user && isPowerUser(user);

  let dynamicTitle = title;

  if (isAdmin && !userId) {
    dynamicTitle = 'Please select a user before you can see any data';
  }

  const contextMenuOptions: ContextMenuOption[] = [
    { label: i18n.components.topBar.avatarOptions.logout, onClick: logout },
  ];

  return (
    <>
      <header className={classes.topBar}>
        <div className={classes.contentWrapper}>
          {onGoBackButtonClick && (
            <GoBackButton onClick={onGoBackButtonClick}>
              {i18n.components.topBar.goBack}
            </GoBackButton>
          )}
          <Text
            className={classNames(classes.pageTitle, classes[titleColor])}
            variant='h4'
            weight='bold'
          >
            {dynamicTitle}
          </Text>
          {content}
        </div>
        {permissions?.users.view && (
          <div className={classes.select}>
            <UsersSelect />
          </div>
        )}
        <ContextMenu options={contextMenuOptions} position='right'>
          <Avatar shape='square' />
        </ContextMenu>
      </header>
      <Divider />
    </>
  );
};
