import { createStyles } from '@stryberventures/gaia-react.theme';

export default createStyles((theme) => ({
  form: {
    display: 'grid',
    gap: theme.spacing[16],
    padding: theme.spacing[16],
    backgroundColor: theme.colors.neutralGray.extraLight50,
  },
  calendar: {
    display: 'grid',
    gridTemplateRows: '48px',
  },
}));
