import { Badge } from 'components';
import { AmbientWarehouseIcon } from 'components/Icons/AmbientWarehouseIcon';
import { ChilledWarehouseIcon } from 'components/Icons/ChilledWarehouseIcon';
import { FrozenWarehouseIcon } from 'components/Icons/FrozenWarehouseIcon';
import { WarehouseTypeLabel } from 'types/warehouse-type-label';

export const getWarehouseTypeBadge = (warehouseTypeName: WarehouseTypeLabel) => {
  switch (warehouseTypeName) {
    case WarehouseTypeLabel.CHILLED:
      return (
        <Badge mode='blue' icon={<ChilledWarehouseIcon />}>
          {warehouseTypeName}
        </Badge>
      );
    case WarehouseTypeLabel.FROZEN:
      return (
        <Badge mode='lightBlue' icon={<FrozenWarehouseIcon />}>
          {warehouseTypeName}
        </Badge>
      );
    case WarehouseTypeLabel.AMBIENT:
      return (
        <Badge mode='primary' icon={<AmbientWarehouseIcon />}>
          {warehouseTypeName}
        </Badge>
      );
    case WarehouseTypeLabel.DRY:
    default:
      return <Badge mode='neutralGray'>{warehouseTypeName}</Badge>;
  }
};
