import { TopBar } from 'components';
import { EditPickupForm } from 'features/pickup/components';
import i18n from 'i18n';
import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ROUTES } from 'routes';

export const PickupOverviewView = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const handleGoBack = () => {
    navigate({
      pathname: ROUTES.LOGISTICS,
      search: searchParams.toString(),
    });
  };

  return (
    <div>
      <TopBar title={i18n.screens.pickupOverview.title} onGoBackButtonClick={handleGoBack} />
      {<EditPickupForm />}
    </div>
  );
};
