import { CustomButton } from 'components';
import { CustomModal } from 'components/modal/modal';
import i18n from 'i18n';
import React from 'react';

import useStyles from './styles';

type DeletePickupModalProps = {
  onOk: () => void;
  onClose: () => void;
};

export const DeleteRequestModal = ({ onOk, onClose }: DeletePickupModalProps) => {
  const classes = useStyles();

  const Confirm = () => {
    onOk();
    onClose();
  };

  return (
    <CustomModal>
      <div className={classes.wrapper}>
        {i18n.screens.logistics.modal.deleteRequest.title}
        <div className={classes.buttons}>
          <CustomButton variant={'outlined'} onClick={onClose}>
            {i18n.no}
          </CustomButton>
          <CustomButton variant={'contained'} onClick={Confirm}>
            {i18n.yes}
          </CustomButton>
        </div>
      </div>
    </CustomModal>
  );
};
