import { createStyles } from '@stryberventures/gaia-react.theme';

export default createStyles((theme) => ({
  pickupInfoContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  pickupInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: theme.spacing[64],
    width: '100%',
  },
  icon: {
    height: 85,
    width: 85,
    '& path': {
      fill: theme.colors.success.dark600,
    },
  },
  title: {
    marginTop: 6,
    marginBottom: 12,
    maxWidth: 440,
  },
  subtitle: {
    color: theme.colors.neutralGray.main500,
  },
  overview: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing[24],
    padding: theme.spacing[24],
    width: '100%',
    maxWidth: 600,
    borderRadius: theme.spacing[8],
    backgroundColor: theme.colors.neutralGray.extraLight50,
    marginTop: 60,
  },
  button: {
    '&[class*="button"]': {
      color: theme.colors.primary.main500,
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing[24],
  },
}));
