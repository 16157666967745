import { AxiosError, isAxiosError } from 'axios';
import i18n from 'i18n';

import { AppBackendError, UserManagementError } from './types';

export { isAxiosError };

//eslint-disable-next-line
export const isServerError = (error: any) => {
  if (!error.response) {
    return false;
  }
  const { status } = error.response;

  return status >= 500;
};

export const isUserMgmtError = (error: AxiosError<UserManagementError>): boolean => {
  const { data } = error.response || {};
  return !!data?.error && typeof data.error === 'string';
};

export const isBackendSingleError = (error: AxiosError<AppBackendError>) => {
  return error?.response?.data?.message;
};

export const isBackendArrayError = (error: AxiosError<AppBackendError>): boolean => {
  const { data } = error.response || {};
  return !!data?.errors && typeof data.errors === 'object';
};

export const isObject = (value: unknown): value is object => {
  return typeof value === 'object' && value !== null && !Array.isArray(value);
};

export const handleError = (error: unknown | Error): string => {
  let uiMessage = '';

  if (isServerError(error)) {
    uiMessage = i18n.errors.systemFailure;
  } else if (isAxiosError(error) && isUserMgmtError(error)) {
    const { data } = error.response || {};
    uiMessage = data.error;
  } else if (isAxiosError(error) && isBackendArrayError(error)) {
    const { data } = error.response || {};
    uiMessage = data.errors.join(', ');
  } else if (isAxiosError(error) && isBackendSingleError(error)) {
    uiMessage = error?.response?.data?.message;
  } else if (
    typeof error === 'object' &&
    error !== null &&
    'message' in error &&
    typeof error.message === 'string'
  ) {
    uiMessage = error.message;
  } else {
    uiMessage = i18n.errors.unexpectedError;
    console.error(error);
  }

  return uiMessage;
};
