import { createStyles } from '@stryberventures/gaia-react.theme';

export default createStyles((theme) => ({
  badgeContainer: {
    display: 'inline-block',
    padding: [theme.spacing[4], theme.spacing[8]],
    borderRadius: 100,
  },
  primary: {
    backgroundColor: theme.colors.primary.extraLight50,
    color: theme.colors.primary.main500,
  },
  neutralGray: {
    backgroundColor: theme.colors.neutralGray.light100,
    color: theme.colors.neutralGray.dark600,
  },
  success: {
    background: theme.colors.success.extraLight50,
    color: theme.colors.success.dark600,
  },
  badgeWrapper: {
    display: 'flex',
    gap: theme.spacing[4],
  },
  blue: {
    ...theme.colors.badge.chilled,
  },
  lightBlue: {
    ...theme.colors.badge.frozen,
  },
}));
