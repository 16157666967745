import { SVGProps } from 'react';

export const SortedIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='22'
      height='22'
      viewBox='0 0 22 22'
      fill='none'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.89595 7.03964V4.41293L16.6651 0.18457L20.4323 4.41293V7.03964H13.281L13.2085 6.7708L12.1992 7.03964H6.89595ZM12.1987 7.03977L6.89595 7.03977V8.45224L12.1987 7.03977ZM6.89589 10.0455V14.7833H4.25354L0 5.07193L4.25354 1.32703L6.89589 1.32703L6.89589 8.45225L6.49521 8.55898L6.89589 10.0455ZM6.89595 10.0457L8.24542 15.0522L14.9588 13.264L13.281 7.03977H14.558L14.5581 7.03977H17.2004L21.4539 16.7511L17.2003 20.496H14.558V17.4101L4.78886 21.6385L1.02166 17.4101L1.02166 14.7834H6.89595V10.0457Z'
        fill='#DF5C24'
      />
    </svg>
  );
};
