import { TopBar } from 'components';
import { Location } from 'components/location/location';
import { useLocationContext } from 'components/location/location-context';
import { LocationMode } from 'components/location/types';
import { TransportDate } from 'components/transport-date/transport-date';
import { TruckTypeForm } from 'components/truck-type-form/truck-type-form';
import { UpdateDeliveryStatusDTO } from 'features/delivery//api/delivery-api.service';
import { DeliveryNavigation } from 'features/delivery/components/delivery-navigation';
import { useGetQueryIdDelivery } from 'features/delivery/hooks';
import { useDeliveryLogisticsGuard } from 'features/delivery/hooks/delivery-logistics-guard';
import { useGetQueryIdWarehouse } from 'features/delivery/hooks/get-query-id-warehouse';
import { useUpdateQueryIdDelivery } from 'features/delivery/hooks/update-query-id-delivery';
import { useUpdateDeliveryStatus } from 'features/delivery/use-cases/update-delivery-status';
import i18n from 'i18n';
import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ROUTES } from 'routes';
import { getRoute } from 'routes/utils/get-route';
import { Status } from 'types/status';

import useStyles from './styles';

export const DeliveryLogisticsView = () => {
  const [price, setPrice] = useState<number | null>(null);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const classes = useStyles();
  const { setMode, setSelectedLocationId } = useLocationContext();
  const { mutate: updateDelivery, isLoading: isUpdatingDelivery } = useUpdateQueryIdDelivery();
  const { mutate: updateDeliveryStatus } = useUpdateDeliveryStatus();
  const { data: deliveryData, isSuccess, isLoading } = useGetQueryIdDelivery();
  const { data: warehouseData, isSuccess: isGetWarehouseSuccess } = useGetQueryIdWarehouse();

  useDeliveryLogisticsGuard();
  if (!isSuccess) return;

  const isProceedDisabled =
    !deliveryData.locationId || !deliveryData.deliveryDate || !deliveryData.truckTypeId;

  const navigateToStorage = () => {
    navigate({
      pathname: getRoute(ROUTES.DELIVERY_STORAGE, deliveryData.id),
      search: searchParams.toString(),
    });
  };

  const handleTruckTypeChange = (truckTypeId: number) => {
    updateDelivery({ truckTypeId: truckTypeId });
  };

  const handleCommentsBlur = (comments: string) => {
    updateDelivery({ comments });
  };

  const handleDeliveryDateUpdate = (newDate: string) => {
    updateDelivery({ deliveryDate: newDate });
  };

  const handleLocationUpdate = (locationId: number) => {
    updateDelivery({ locationId });
  };

  const handleCreateLocationSuccess = (locationId: number) => {
    updateDelivery(
      { locationId },
      {
        onSuccess: () => {
          setSelectedLocationId(locationId);
          setMode(LocationMode.Select);
        },
      },
    );
  };

  const handleProceed = () => {
    if (deliveryData.id) {
      const payload: UpdateDeliveryStatusDTO = {
        id: String(deliveryData.id),
        status: Status.SUBMITTED,
      };
      updateDeliveryStatus(payload);
    }
  };

  return (
    <>
      <TopBar
        title={i18n.screens.deliveryLogistics.title}
        onGoBackButtonClick={navigateToStorage}
      />
      <DeliveryNavigation
        isProceedDisabled={isProceedDisabled}
        onProceedClick={handleProceed}
        price={price}
      />
      <div className={classes.sections}>
        <TruckTypeForm
          truckTypeId={deliveryData.truckTypeId}
          comments={deliveryData.comments}
          isLoading={isUpdatingDelivery}
          onPriceChange={setPrice}
          onTruckTypeChange={handleTruckTypeChange}
          onCommentsBlur={handleCommentsBlur}
        />
        <TransportDate
          title={i18n.screens.deliveryLogistics.transportTitle}
          startTime={warehouseData?.deliveryStartTime}
          endTime={warehouseData?.deliveryEndTime}
          initialTime={deliveryData?.deliveryDate}
          isLoading={!isGetWarehouseSuccess || isLoading}
          isUpdating={isUpdatingDelivery}
          onUpdate={handleDeliveryDateUpdate}
        />
        <Location
          locationId={deliveryData.locationId}
          title={i18n.screens.deliveryLogistics.locationTitle}
          onUpdate={handleLocationUpdate}
          onCreateLocation={handleCreateLocationSuccess}
          isUpdating={isUpdatingDelivery}
          isLoading={isLoading}
        />
      </div>
    </>
  );
};
