export const QUERY_KEYS = {
  users: 'users',
  prices: 'prices',
  deliveries: 'deliveries',
  pickups: 'pickups',
  pickupItems: 'pickup-items',
  locations: 'locations',
  warehouse: 'warehouse',
  warehouseType: 'warehouseType',
  warehouseItems: 'warehouseItems',
  truckType: 'truckType',
  statisticsInProgress: 'statisticsInProgress',
  statisticsOverview: 'statisticsOverview',
} as const;
