import Input from '@stryberventures/gaia-react.input';
import { CustomButton, Divider, FormControl, Select } from 'components';
import { Calendar } from 'components/calendar/calendar';
import {
  UpdateDeliveryDTO,
  UpdateDeliveryStatusDTO,
} from 'features/delivery/api/delivery-api.service';
import { useGetQueryIdDelivery } from 'features/delivery/hooks';
import { useUpdateQueryIdDelivery } from 'features/delivery/hooks/update-query-id-delivery';
import { useUpdateDeliveryStatus } from 'features/delivery/use-cases/update-delivery-status';
import { useGetTruckTypes } from 'features/truck/use-cases';
import { useUserId } from 'features/users';
import i18n from 'i18n';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Skeleton from 'react-loading-skeleton';
import { Status } from 'types/status';
import {
  DateService,
  DEFAULT_TIMEZONE,
  deliveryStatusOptions,
  formatDate,
  formatTime,
  getStorageTypeOptions,
  getTruckTypesOptions,
  mergeDateAndTimeToDateObject,
  utcDateToDbString,
} from 'utils';
import { useGetStorageTypes } from 'utils/hooks/get-storage-types';

import useStyles from './styles';

const { UTCtoLocalTimezone, localTimezoneToUTC } = DateService(DEFAULT_TIMEZONE);

enum EditDeliveryFormField {
  Status = 'status',
  StatusComment = 'comment',
  StorageType = 'storageType',
  TruckTypeId = 'truckTypeId',
  DeliveryTime = 'deliveryTime',
  DeliveryDate = 'deliveryDate',
  Comments = 'comments',
}

export type EditDeliveryFormValues = {
  [EditDeliveryFormField.Status]: { value: Status; label: Status };
  [EditDeliveryFormField.StatusComment]: string;
  [EditDeliveryFormField.StorageType]: { value: number; label: string };
  [EditDeliveryFormField.TruckTypeId]: { value: number; label: string };
  [EditDeliveryFormField.DeliveryTime]: Date;
  [EditDeliveryFormField.DeliveryDate]: Date;
  [EditDeliveryFormField.Comments]: string;
};

export const EditDeliveryForm = () => {
  const classes = useStyles();
  const { data, isLoading, isSuccess: isGetDeliverySuccess } = useGetQueryIdDelivery();
  const [defaultValuesSet, setDefaultValuesSet] = useState(false);

  const {
    control,
    handleSubmit,
    reset,
    formState: { dirtyFields },
  } = useForm<EditDeliveryFormValues>({
    defaultValues: {
      [EditDeliveryFormField.Status]: { value: data?.status, label: data?.status },
      [EditDeliveryFormField.StorageType]: {
        value: data?.warehouseTypeId,
        label: data?.warehouseTypeId?.toString(),
      },
      [EditDeliveryFormField.TruckTypeId]: {
        value: data?.truckTypeId,
        label: data?.truckTypeId?.toString(),
      },
      [EditDeliveryFormField.DeliveryTime]: data?.deliveryDate
        ? UTCtoLocalTimezone(data.deliveryDate)
        : undefined,
      [EditDeliveryFormField.DeliveryDate]: data?.deliveryDate
        ? UTCtoLocalTimezone(data.deliveryDate)
        : undefined,
      [EditDeliveryFormField.Comments]: data?.comments,
    },
  });
  const userId = useUserId();
  const { storageTypes, storageUnit } = useGetStorageTypes();
  const storageTypeOptions = getStorageTypeOptions(storageTypes, storageUnit);
  const { data: truckTypes, isSuccess: isGetTruckTypesSuccess } = useGetTruckTypes();
  const { mutate: updateStatus, isLoading: isUpdatingStatus } = useUpdateDeliveryStatus({
    preventNavigate: true,
  });
  const truckTypesOptions = getTruckTypesOptions(truckTypes?.data);

  const { mutate: updateDelivery, isLoading: isUpdatingDelivery } = useUpdateQueryIdDelivery();

  const isUpdating = isUpdatingStatus || isUpdatingDelivery;
  const isFormStatusPartDirty = dirtyFields[EditDeliveryFormField.Status];
  const isFormDeliveryPartDirty =
    dirtyFields[EditDeliveryFormField.TruckTypeId] ||
    dirtyFields[EditDeliveryFormField.Comments] ||
    dirtyFields[EditDeliveryFormField.DeliveryDate] ||
    dirtyFields[EditDeliveryFormField.DeliveryTime];

  const onSubmit = async (values: EditDeliveryFormValues) => {
    if (isFormDeliveryPartDirty) {
      const updateDeliveryPayload: UpdateDeliveryDTO = {
        userId,
        warehouseTypeId: values[EditDeliveryFormField.StorageType]?.value,
        truckTypeId: values[EditDeliveryFormField.TruckTypeId]?.value,
        comments: values[EditDeliveryFormField.Comments],
      };

      if (
        values[EditDeliveryFormField.DeliveryDate] &&
        values[EditDeliveryFormField.DeliveryTime]
      ) {
        updateDeliveryPayload.deliveryDate = utcDateToDbString(
          localTimezoneToUTC(
            mergeDateAndTimeToDateObject(
              values[EditDeliveryFormField.DeliveryDate],
              values[EditDeliveryFormField.DeliveryTime],
            ).toISOString(),
          ),
        );
      }
      updateDelivery(updateDeliveryPayload);
    }

    if (data?.id) {
      if (isFormStatusPartDirty && data?.status !== values[EditDeliveryFormField.Status]?.value) {
        const updateStatusPayload: UpdateDeliveryStatusDTO = {
          id: data.id.toString(),
          userId,
          status: values[EditDeliveryFormField.Status]?.value,
        };
        if (values[EditDeliveryFormField.StatusComment]) {
          updateStatusPayload.comment = values[EditDeliveryFormField.StatusComment];
        }
        updateStatus(updateStatusPayload);
      }
    }
  };

  useEffect(() => {
    if (isGetDeliverySuccess && isGetTruckTypesSuccess && !defaultValuesSet) {
      const status = deliveryStatusOptions.find((status) => status.value === data.status);
      const initialTruckType = truckTypesOptions.find((truck) => truck.value === data?.truckTypeId);

      const storageType = storageTypeOptions.find(
        (storage) => storage.value === data.warehouseTypeId,
      );
      reset({
        [EditDeliveryFormField.Status]: status,
        [EditDeliveryFormField.StorageType]: storageType,
        [EditDeliveryFormField.TruckTypeId]: initialTruckType,
        [EditDeliveryFormField.DeliveryTime]: data.deliveryDate
          ? UTCtoLocalTimezone(data.deliveryDate)
          : undefined,
        [EditDeliveryFormField.DeliveryDate]: data.deliveryDate
          ? UTCtoLocalTimezone(data.deliveryDate)
          : undefined,
        [EditDeliveryFormField.Comments]: data.comments,
      });
      setDefaultValuesSet(true);
    }
  }, [
    data?.comments,
    data?.deliveryDate,
    data?.status,
    data?.truckTypeId,
    data?.warehouseTypeId,
    defaultValuesSet,
    isGetDeliverySuccess,
    isGetTruckTypesSuccess,
    reset,
    storageTypeOptions,
    truckTypesOptions,
  ]);

  if (isLoading) {
    return <Skeleton width='100%' height='100%' />;
  }

  return (
    <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
      <FormControl title={i18n.screens.deliveryOverview.formFields.status.label}>
        <Controller
          name={EditDeliveryFormField.Status}
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              isDisabled={isUpdating}
              options={deliveryStatusOptions}
              placeholder={i18n.screens.deliveryOverview.formFields.status.placeholder}
            />
          )}
        />
      </FormControl>
      <FormControl title={i18n.screens.deliveryOverview.formFields.statusComment.label}>
        <Controller
          name={EditDeliveryFormField.StatusComment}
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              placeholder={i18n.screens.deliveryOverview.formFields.statusComment.placeholder}
              fullWidth
            />
          )}
        />
      </FormControl>
      <FormControl title={i18n.screens.deliveryOverview.formFields.storageType.label}>
        <Controller
          name={EditDeliveryFormField.StorageType}
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              isDisabled
              placeholder={i18n.screens.deliveryOverview.formFields.storageType.placeholder}
            />
          )}
        />
      </FormControl>
      <Divider text={i18n.screens.deliveryOverview.divider.transport} />
      <FormControl title={i18n.screens.deliveryOverview.formFields.pickupDate.label}>
        <Controller
          name={EditDeliveryFormField.DeliveryDate}
          control={control}
          render={({ field }) => (
            <div className={classes.calendar}>
              <Calendar
                {...field}
                disabled={isUpdating}
                value={field.value && formatDate({ date: field.value, dateFormat: 'date' })}
                date={field.value}
                placeholderText={i18n.screens.deliveryOverview.formFields.pickupDate.placeholder}
              />
            </div>
          )}
        />
      </FormControl>
      <FormControl title={i18n.screens.deliveryOverview.formFields.pickupTime.label}>
        <Controller
          name={EditDeliveryFormField.DeliveryTime}
          control={control}
          render={({ field }) => (
            <div className={classes.calendar}>
              <Calendar
                {...field}
                disabled={isUpdating}
                value={field.value && formatTime({ time: field.value, timeFormat: 'time' })}
                showTimeSelect={true}
                showTimeSelectOnly={true}
                timeIntervals={60}
                dateFormat='h:mm aa'
                date={field.value}
                placeholderText={i18n.screens.deliveryOverview.formFields.pickupTime.placeholder}
              />
            </div>
          )}
        />
      </FormControl>
      <FormControl title={i18n.screens.deliveryOverview.formFields.truckType.label}>
        <Controller
          name={EditDeliveryFormField.TruckTypeId}
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              isDisabled={isUpdating}
              options={truckTypesOptions}
              placeholder={i18n.screens.deliveryOverview.formFields.truckType.placeholder}
            />
          )}
        />
      </FormControl>
      <FormControl title={i18n.screens.deliveryOverview.formFields.comment.label}>
        <Controller
          name={EditDeliveryFormField.Comments}
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              disabled={isUpdating}
              onChange={(value) => field.onChange(value)}
              fullWidth
              controlled
              value={field.value}
              placeholder={i18n.screens.deliveryOverview.formFields.comment.placeholder}
            />
          )}
        />
      </FormControl>

      <CustomButton disabled={isUpdating} variant={'contained'}>
        {i18n.screens.deliveryOverview.submit}
      </CustomButton>
    </form>
  );
};
