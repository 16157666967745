import Text from '@stryberventures/gaia-react.text';
import { Price } from 'features/pickup/routes/pickup-storage';
import i18n from 'i18n';
import React from 'react';
import { PriceService } from 'utils';

import useStyles from './styles';

type StoragePriceProps = {
  price?: Price;
  title?: string;
};

const { getPrice } = PriceService();

export const StoragePrice: React.FC<StoragePriceProps> = ({ price, title }) => {
  const classes = useStyles();

  if (!price) return null;

  return (
    <div className={classes.storagePrice}>
      <Text variant='components2' className={classes.priceTitle}>
        {title}
      </Text>
      <span className={classes.price}>
        <Text variant='body3' weight='bold' component='span'>
          {getPrice(price)}
        </Text>{' '}
        <Text variant='caption1' weight='medium'>
          {i18n.currency.sar}/{i18n.monthAbbreviation}
        </Text>
      </span>
    </div>
  );
};
