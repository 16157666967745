import { CloseIcon, LoadIcon } from '@stryberventures/gaia-react.icons';
import { CustomButton, Text } from 'components';
import { CustomModal } from 'components/modal/modal';
import { ImageUploader } from 'features/warehouse/components/image-uploader';
import i18n from 'i18n';
import React from 'react';

import useStyles from './styles';

type ImageModalProps = {
  previewImageUrl: string;
  imageUrl: string;
  onClose: () => void;
  itemName: string;
  itemId: number;
};

export const ImageModal: React.FC<ImageModalProps> = ({
  itemName,
  onClose,
  imageUrl,
  itemId,
  previewImageUrl,
}) => {
  const imageUploaderRef = React.useRef<HTMLInputElement>(null);
  const classes = useStyles();

  const handleReplaceImage = () => {
    if (imageUploaderRef.current) {
      imageUploaderRef.current.click();
    }
  };

  const handleViewFullImage = () => {
    window.open(imageUrl, '_blank');
  };

  return (
    <CustomModal>
      <div className={classes.container}>
        <div className={classes.titleWrapper}>
          <Text variant='body1' weight='semiBold'>
            {itemName}
          </Text>
          <div className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </div>
        </div>
        <a href={imageUrl} target='_blank' rel='noreferrer'>
          <div className={classes.image} style={{ backgroundImage: `url('${previewImageUrl}')` }} />
        </a>
        <div className={classes.actionButtonWrapper}>
          <ImageUploader itemId={itemId} ref={imageUploaderRef}>
            <div>
              <CustomButton
                onClick={handleReplaceImage}
                iconLeft={<LoadIcon variant='upload' />}
                variant='outlined'
              >
                {i18n.screens.inventory.imageModal.replaceImage}
              </CustomButton>
            </div>
          </ImageUploader>
          <CustomButton onClick={handleViewFullImage} variant='outlined'>
            {i18n.screens.inventory.imageModal.viewFullImage}
          </CustomButton>
        </div>
      </div>
    </CustomModal>
  );
};
