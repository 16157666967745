import { UploadItemImageParams } from 'features/multimedia/api/multimedia-api.service';
import { useUploadItemImage } from 'features/multimedia/use-cases/upload-item-image';
import React, { forwardRef } from 'react';
import Resizer from 'react-image-file-resizer';
import { getTypedFormData } from 'types/typed-form-data';

type ImageUploaderProps = {
  itemId: number;
  children: React.ReactNode;
};

const resizeFile = (file: File): Promise<File> =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      520,
      520,
      'JPEG',
      100,
      0,
      (uri) => {
        resolve(uri as File);
      },
      'file',
    );
  });

export const ImageUploader = forwardRef<HTMLInputElement, ImageUploaderProps>(
  ({ itemId, children }, ref) => {
    const { mutate: uploadImage } = useUploadItemImage();
    const handleFileChange: React.ChangeEventHandler<HTMLInputElement> = async (e) => {
      if (!e.target.files) return;
      const file = e.target.files[0];
      if (!file) return;
      try {
        const formData = getTypedFormData<UploadItemImageParams>();
        const resizedFile = await resizeFile(file);
        formData.append('file', file);
        formData.append('fileMinified', resizedFile);
        formData.append('itemId', itemId.toString());
        uploadImage(formData);
      } catch (error) {
        //TODO: think about how to handle errors for file resizing
        console.error(error);
      }
    };

    return (
      <label htmlFor={`imgUploader-${itemId}`}>
        <input
          ref={ref}
          type='file'
          id={`imgUploader-${itemId}`}
          accept='image/*'
          onChange={handleFileChange}
          style={{ display: 'none', cursor: 'pointer' }}
        />
        {children}
      </label>
    );
  },
);

ImageUploader.displayName = 'ImageUploader';
