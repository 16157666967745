import Form from '@stryberventures/gaia-react.form';
import Input from '@stryberventures/gaia-react.input';
import { CustomButton } from 'components/custom-button/custom-button';
import { getLatLng, getLatLngString } from 'components/google-maps/get-lat-lng';
import { useLocationContext } from 'components/location/location-context';
import { LatLngLiteral } from 'components/location/types';
import { CreateLocationDTO } from 'features/location/api/location-api.service';
import { useCreateLocation } from 'features/location/use-cases/create-location';
import i18n from 'i18n';
import React, { useState } from 'react';
import * as yup from 'yup';

import useStyles from './styles';
import { CreateLocationFormField, CreateLocationFormValues } from './types';

const validationSchema = yup.object().shape({
  address: yup.string().required(i18n.screens.pickupLogistics.errorMessages.addressIsRequired),
});

const latLngValidationSchema = yup
  .string()
  .required(i18n.screens.pickupLogistics.errorMessages.latLngIsRequired)
  .trim()
  .matches(
    /^(-?\d+(\.\d+)?),\s*(-?\d+(\.\d+)?)$/,
    i18n.screens.pickupLogistics.errorMessages.latLngIsWrong,
  );

const preparePayload = (values: { address: string }, pin: LatLngLiteral): CreateLocationDTO => {
  return {
    address: values.address,
    name: values.address,
    latitude: pin.lat,
    longitude: pin.lng,
  };
};

interface CreateLocationFormProps {
  onSuccess: (locationId: number) => void;
}

export const CreateLocationForm = ({ onSuccess }: CreateLocationFormProps) => {
  const [latLngError, setLatLngError] = useState('');
  const classes = useStyles();
  const { pin, setPin } = useLocationContext();
  const [disabled, setDisabled] = React.useState(true);
  const { mutate: createLocation, isLoading } = useCreateLocation();

  // Controlled field is not present onSubmit
  const handleSubmit = async (
    values: Omit<CreateLocationFormValues, CreateLocationFormField.LatLng>,
  ) => {
    const payload = preparePayload(values, getLatLng(pin));
    createLocation(payload, {
      onSuccess: ({ id }) => onSuccess(id),
    });
  };

  const updateMapPin = (event: React.BaseSyntheticEvent) => {
    setPin(getLatLngString(event.target.value));
  };

  const validateLatLng = async (e: React.BaseSyntheticEvent) => {
    try {
      await latLngValidationSchema.validate(e.target.value);
      updateMapPin(e);
      return '';
    } catch (error: unknown) {
      if (
        typeof error === 'object' &&
        error !== null &&
        'message' in error &&
        typeof error.message === 'string'
      ) {
        return error.message;
      }
      return '';
    }
  };

  return (
    <Form
      className={classes.form}
      validationSchema={validationSchema}
      onChange={(_, { isValid }) => {
        setDisabled(!isValid);
      }}
      onSubmit={handleSubmit}
    >
      <Input
        disabled={isLoading}
        fullWidth
        name={CreateLocationFormField.Address}
        label={i18n.components.location.formFields.locationName.label}
        placeholder={i18n.components.location.formFields.locationName.placeholder}
      />
      <Input
        disabled={isLoading}
        controlled
        fullWidth
        name={CreateLocationFormField.LatLng}
        value={getLatLngString(pin)}
        label={i18n.components.location.formFields.latLong.label}
        placeholder={i18n.components.location.formFields.latLong.placeholder}
        onChange={(e) => {
          validateLatLng(e).then((error) => setLatLngError(error));
          return setPin(getLatLngString(e.target.value));
        }}
        errorMessage={latLngError}
      />
      <CustomButton type='submit' disabled={disabled || isLoading} className={classes.submit}>
        {i18n.components.location.formFields.submit}
      </CustomButton>
    </Form>
  );
};
