import { createStyles } from '@stryberventures/gaia-react.theme';

export default createStyles((theme) => ({
  titleWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing[12],
    gap: theme.spacing[2],
  },
  title: {
    color: theme.colors.text.secondary,
  },
  subtitle: {
    color: theme.colors.neutralGray.medium400,
  },
  hint: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing[2],
    color: theme.colors.neutralGray.medium400,
    marginTop: theme.spacing[8],
  },
  tooltip: {
    '& [class*="tooltipTarget"]': {
      padding: 0,
    },
    '& [class*="bottomStart"]': {
      left: -25,
      top: 'calc(100% - 5px)',
    },
  },
}));
