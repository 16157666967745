import { eachHourOfInterval, getHours, isValid, parse } from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { DATE_FORMATS } from 'types/date';

export const DEFAULT_TIMEZONE = 'Asia/Riyadh';

export const startOfDayUTC = () => {
  const startOfDay = new Date();
  startOfDay.setUTCHours(0, 0, 0, 0);
  return startOfDay;
};

export const DateService = (timezone: string) => {
  const UTCtoLocalTimezone = (date: string) => {
    return utcToZonedTime(date, timezone);
  };

  const localTimezoneToUTC = (date: string) => {
    return zonedTimeToUtc(date, timezone);
  };

  const parseHourMinutesSecondsToCurrentDay = (hour: string) => {
    return parse(hour, DATE_FORMATS.dbHourDate, new Date());
  };

  const getHoursRange = (startDateUTC = '', endDateUTC = '') => {
    // INPUT: '09:00:00', '14:00:00'
    // OUTPUT: [9,10,11,12,13,14]

    const start = parseHourMinutesSecondsToCurrentDay(startDateUTC);
    const end = parseHourMinutesSecondsToCurrentDay(endDateUTC);

    if (!isValid(start) || !isValid(end)) {
      return [];
    }

    const hoursInterval = eachHourOfInterval({
      start,
      end,
    });
    return hoursInterval.map((date) => getHours(date));
  };

  return {
    startOfDayUTC,
    UTCtoLocalTimezone,
    localTimezoneToUTC,
    getHoursRange,
  };
};
