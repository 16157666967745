import { apiClient, ApiClientConfig, PaginatedRequestParams, PaginatedResponse } from 'api';
import { REST_API_ENDPOINTS } from 'api/constants';
import { Location } from 'types/location';

export type CreateLocationDTO = {
  userId?: string;
  name: string;
  address: string;
  latitude?: number;
  longitude?: number;
};

export type UpdateLocationDTO = {
  id: string;
  userId?: string;
  name?: string;
  address?: string;
  latitude?: number;
  longitude?: number;
};

export type GetLocationsRequestParams = {
  userId?: string;
} & PaginatedRequestParams;

export type LocationsResponse = PaginatedResponse<Location>;

export const LocationApiService = () => {
  const createLocation = async (
    payload: CreateLocationDTO,
    apiConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<Location>({
      ...apiConfig,
      endpoint: REST_API_ENDPOINTS.LOCATION,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };

  const getLocation = async (id: string, apiConfig?: Partial<ApiClientConfig>) => {
    const response = await apiClient<Location>({
      ...apiConfig,
      endpoint: `${REST_API_ENDPOINTS.LOCATION}/${id}`,
    });

    return response.data;
  };

  const getLocations = async (
    params: GetLocationsRequestParams,
    apiConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<LocationsResponse>({
      ...apiConfig,
      endpoint: REST_API_ENDPOINTS.LOCATION,
      params,
    });

    return response.data;
  };

  const updateLocation = async (
    payload: UpdateLocationDTO,
    apiConfig?: Partial<ApiClientConfig>,
  ) => {
    const { id, ...data } = payload;
    const response = await apiClient<Location>({
      ...apiConfig,
      endpoint: `${REST_API_ENDPOINTS.LOCATION}/${id}`,
      method: 'PATCH',
      data,
    });

    return response.data;
  };

  const deleteLocation = async (id: string, apiConfig?: Partial<ApiClientConfig>) => {
    const response = await apiClient({
      ...apiConfig,
      endpoint: `${REST_API_ENDPOINTS.LOCATION}/${id}`,
      method: 'DELETE',
    });

    return response.data;
  };

  return {
    createLocation,
    getLocation,
    getLocations,
    updateLocation,
    deleteLocation,
  };
};
