import { Text, TopBar } from 'components';
import { InventoryContainer } from 'features/warehouse/components/inventory-container/inventory-container';
import { Search } from 'features/warehouse/components/search/search';
import {
  InventoryViewType,
  ViewSwitcher,
} from 'features/warehouse/components/view-switcher/view-switcher';
import i18n from 'i18n';
import debounce from 'lodash/debounce';
import React, { useState } from 'react';

import useStyles from './styles';

export const InventoryView = () => {
  const [view, setView] = React.useState<InventoryViewType>(InventoryViewType.table);
  const [query, setQuery] = useState('');
  const classes = useStyles();

  const handleSearchValueChange = debounce((value: string) => {
    setQuery(value);
  }, 300);

  return (
    <>
      <TopBar titleColor='neutralGrey' title={i18n.screens.inventory.headerTitle} />
      <div className={classes.wrapper}>
        <div className={classes.titleWrapper}>
          <Text variant='body1' weight='bold'>
            {i18n.screens.inventory.title}
          </Text>
          <div className={classes.topBarActionsWrapper}>
            <Search onValueChange={handleSearchValueChange} value={query} />
            <ViewSwitcher view={view} setView={setView} />
          </div>
        </div>
        <InventoryContainer view={view} query={query} />
      </div>
    </>
  );
};
