import { useMutation } from '@tanstack/react-query';
import { CreateLocationDTO, LocationApiService } from 'features/location/api/location-api.service';
import { useUserId } from 'features/users';
import { queryClient } from 'index';
import { enqueueSnackbar } from 'notistack';

import { locationQueryKeys } from './query-keys';

const { createLocation } = LocationApiService();

export const useCreateLocation = () => {
  const userId = useUserId();

  return useMutation(
    (payload: CreateLocationDTO) => {
      return createLocation({ userId, ...payload });
    },
    {
      onError: () => {
        enqueueSnackbar('Location could not be created', { variant: 'error' });
      },
      onSuccess: () => {
        Promise.all([
          queryClient.invalidateQueries({
            queryKey: locationQueryKeys.lists(),
          }),
        ]);
        enqueueSnackbar('Location created successfully', { variant: 'success' });
      },
    },
  );
};
