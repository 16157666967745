import { QUERY_KEYS } from 'features/query-keys';

/**
 * @see https://tkdodo.eu/blog/effective-react-query-keys#use-query-key-factories
 */
export const userQueryKeys = {
  all: [QUERY_KEYS.users] as const,
  lists: () => [...userQueryKeys.all, 'list'] as const,
  list: (filters: object) => [...userQueryKeys.lists(), { filters }] as const,
  details: () => [...userQueryKeys.all, 'detail'] as const,
  detail: (id: string) => [...userQueryKeys.details(), id] as const,
};

export const pricesQueryKeys = {
  all: [QUERY_KEYS.prices] as const,
  lists: () => [...userQueryKeys.all, 'list'] as const,
  list: (filters: object) => [...userQueryKeys.lists(), { filters }] as const,
  details: () => [...userQueryKeys.all, 'detail'] as const,
  detail: (params: object) => [...userQueryKeys.details(), params] as const,
};
