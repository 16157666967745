import { QueryFunction, QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query';
import {
  getStatisticsInProgressParams,
  StatisticsApiService,
} from 'features/dashboard/api/statistics-api.service';
import { useReactQueryPowerUserUserIdManager } from 'features/users';
import { StatisticsInProgress } from 'types/statistics';

import { statisticsInProgressQueryKeys } from './query-keys';

const { getStatisticsInProgress } = StatisticsApiService();

const getStatisticsInProgressQuery = (
  params: getStatisticsInProgressParams,
  options: UseQueryOptions<StatisticsInProgress>,
): {
  queryKey: QueryKey;
  queryFn: QueryFunction<StatisticsInProgress>;
  options?: UseQueryOptions<StatisticsInProgress>;
} => ({
  queryKey: statisticsInProgressQueryKeys.list(params),
  queryFn: ({ signal }) => getStatisticsInProgress(params, { config: { signal } }),
  ...options,
});

export const useStatisticsInProgress = (
  params: getStatisticsInProgressParams,
  options?: UseQueryOptions<StatisticsInProgress>,
) => {
  const { updatedParams, enabled } = useReactQueryPowerUserUserIdManager(params, options);

  return useQuery<StatisticsInProgress, Error>(
    getStatisticsInProgressQuery(updatedParams, {
      ...options,
      enabled,
    }),
  );
};
