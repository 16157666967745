import { addMonths, eachWeekendOfInterval, subDays } from 'date-fns';
import { startOfDayUTC } from 'utils';

export const getSaudiWeekendsInterval = () => {
  const maxDate = addMonths(startOfDayUTC(), 6);

  return eachWeekendOfInterval({
    start: startOfDayUTC(),
    end: maxDate,
  }).map((day) => subDays(day, 1));
};
