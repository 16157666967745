import Button, { IButton } from '@stryberventures/gaia-react.button';
import classNames from 'classnames';
import React from 'react';

import useStyles from './styles';

export type CustomButtonProps = {
  design?: 'standard' | 'light';
  iconColor?: 'primary' | 'grey';
} & IButton;

export enum ButtonVariant {
  Contained = 'contained',
  Outlined = 'outlined',
  Ghost = 'ghost',
}

export const CustomButton: React.FC<CustomButtonProps> = ({
  design = 'standard',
  iconColor = 'primary',
  className,
  variant,
  ...rest
}) => {
  const classes = useStyles();
  const isOutlined = variant === ButtonVariant.Outlined;
  const isContained = variant === ButtonVariant.Contained;

  let styles = '';

  if (design === 'light') {
    styles = classNames(
      classes.buttonContainerLight,
      { [classes.outlinedLight]: isOutlined },
      { [classes.containedLight]: isContained },
      classes[iconColor],
      className,
    );
  }
  if (design === 'standard') {
    styles = classNames(
      classes.buttonContainer,
      { [classes.outlined]: isOutlined },
      classes[iconColor],
      className,
    );
  }

  return <Button variant={variant} className={styles} {...rest} />;
};
