import { useMutation } from '@tanstack/react-query';
import { handleError } from 'api/error-handling';
import { PickupApiService, UpdatePickupStatusDTO } from 'features/pickup/api/pickup-api.service';
import { useUserId } from 'features/users';
import { enqueueSnackbar } from 'notistack';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
import { getRoute } from 'routes/utils/get-route';

const { updatePickupStatus } = PickupApiService();

interface UseUpdatePickupStatusProps {
  preventNavigate?: boolean;
}

export const useUpdatePickupStatus = ({ preventNavigate }: UseUpdatePickupStatusProps = {}) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const userId = useUserId();

  return useMutation(
    (payload: UpdatePickupStatusDTO) => {
      return updatePickupStatus({ userId, ...payload });
    },
    {
      onSuccess: (data) => {
        if (preventNavigate) return;
        navigate({
          pathname: getRoute(ROUTES.PICKUP_CREATED_INFO, data.id),
          search: searchParams.toString(),
        });
      },
      onError: (error) => {
        const uiError = handleError(error);
        enqueueSnackbar(uiError, { variant: 'error' });
      },
    },
  );
};
