import { createStyles } from '@stryberventures/gaia-react.theme';

export default createStyles((theme) => ({
  modalWrapper: {
    position: 'relative',
    cursor: 'pointer',
  },
  modal: {
    position: 'absolute',
    top: '100%',
    right: 0,
    paddingTop: theme.spacing['8'],
    zIndex: 100,
  },
  left: {
    left: 0,
  },
  right: {
    right: 0,
  },
  modalOption: {
    backgroundColor: theme.colors.background.white,
  },
}));
