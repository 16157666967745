import { Text, TextColor } from 'components/text/text';
import i18n from 'i18n';
import React from 'react';

export const Support = () => {
  return (
    <div>
      <Text color={TextColor.Tint} variant='body3'>
        {i18n.components.transportDate.timeSlot.contactUs}
      </Text>
      <a href={`mailto:${i18n.components.transportDate.timeSlot.supportEmail}`}>
        <Text color={TextColor.Secondary} variant='body3'>
          {i18n.components.transportDate.timeSlot.supportEmail}
        </Text>
      </a>
    </div>
  );
};
