import Text from '@stryberventures/gaia-react.text';
import classNames from 'classnames';
import React from 'react';

import useStyles from './styles';

export type Step = { key: string; label: string };

type StepperProps = {
  steps: Step[];
  activeStep?: Step;
};

export const Stepper: React.FC<StepperProps> = ({ activeStep, steps }) => {
  const classes = useStyles();

  if (!activeStep) return null;

  return (
    <ul className={classes.stepper}>
      {steps.map((step, index) => {
        const stepNumber = index + 1;
        const isActive = step.key === activeStep.key;
        return (
          <li key={index}>
            <Text
              className={classNames(classes.step, {
                [classes.activeStep]: isActive,
              })}
              variant={isActive ? 'body2' : 'body3'}
              weight='bold'
            >
              {stepNumber}. {step.label}
            </Text>
          </li>
        );
      })}
    </ul>
  );
};
