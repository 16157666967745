import { SVGProps } from 'react';

export const LogoIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='49'
      height='48'
      viewBox='0 0 49 48'
      fill='none'
      {...props}
    >
      <rect x='0.5' width='48' height='48' rx='6' fill='#122D23' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18.0715 17.5029V13.0953L34.4643 6L40.7858 13.0953V17.5029H28.7857L28.6641 17.0519L26.9708 17.5029H18.0715ZM26.9703 17.5031L18.0715 17.5031V19.8734L26.9703 17.5031ZM18.0714 22.547V30.4969H13.6375L6.5 14.2011L13.6375 7.91712L18.0714 7.91712L18.0714 19.8734L17.3991 20.0525L18.0714 22.547ZM18.0715 22.5471L20.3359 30.9482L31.601 27.9476L28.7857 17.5031H30.9286L30.9286 17.5031H35.3625L42.5 33.7989L35.3625 40.0829H30.9286V30.4971L30.9286 34.9047L14.5357 42L8.21427 34.9047L8.21427 30.4971H18.0715V22.5471Z'
        fill='#DF5C24'
      />
    </svg>
  );
};
