export enum RouteName {
  Home = '/',
  Login = '/login',
  SignUp = '/signup',
  SignUpConfirm = '/signup/confirm',
  SignUpVerification = '/signup/verification',
  ForgotPassword = '/forgot-password',
  ForgotPasswordConfirm = '/forgot-password/confirm',
  Profile = '/profile',
}
