import { UseQueryOptions } from '@tanstack/react-query';
import { useUserContext } from 'features/users/user-context';
import { defaultTo } from 'ramda';

import { usePermissions } from './use-permissions';
import { useUserId } from './use-user-id';

export const useEnabled = <T extends object>(options?: UseQueryOptions<T>) => {
  const userId = useUserId();
  const permissions = usePermissions();
  const { user } = useUserContext();

  if (!user) return false;

  if (permissions?.users.view) {
    return defaultTo(options?.enabled, true) && Boolean(userId);
  }
  return defaultTo(options?.enabled, true);
};
