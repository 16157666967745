import { Order, PaginatedRequestParams } from 'api';
import { TableContainer, TablePagination, TableSkeletonLoader } from 'components';
import { InventoryList } from 'features/warehouse/components/inventory-list/inventory-list';
import { InventoryTable } from 'features/warehouse/components/inventory-table/inventory-table';
import { InventoryViewType } from 'features/warehouse/components/view-switcher/view-switcher';
import { useGetWarehouseItems } from 'features/warehouse/use-cases/get-warehouse-items';
import { usePagination } from 'utils/hooks/use-pagination';

const initialParams: PaginatedRequestParams = {
  order: Order.asc,
  page: 1,
  take: 50,
};

interface InventoryContainerProps {
  view: InventoryViewType;
  query?: string;
}

export const InventoryContainer = ({ view, query }: InventoryContainerProps) => {
  const { page, onPageChange } = usePagination();
  const { data, isLoading } = useGetWarehouseItems({ ...initialParams, page, name: query });

  if (isLoading) {
    return <TableSkeletonLoader />;
  }

  if (!data) {
    return null;
  }

  return (
    <TableContainer>
      {view === InventoryViewType.table && <InventoryTable warehouseItems={data.data} />}
      {view === InventoryViewType.list && <InventoryList warehouseItems={data.data} />}
      <TablePagination meta={data.meta} onPageChange={onPageChange} />
    </TableContainer>
  );
};
