import classNames from 'classnames';
import { InventoryImagePlaceholderIcon, ZoomIcon } from 'components/Icons';
import { ImageModal } from 'features/warehouse/components/image-modal/image-modal';
import { ImageUploader } from 'features/warehouse/components/image-uploader';
import React from 'react';
import { Multimedia } from 'types/multimedia';
import { getPreviewImageUrl } from 'utils/getPreviewImageUrl';

import useStyles from './styles';

export enum ItemImageVariant {
  small = 'small',
  large = 'large',
}

export const itemImageVariantToSize = {
  [ItemImageVariant.small]: {
    width: 43,
    height: 35,
  },
  [ItemImageVariant.large]: {
    width: 99,
    height: 82,
  },
};

export const itemImageVariantToZoomIconSize = {
  [ItemImageVariant.small]: {
    width: 10,
    height: 10,
  },
  [ItemImageVariant.large]: {
    width: 14,
    height: 14,
  },
};

type ItemImageProps = {
  itemName: string;
  itemId: number;
  variant?: ItemImageVariant;
  multimedia: Multimedia[];
};

export const ItemImage: React.FC<ItemImageProps> = ({
  multimedia,
  itemId,
  itemName,
  variant = ItemImageVariant.small,
}) => {
  const [showModal, setShowModal] = React.useState(false);
  const classes = useStyles();

  if (!multimedia[0]) {
    return (
      <ImageUploader itemId={itemId}>
        <InventoryImagePlaceholderIcon
          {...itemImageVariantToSize[variant]}
          style={{ cursor: 'pointer' }}
        />
      </ImageUploader>
    );
  }
  const imageUrl = multimedia[0].url;
  const previewImageUrl = getPreviewImageUrl(multimedia[0]);

  return (
    <>
      {showModal && (
        <ImageModal
          itemName={itemName}
          itemId={itemId}
          imageUrl={imageUrl}
          previewImageUrl={previewImageUrl}
          onClose={() => setShowModal(false)}
        />
      )}
      <div
        style={{ backgroundImage: `url('${previewImageUrl}')` }}
        className={classNames(classes.image, classes[variant])}
        onClick={() => setShowModal(true)}
      >
        <div className={classNames(classes.zoom, classes[`${variant}Zoom`])}>
          <ZoomIcon {...itemImageVariantToZoomIconSize[variant]} />
        </div>
      </div>
    </>
  );
};
