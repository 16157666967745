import { createStyles } from '@stryberventures/gaia-react.theme';

export default createStyles((theme) => ({
  divider: {
    display: 'grid',
    gap: theme.spacing[16],
    textAlign: 'center',
    gridTemplateColumns: '1fr min-content 1fr',
  },
  line: {
    borderBottom: `1px solid ${theme.colors.neutralGray.light200}`,
    position: 'relative',
    transform: 'translateY(-50%)',
  },
}));
