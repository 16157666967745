import { TopBar } from 'components';
import { LogisticsControls } from 'features/logistics/components/logistics-controls/logistics-controls';
import { TableTabs } from 'features/logistics/components/table-tabs/table-tabs';
import i18n from 'i18n';
import React from 'react';

import useStyles from './styles';

export const LogisticsView = () => {
  const classes = useStyles();
  return (
    <section>
      <TopBar title={i18n.title} titleColor={'neutralGrey'} />
      <section className={classes.wrapper}>
        <LogisticsControls />
        <TableTabs />
      </section>
    </section>
  );
};
