import { QueryFunction, QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query';
import {
  getStatisticsOverviewParams,
  StatisticsApiService,
} from 'features/dashboard/api/statistics-api.service';
import { useReactQueryPowerUserUserIdManager } from 'features/users';
import { StatisticsOverview } from 'types/statistics';

import { statisticsOverviewQueryKeys } from './query-keys';

const { getStatisticsOverview } = StatisticsApiService();

const getStatisticsOverviewQuery = (
  params: getStatisticsOverviewParams,
  options: UseQueryOptions<StatisticsOverview>,
): {
  queryKey: QueryKey;
  queryFn: QueryFunction<StatisticsOverview>;
  options?: UseQueryOptions<StatisticsOverview>;
} => ({
  queryKey: statisticsOverviewQueryKeys.list(params),
  queryFn: ({ signal }) => getStatisticsOverview(params, { config: { signal } }),
  ...options,
});

export const useStatisticsOverview = (
  params: getStatisticsOverviewParams,
  options?: UseQueryOptions<StatisticsOverview>,
) => {
  const { updatedParams, enabled } = useReactQueryPowerUserUserIdManager(params, options);

  return useQuery<StatisticsOverview, Error>(
    getStatisticsOverviewQuery(updatedParams, {
      ...options,
      enabled,
    }),
  );
};
