import { InfoIcon } from '@stryberventures/gaia-react.icons';
import Text from '@stryberventures/gaia-react.text';
import { useTheme } from '@stryberventures/gaia-react.theme';
import classNames from 'classnames';
import React from 'react';
import Skeleton from 'react-loading-skeleton';

import useStyles from './styles';

type DashboardStatTextProps = {
  title: string;
  count?: number;
  type: string;
  hint?: string;
  className?: string;
  loading?: boolean;
};

export const DashboardStatText: React.FC<DashboardStatTextProps> = ({
  title,
  count,
  type,
  hint,
  className,
  loading = false,
}) => {
  const { theme } = useTheme();
  const classes = useStyles();
  return (
    <div className={classNames(classes.statTextContainer, className)}>
      {loading ? (
        <>
          <Skeleton width={65} height={17} />
          <Skeleton width={135} height={28} />
          {hint && <Skeleton height={19} width={100} />}
        </>
      ) : (
        <>
          <Text variant='components2' className={classes.greyText}>
            {title}
          </Text>
          <Text variant='h4' weight='bold'>
            {count} <span className={classes.greyText}>{type}</span>
          </Text>
          {hint && (
            <div className={classes.hintContainer}>
              <InfoIcon width={19} height={19} fill={theme.colors.primary.dark600} />
              <Text variant='components2'>{hint}</Text>
            </div>
          )}
        </>
      )}
    </div>
  );
};
