import { useGetWarehouse } from 'features/warehouse/use-cases/get-warehouse';

import { useGetQueryIdDelivery } from './get-query-id-delivery';

export const useGetQueryIdWarehouse = () => {
  const { data } = useGetQueryIdDelivery();
  const warehouseId = data?.warehouseId;

  return useGetWarehouse(
    {
      id: warehouseId || -1,
    },
    {
      enabled: Boolean(warehouseId),
    },
  );
};
