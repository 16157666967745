import Input from '@stryberventures/gaia-react.input';
import classNames from 'classnames';
import { FormControl } from 'components/form-control/form-control';
import { Select } from 'components/select/select';
import { useGetQueryIdPickup } from 'features/pickup/hooks/get-query-id-pickup';
import { Price } from 'features/pickup/routes/pickup-storage';
import i18n from 'i18n';
import React, {
  BaseSyntheticEvent,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { SingleValue } from 'react-select';
import { Option } from 'types/option';
import { getStorageTypeOptions, StorageType } from 'utils';

import useStyles from './styles';

type RequestedStorageProps = {
  setPrice?: Dispatch<SetStateAction<Price>>;
  storageTypes: StorageType[];
  selectedOption: Option | null;
  setSelectedOption: Dispatch<SetStateAction<Option | null>>;
  storageSize: number | null;
  setStorageSize: Dispatch<SetStateAction<number | null>>;
  storageUnit?: string;
};

export const RequestedStorage: React.FC<RequestedStorageProps> = ({
  setPrice,
  storageTypes,
  selectedOption,
  setSelectedOption,
  storageSize,
  setStorageSize,
  storageUnit,
}) => {
  const { data: pickupData } = useGetQueryIdPickup();

  const [options, setOptions] = useState<Option[]>([]);
  const [error, setError] = useState('');

  const classes = useStyles();

  const handlePriceChange = useCallback(
    (value?: number) => {
      const totalStorage = value || storageSize || 0;
      const storageType = storageTypes.find(
        (storageType) => storageType.id === selectedOption?.value,
      );
      if (storageType) {
        setPrice && setPrice(totalStorage * storageType.priceCents);
      }
    },
    [selectedOption?.value, setPrice, storageSize, storageTypes],
  );

  const handleStorageSizeChange = (e: BaseSyntheticEvent) => {
    if (!selectedOption) return setError(i18n.screens.pickupStorage.errorMessages.storageType);

    const { value } = e.target;
    const isNumberRegExp = /^[0-9\b]+$/;

    if (value !== '' && !isNumberRegExp.test(value)) return;

    const parsedValue = parseInt(value, 10);

    setError('');
    setStorageSize(parsedValue);
    handlePriceChange(parsedValue);
  };

  const handleStorageTypeChange = (value: SingleValue<Option>) => {
    setSelectedOption(value);
    setError('');
  };

  useEffect(() => {
    handlePriceChange();
  }, [handlePriceChange, selectedOption]);

  useEffect(() => {
    if (pickupData && storageTypes.length) {
      const storageType = storageTypes.find(
        (storageType) => storageType.id === pickupData.warehouseTypeId,
      );
      if (storageType) {
        setSelectedOption(options.find((option) => option.value === storageType.id) || null);
      }
    }
  }, [options, pickupData, setSelectedOption, storageTypes]);

  useEffect(() => {
    if (pickupData && pickupData.metersRequested) {
      setStorageSize(pickupData.metersRequested);
    }
  }, [pickupData, setStorageSize]);

  useEffect(() => {
    setOptions(getStorageTypeOptions(storageTypes, storageUnit));
  }, [storageUnit, storageTypes]);

  return (
    <section className={classes.requestedStorage}>
      <FormControl
        className={classNames(classes.formControl, classes.selectWrapper)}
        title={i18n.screens.pickupStorage.requestedStorage.storageType.title}
        subtitle={i18n.screens.pickupStorage.requestedStorage.storageType.subtitle}
        // TODO: SOR-121 Update and uncomment when backend is ready
        // hint={i18n.screens.pickupStorage.requestedStorage.storageType.hint}
        // tooltipContent={
        //   <TooltipContent metrics={config.metrics} storageTypes={MOCKED_TOOLTIP_STORAGE_TYPES} />
        // }
      >
        <Select
          options={options}
          isSearchable={false}
          value={selectedOption}
          onChange={handleStorageTypeChange}
          placeholder=''
        />
      </FormControl>
      <FormControl
        className={classNames(classes.formControl, classes.inputWrapper)}
        title={i18n.screens.pickupStorage.requestedStorage.storageSize.title}
        subtitle={i18n.screens.pickupStorage.requestedStorage.storageSize.subtitle}
      >
        <Input
          type='number'
          controlled
          value={String(storageSize)}
          onChange={handleStorageSizeChange}
          fullWidth
          postfix={storageUnit}
          errorMessage={error}
        />
      </FormControl>
    </section>
  );
};
