import { createStyles } from '@stryberventures/gaia-react.theme';

export default createStyles((theme) => ({
  wrapper: {
    border: [1, 'solid', theme.colors.neutralGray.medium300],
    borderRadius: theme.spacing[4],
    padding: theme.spacing[12],
  },
  inputLabel: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  uploadButton: {
    width: '100%',
    padding: '17px 0 !important',
    boxShadow: 'none !important',
    borderRadius: theme.spacing[4] + ' !important',
    color: theme.colors.neutralGray.main500 + ' !important',
  },
}));
