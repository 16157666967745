import { QUERY_KEYS } from 'features/query-keys';

/**
 * @see https://tkdodo.eu/blog/effective-react-query-keys#use-query-key-factories
 */
export const truckTypesQueryKeys = {
  all: [QUERY_KEYS.truckType] as const,
  lists: () => [...truckTypesQueryKeys.all, 'list'] as const,
  list: (filters: object) => [...truckTypesQueryKeys.lists(), { filters }] as const,
  details: () => [...truckTypesQueryKeys.all, 'detail'] as const,
  detail: (id: string) => [...truckTypesQueryKeys.details(), id] as const,
};
