import { format, formatInTimeZone } from 'date-fns-tz';
import { DATE_FORMATS, DateFormat, DEFAULT_LOCALE } from 'types/date';

import { DEFAULT_TIMEZONE, startOfDayUTC } from './date.service';

export type FormatDateArguments = {
  date: Date;
  dateFormat: DateFormat;
};

export const formatDate = ({ date, dateFormat }: FormatDateArguments) => {
  return format(date, DATE_FORMATS[dateFormat], {
    locale: DEFAULT_LOCALE,
    timeZone: DEFAULT_TIMEZONE,
  });
};

export type FormatTimeArguments = {
  time: Date;
  timeFormat: DateFormat;
};

export const formatTime = ({ time, timeFormat }: FormatTimeArguments) => {
  return format(time, DATE_FORMATS[timeFormat], {
    locale: DEFAULT_LOCALE,
    timeZone: DEFAULT_TIMEZONE,
  });
};

export const formatTZ = (date: string | Date, dateFormat: DateFormat) => {
  return formatInTimeZone(date, DEFAULT_TIMEZONE, DATE_FORMATS[dateFormat]);
};

export const utcDateToDbString = (date: Date) => {
  const year = date.getUTCFullYear();
  const month =
    date.getUTCMonth() + 1 >= 10 ? date.getUTCMonth() + 1 : `0${date.getUTCMonth() + 1}`;
  const day = date.getUTCDate() >= 10 ? date.getUTCDate() : `0${date.getUTCDate()}`;

  const hours = date.getUTCHours() >= 10 ? date.getUTCHours() : `0${date.getUTCHours()}`;
  const minutes = date.getUTCMinutes() >= 10 ? date.getUTCMinutes() : `0${date.getUTCMinutes()}`;

  return `${year}-${month}-${day} ${hours}:${minutes}`;
};

export const mergeDateAndTimeToDateObject = (date: Date, time: Date) => {
  const newDate = startOfDayUTC();
  newDate.setFullYear(date.getFullYear());
  newDate.setMonth(date.getMonth());
  newDate.setDate(date.getDate());
  newDate.setHours(time.getHours());

  return newDate;
};
