import { ResponseType } from 'axios';
import { AxiosAuthRefreshRequestConfig } from 'axios-auth-refresh';
import env from 'env';

export const baseUrl = {
  sortedApi: env.sortedApi,
  authApi: env.authApiUrl,
};

export type ApiClientConfig = {
  endpoint: string;
  baseURL?: string;
  data?: object;
  params?: object;
  responseType?: ResponseType;
  method?: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
  config?: AxiosAuthRefreshRequestConfig;
  apiVersion?: 'v1';
};

export enum Order {
  asc = 'ASC',
  desc = 'DESC',
}

export type PaginatedRequestParams = {
  order: Order;
  page: number;
  take: number;
};

export type PaginatedResponseMeta = {
  page: number;
  take: number;
  itemCount: number;
  pageCount: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
};

export type PaginatedResponse<T> = {
  data: T[];
  meta: PaginatedResponseMeta;
};

export type UserManagementError = {
  error: string;
  method: string;
  path: string;
  statusCode: number;
  timestamp: string;
};

export type AppBackendError = {
  errors: string[];
  message: string;
  statusCode: number;
};
