import Text from '@stryberventures/gaia-react.text';
import { Order } from 'api';
import { GetLocationsRequestParams } from 'features/location/api/location-api.service';
import { useGetLocations } from 'features/location/use-cases/get-locations';

import { CreateLocationForm } from './create-form/create-location-form';
import { useLocationContext } from './location-context';
import { Map } from './map/Map';
import { SelectLocationForm } from './select-form/select-location-form';
import useStyles from './styles';
import { SwitchForms } from './switch-forms/switch-forms';
import { LocationMode } from './types';

const initialParams: GetLocationsRequestParams = { order: Order.asc, take: 50, page: 1 };

interface LocationProps {
  locationId: number;
  title?: string;
  onUpdate: (locationId: number) => void;
  onCreateLocation: (locationId: number) => void;
  isUpdating?: boolean;
  isLoading?: boolean;
}

export const Location = ({
  locationId,
  title,
  onUpdate,
  isUpdating,
  onCreateLocation,
  isLoading,
}: LocationProps) => {
  const classes = useStyles();
  const { mode } = useLocationContext();
  const { data } = useGetLocations(initialParams);

  if (!data) return null;

  const savedLocationId = data.data.find((location) => location.id === locationId)?.id;

  return (
    <section>
      {title && (
        <Text variant='body2' weight='medium' className={classes.title}>
          {title}
        </Text>
      )}

      <div className={classes.articles}>
        <article className={classes.details}>
          <SwitchForms />
          {mode === LocationMode.Select && (
            <SelectLocationForm
              savedLocations={data.data}
              savedLocationId={savedLocationId}
              onUpdate={onUpdate}
              isUpdating={isUpdating}
              locationId={locationId}
              isLoading={isLoading}
            />
          )}
          {mode === LocationMode.Create && <CreateLocationForm onSuccess={onCreateLocation} />}
        </article>
        <Map key={mode} />
      </div>
    </section>
  );
};
