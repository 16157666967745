import { createStyles } from '@stryberventures/gaia-react.theme';

export default createStyles(() => ({
  root: {
    display: 'grid',
    gridTemplateColumns: '250px auto',
    height: '100%',
    padding: 0,
    margin: 0,
  },
}));
