import { apiClient, ApiClientConfig, PaginatedRequestParams, PaginatedResponse } from 'api';
import { REST_API_ENDPOINTS } from 'api/constants';
import { Warehouse } from 'types/warehouse';
import { WarehouseToWarehouseType } from 'types/warehouse-to-warehouse-type';

export type CreateWarehouseDTO = {
  name: string;
  address: string;
  latitude: number;
  longitude: number;
  warehouseTypeIds?: number[];
  capacity: number;
};

export type UpdateWarehouseDTO = {
  id: string;
  name?: string;
  address?: string;
  latitude?: number;
  longitude?: number;
  warehouseTypeIds?: number[];
  capacity?: number;
};

export type GetWarehousesRequestParams = {
  userId?: string;
} & PaginatedRequestParams;

export type GetWarehouseRequestParams = {
  id: number;
  userId?: string;
};

export type PaginatedWarehousesList = PaginatedResponse<
  Omit<Warehouse, 'warehouseToWarehouseTypes'>
>;

export const WarehouseApiService = () => {
  const createWarehouse = async (
    payload: CreateWarehouseDTO,
    apiConfig: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<Omit<Warehouse, 'warehouseToWarehouseTypes'>>({
      ...apiConfig,
      endpoint: REST_API_ENDPOINTS.WAREHOUSES,
      method: 'POST',
      data: payload,
    });

    // This property is missing, and we have to add it manually to avoid another query on backend side.
    // "warehouseToWarehouseTypes" property is always empty for new objects
    const warehouseToWarehouseTypes: WarehouseToWarehouseType[] = [];

    const result: Warehouse = {
      ...response.data,
      warehouseToWarehouseTypes,
    };

    return result;
  };

  const getWarehouse = async (
    params: GetWarehouseRequestParams,
    apiConfig: Partial<ApiClientConfig>,
  ) => {
    const { id, ...data } = params;
    const response = await apiClient<Warehouse>({
      ...apiConfig,
      endpoint: `${REST_API_ENDPOINTS.WAREHOUSES}/${id}`,
      data,
    });

    return response.data;
  };

  const getWarehouses = async (
    params: GetWarehousesRequestParams,
    apiConfig: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<PaginatedWarehousesList>({
      ...apiConfig,
      endpoint: REST_API_ENDPOINTS.WAREHOUSES,
      params,
    });

    return response.data;
  };

  const updateWarehouse = async (
    payload: UpdateWarehouseDTO,
    apiConfig: Partial<ApiClientConfig>,
  ) => {
    const { id, ...data } = payload;

    const response = await apiClient<Warehouse>({
      ...apiConfig,
      endpoint: `${REST_API_ENDPOINTS.WAREHOUSES}/${id}`,
      method: 'PATCH',
      data,
    });

    return response.data;
  };

  const deleteWarehouse = async (id: string, apiConfig: Partial<ApiClientConfig>) => {
    const response = await apiClient({
      ...apiConfig,
      endpoint: `${REST_API_ENDPOINTS.WAREHOUSES}/${id}`,
      method: 'DELETE',
    });

    return response.data;
  };

  return {
    getWarehouse,
    getWarehouses,
    createWarehouse,
    updateWarehouse,
    deleteWarehouse,
  };
};
