import { Badge, TopBar } from 'components';
import { DeliveryInfo } from 'features/delivery/components/delivery-info/delivery-info';
import { useGetQueryIdDelivery } from 'features/delivery/hooks';
import { useSuccessGuard } from 'features/delivery/hooks/success-guard';
import { useGetTruckTypes } from 'features/truck/use-cases/get-truck-types';
import i18n from 'i18n';
import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { useParams } from 'react-router-dom';

import useStyles from './styles';

export const DeliveryCreatedInfo: React.FC = () => {
  const { id } = useParams();
  const { data: deliveryData } = useGetQueryIdDelivery();
  const { data: truckTypes } = useGetTruckTypes({ enabled: Boolean(deliveryData?.truckTypeId) });
  const classes = useStyles();

  useSuccessGuard();

  if (!id || !deliveryData || !truckTypes) return <Skeleton width={'100%'} height={'100%'} />;

  const truckType = truckTypes.data.find((truckType) => truckType.id === deliveryData.truckTypeId);

  if (!truckType) return <Skeleton width={'100%'} height={'100%'} />;

  const deliveryStatus = deliveryData.status;

  return (
    <div className={classes.createdDeliveryRoot}>
      <TopBar
        title={`${i18n.screens.deliveryCreatedInfo.title} #${id}`}
        content={<Badge>{deliveryStatus}</Badge>}
      />
      <DeliveryInfo id={id} truckType={truckType} delivery={deliveryData} />
    </div>
  );
};
