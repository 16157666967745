import Button from '@stryberventures/gaia-react.button';
import Form from '@stryberventures/gaia-react.form';
import Input from '@stryberventures/gaia-react.input';
import Text from '@stryberventures/gaia-react.text';
import { useDir } from '@stryberventures/gaia-react.theme';
import LoadingButton from 'components/LoadingButton';
import { useForgotPassword } from 'features/auth/use-cases/forgot-password';
import useStyles from 'features/users/routes/forgot-password/styles';
import i18n from 'i18n';
import { RouteName } from 'navigation/routeNames';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

const validationSchema = yup.object().shape({
  username: yup
    .string()
    .email(i18n.screens.forgotPassword.emailInvalid)
    .required(i18n.screens.forgotPassword.emailError),
});

export const ForgotPassword = () => {
  const dir = useDir();
  const classes = useStyles({ dir });
  const [disabled, setDisabled] = React.useState(true);
  const navigate = useNavigate();

  const { mutate: forgotPassword, isLoading } = useForgotPassword();

  const handleSignUpClick = () => {
    navigate(RouteName.SignUp);
  };

  //eslint-disable-next-line
  const handleSubmit = (values: any) => {
    forgotPassword(values);
  };

  return (
    <div className={classes.forgotPasswordEmail}>
      <div className={classes.screen}>
        <div className={classes.formContainer}>
          <Text variant='h4' component='h4' weight='bold' align='center' className={classes.title}>
            {i18n.screens.forgotPassword.title}
          </Text>
          <Text variant='body2' align='center' className={classes.description}>
            {i18n.screens.forgotPassword.description}
          </Text>
          <Form
            className={classes.form}
            validationSchema={validationSchema}
            onChange={(formData, { isValid }) => {
              setDisabled(!isValid);
            }}
            onSubmit={handleSubmit}
          >
            <Input
              fullWidth
              name='username'
              label={i18n.screens.forgotPassword.label}
              placeholder={i18n.screens.forgotPassword.placeholder}
              className={classes.emailInput}
            />
            <LoadingButton
              fullWidth
              type='submit'
              disabled={disabled}
              className={classes.submitButton}
              loading={isLoading}
            >
              {i18n.screens.forgotPassword.submit}
            </LoadingButton>
            <Button
              onClick={handleSignUpClick}
              fullWidth
              type='button'
              variant='ghost'
              className={classes.loginButton}
            >
              {i18n.screens.forgotPassword.signUp}
            </Button>
          </Form>
        </div>
      </div>
    </div>
  );
};
