import { SVGProps } from 'react';

export const VerificationIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='158'
      height='158'
      viewBox='0 0 158 158'
      fill='none'
      {...props}
    >
      <path
        d='M150.191 0H7.97476C3.6551 0 0 3.48896 0 7.97476V150.025C0 154.345 3.48896 158 7.97476 158H150.025C154.345 158 158 154.511 158 150.025V7.80862C158 3.48896 154.511 0 150.191 0ZM43.1966 57.4847C46.3533 54.3281 51.3375 54.3281 54.3281 57.4847L69.1146 72.2713L103.838 37.5478C106.995 34.3912 111.979 34.3912 114.969 37.5478C118.126 40.7045 118.126 45.6887 114.969 48.6793L74.5973 88.8854C73.102 90.3807 71.1083 91.2114 68.9485 91.2114C66.7886 91.2114 64.9611 90.5468 63.4658 88.8854L43.1966 68.7823C40.2061 65.6257 40.2061 60.6414 43.1966 57.4847ZM117.129 122.612H41.0368C36.7171 122.612 33.062 119.123 33.062 114.637C33.062 110.318 36.551 106.662 41.0368 106.662H117.129C121.449 106.662 125.104 110.151 125.104 114.637C125.104 119.123 121.449 122.612 117.129 122.612Z'
        fill='url(#paint0_linear_278_43120)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_278_43120'
          x1='79'
          y1='0'
          x2='79'
          y2='158'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' stopOpacity='0.3' />
          <stop offset='1' stopColor='white' stopOpacity='0' />
        </linearGradient>
      </defs>
    </svg>
  );
};
