import { useMutation } from '@tanstack/react-query';
import { handleError } from 'api/error-handling';
import {
  DeleteDeliveryItemDTO,
  DeliveryItemsApiService,
} from 'features/delivery/api/delivery-items-api.service';
import { deliveriesQueryKeys } from 'features/delivery/use-cases/query-keys';
import { useUserId } from 'features/users';
import { queryClient } from 'index';
import { enqueueSnackbar } from 'notistack';

const { deleteDeliveryItem } = DeliveryItemsApiService();

export const useDeleteDeliveryItem = () => {
  const userId = useUserId();

  return useMutation(
    (payload: DeleteDeliveryItemDTO) => {
      return deleteDeliveryItem({ userId, ...payload });
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(deliveriesQueryKeys.detail(`${data.deliveryId}`));
        queryClient.invalidateQueries(
          deliveriesQueryKeys.list({ deliveryId: `${data.deliveryId}` }),
        );
      },
      onError: (error) => {
        const uiError = handleError(error);
        enqueueSnackbar(uiError, { variant: 'error' });
      },
    },
  );
};
