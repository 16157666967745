import { apiClient, ApiClientConfig, PaginatedRequestParams, PaginatedResponse } from 'api';
import { REST_API_ENDPOINTS } from 'api/constants';
import { WarehouseItem } from 'types/warehouse-item';

export type GetWarehouseItemsRequestParams = {
  userId?: string;
  warehouseTypeId?: number;
  name?: string;
} & PaginatedRequestParams;

export type WarehouseItemsResponse = PaginatedResponse<WarehouseItem>;

export const WarehouseItemsApiService = () => {
  const getWarehouseItems = async (
    params: GetWarehouseItemsRequestParams,
    apiConfig: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<WarehouseItemsResponse>({
      ...apiConfig,
      endpoint: REST_API_ENDPOINTS.WAREHOUSE_ITEMS,
      params,
    });

    return response.data;
  };

  return {
    getWarehouseItems,
  };
};
