import { useMutation } from '@tanstack/react-query';
import { handleError } from 'api/error-handling';
import {
  DeletePickupItemDTO,
  PickupItemsApiService,
} from 'features/pickup/api/pickup-items-api.service';
import { pickupsQueryKeys } from 'features/pickup/use-cases/query-keys';
import { useUserId } from 'features/users';
import { queryClient } from 'index';
import { enqueueSnackbar } from 'notistack';

const { deletePickupItem } = PickupItemsApiService();

export const useDeletePickupItem = () => {
  const userId = useUserId();

  return useMutation(
    (payload: DeletePickupItemDTO) => {
      return deletePickupItem({ userId, ...payload });
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(pickupsQueryKeys.detail(`${data.pickupId}`));
        queryClient.invalidateQueries(pickupsQueryKeys.list({ pickupId: `${data.pickupId}` }));
      },
      onError: (error) => {
        const uiError = handleError(error);
        enqueueSnackbar(uiError, { variant: 'error' });
      },
    },
  );
};
