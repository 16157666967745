import { useMutation } from '@tanstack/react-query';
import { handleError } from 'api/error-handling';
import {
  MultimediaApiService,
  UploadItemImageDTO,
} from 'features/multimedia/api/multimedia-api.service';
import { useUserId } from 'features/users';
import { warehouseItemsQueryKeys } from 'features/warehouse/use-cases/query-keys';
import { queryClient } from 'index';
import { enqueueSnackbar } from 'notistack';

const { uploadItemImage } = MultimediaApiService();

export const useUploadItemImage = () => {
  const userId = useUserId();
  return useMutation(
    (payload: UploadItemImageDTO) => {
      if (userId) {
        payload.append('userId', userId);
      }
      return uploadItemImage(payload, {
        config: { headers: { 'Content-Type': 'multipart/form-data' } },
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(warehouseItemsQueryKeys.lists());
      },
      onError: (error) => {
        const uiError = handleError(error);
        enqueueSnackbar(uiError, { variant: 'error' });
      },
    },
  );
};
