import { QueryFunction, QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { handleError } from 'api/error-handling';
import { GetPickupRequestParams, PickupApiService } from 'features/pickup/api/pickup-api.service';
import { useReactQueryPowerUserUserIdManager } from 'features/users';
import { enqueueSnackbar } from 'notistack';
import { Pickup } from 'types/pickup';

import { pickupsQueryKeys } from './query-keys';

const { getPickup } = PickupApiService();

const getPickupQuery = (
  params: GetPickupRequestParams,
  options?: UseQueryOptions<Pickup>,
): {
  queryKey: QueryKey;
  queryFn: QueryFunction<Pickup>;
  options?: UseQueryOptions<Pickup>;
} => ({
  queryKey: pickupsQueryKeys.detail(params.id),
  queryFn: ({ signal }) => getPickup(params, { config: { signal } }),
  ...options,
});

export const useGetPickup = (params: GetPickupRequestParams, options?: UseQueryOptions<Pickup>) => {
  const { enabled, updatedParams } = useReactQueryPowerUserUserIdManager(params, options);

  return useQuery<Pickup, Error>(
    getPickupQuery(updatedParams, {
      onError: (error) => {
        const uiError = handleError(error);
        enqueueSnackbar(uiError, { variant: 'error' });
      },
      ...options,
      enabled,
    }),
  );
};
