import { SVGProps } from 'react';

export const EditIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      {...props}
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
    >
      <path
        d='M4.5 19.5004H5.6L16.675 8.42536L15.575 7.32536L4.5 18.4004V19.5004ZM19.85 7.35036L16.65 4.15036L17.7 3.10036C17.9833 2.81702 18.3375 2.67952 18.7625 2.68786C19.1875 2.69619 19.5417 2.84202 19.825 3.12536L20.9 4.20036C21.1833 4.48369 21.325 4.83369 21.325 5.25036C21.325 5.66702 21.1833 6.01702 20.9 6.30036L19.85 7.35036ZM3.75 21.0004C3.53333 21.0004 3.35417 20.9295 3.2125 20.7879C3.07083 20.6462 3 20.467 3 20.2504V18.1004C3 18.0004 3.01667 17.9087 3.05 17.8254C3.08333 17.742 3.14167 17.6587 3.225 17.5754L15.6 5.20036L18.8 8.40036L6.425 20.7754C6.34167 20.8587 6.25833 20.917 6.175 20.9504C6.09167 20.9837 6 21.0004 5.9 21.0004H3.75ZM16.125 7.87536L15.575 7.32536L16.675 8.42536L16.125 7.87536Z'
        fill='#98A2B3'
      />
    </svg>
  );
};
