import { createStyles, toRem } from '@stryberventures/gaia-react.theme';

interface IUseStyles {
  dir: string;
}
//eslint-disable-next-line
export default createStyles<any, IUseStyles>((theme) => ({
  forgotPasswordEmail: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: `${theme.spacing[48]} 0 ${theme.spacing[80]}`,
    boxSizing: 'border-box',
    '*, *:after, *:before': {
      boxSizing: 'inherit',
    },
  },
  screen: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: toRem(560),
    width: '100%',
  },
  title: {
    width: '100%',
    marginBottom: theme.spacing[24],
    color: theme.colors.text.headline,
    fontSize: 28,
    fontFamily: theme.secondaryFont,
    fontWeight: 700,
  },
  description: {
    width: '100%',
    marginBottom: theme.spacing[48],
    color: theme.colors.neutralGray.main500,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flexGrow: 1,
  },
  emailInput: {
    position: 'relative',
    marginBottom: theme.spacing[96],
    ...hintAndErrorStyles,
  },
  submitButton: {
    marginBottom: theme.spacing[24],
  },
  loginButton: {
    marginBottom: theme.spacing[80],
  },
  textLink: {},
  [`@media (max-width: ${theme.breakpoints.md}px)`]: {
    forgotPasswordEmail: {
      padding: [theme.spacing[48], theme.spacing[16]],
    },
    description: {
      marginBottom: theme.spacing[32],
    },
    formContainer: {
      maxWidth: '100%',
      height: '100%',
    },
    emailInput: {
      marginBottom: theme.spacing[24],
    },
    submitButton: {
      marginTop: 'auto',
      marginBottom: theme.spacing[24],
    },
    loginButton: {
      marginBottom: theme.spacing[40],
    },
  },
}));

const hintAndErrorStyles = {
  '& [class*=hint], & [class*=errorMessage]': {
    position: 'absolute',
    left: 0,
    top: '100%',
    width: '100%',
  },
};
