import { createStyles } from '@stryberventures/gaia-react.theme';

export default createStyles((theme) => ({
  itemList: {
    border: [1, 'solid', theme.colors.neutralGray.medium300],
    borderRadius: theme.spacing[4],
  },
  noItemsMessage: {
    padding: theme.spacing[64],
    textAlign: 'center',
  },
}));
