import { QUERY_KEYS } from 'features/query-keys';

/**
 * @see https://tkdodo.eu/blog/effective-react-query-keys#use-query-key-factories
 */
export const locationQueryKeys = {
  all: [QUERY_KEYS.locations] as const,
  lists: () => [...locationQueryKeys.all, 'list'] as const,
  list: (filters: object) => [...locationQueryKeys.lists(), { filters }] as const,
  details: () => [...locationQueryKeys.all, 'detail'] as const,
  detail: (id: string) => [...locationQueryKeys.details(), id] as const,
};
