import Text from '@stryberventures/gaia-react.text';
import { DashboardOverviewStatistics } from 'features/dashboard/components/dashboard-overview-statistics';
import i18n from 'i18n';
import React from 'react';

import useStyles from './styles';

export const DashboardOverview: React.FC = () => {
  const classes = useStyles();
  return (
    <section className={classes.actions}>
      <div className={classes.actionContainer}>
        <Text variant='h4' weight='bold'>
          {i18n.screens.dashboard.section.overview.title}
        </Text>
      </div>
      <DashboardOverviewStatistics />
      {/* TODO: SOR-121 Update and uncomment when backend is ready */}
      {/* <DashboardOverviewCharge /> */}
    </section>
  );
};
