import Form from '@stryberventures/gaia-react.form';
import Input from '@stryberventures/gaia-react.input';
import InputPassword from '@stryberventures/gaia-react.input-password';
import Text from '@stryberventures/gaia-react.text';
import TextLink from '@stryberventures/gaia-react.text-link';
import { useDir } from '@stryberventures/gaia-react.theme';
import { LogoIcon } from 'components/Icons/Logo';
import LoadingButton from 'components/LoadingButton';
import { useSignUp } from 'features/auth/use-cases/sign-up';
import i18n from 'i18n';
import { RouteName } from 'navigation/routeNames';
import React from 'react';
import * as yup from 'yup';

import useStyles from './styles';

const passwordRegEx =
  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])(?=(?:.*[!@#$%^&*()\-_=+{};:,<.>])+).{8,}$/;
const passwordErrorMessage = i18n.screens.signUp.errors.passwordInvalid;

const validationSchema = yup.object().shape({
  firstName: yup.string().required(i18n.screens.signUp.errors.firstName),
  lastName: yup.string().required(i18n.screens.signUp.errors.lastName),
  username: yup
    .string()
    .email(i18n.screens.signUp.errors.emailInvalid)
    .required(i18n.screens.signUp.errors.email),
  phone: yup.string().required(i18n.screens.signUp.errors.phone),
  password: yup
    .string()
    .matches(passwordRegEx, passwordErrorMessage)
    .required(i18n.screens.signUp.errors.password),
  repeatPassword: yup
    .string()
    .oneOf([yup.ref('password')], i18n.screens.signUp.errors.confirmPassword),
});

export const SignUp = () => {
  const dir = useDir();
  const classes = useStyles({ dir });
  const [disabled, setDisabled] = React.useState(true);
  const { mutate: signUp, isLoading } = useSignUp();

  //eslint-disable-next-line
  const handleSubmit = (values: any) => {
    signUp(values);
  };

  return (
    <div className={classes.signUpEmail}>
      <div className={classes.logoWrapper}>
        <LogoIcon />
      </div>
      <div className={classes.screen}>
        <div className={classes.formContainer}>
          <Text variant='h4' component='h4' weight='bold' align='center' className={classes.title}>
            {i18n.screens.signUp.title}
          </Text>
          <Text variant='body2' align='center' className={classes.description}>
            {i18n.screens.signUp.description}
          </Text>
          <Form
            className={classes.form}
            validationSchema={validationSchema}
            onChange={(formData, { isValid }) => {
              setDisabled(!isValid);
            }}
            onSubmit={handleSubmit}
          >
            <div className={classes.nameContainer}>
              <Input
                fullWidth
                name='firstName'
                label={i18n.screens.signUp.labels.firstName}
                placeholder={i18n.screens.signUp.placeholders.firstName}
              />
              <Input
                fullWidth
                name='lastName'
                label={i18n.screens.signUp.labels.lastName}
                placeholder={i18n.screens.signUp.placeholders.lastName}
              />
            </div>
            <Input
              fullWidth
              name='username'
              label={i18n.screens.signUp.labels.email}
              placeholder={i18n.screens.signUp.placeholders.email}
              className={classes.input}
            />
            <Input
              fullWidth
              name='phone'
              label={i18n.screens.signUp.labels.phone}
              placeholder={i18n.screens.signUp.placeholders.phone}
              className={classes.input}
            />
            <InputPassword
              fullWidth
              name='password'
              autoComplete='new-password'
              label={i18n.screens.signUp.labels.password}
              placeholder={i18n.screens.signUp.placeholders.password}
              hint={passwordErrorMessage}
              className={classes.input}
            />
            <InputPassword
              fullWidth
              name='repeatPassword'
              autoComplete='new-password'
              label={i18n.screens.signUp.labels.confirmPassword}
              placeholder={i18n.screens.signUp.placeholders.confirmPassword}
              className={classes.input}
            />
            <LoadingButton
              fullWidth
              type='submit'
              disabled={disabled}
              className={classes.submitButton}
              loading={isLoading}
            >
              {i18n.screens.signUp.submit}
            </LoadingButton>
            <Text className={classes.loginText} align='center'>
              {i18n.screens.signUp.signInDescription}{' '}
              <TextLink className={classes.link} href={RouteName.Login}>
                {i18n.screens.signUp.signIn}
              </TextLink>
            </Text>
          </Form>
        </div>
      </div>
    </div>
  );
};
