import { useUserContext, useUserId } from 'features/users';
import { getUserPermissions, hasRightsToUseUserId } from 'features/users/permissions';
import { User } from 'types/user';

export const getMissingUserIdError = <T extends { userId?: string }>(
  user: User | null,
  userId: string | undefined,
  payload: T,
) => {
  if (!user) {
    return;
  }

  const permissions = getUserPermissions(user);
  const hasAccessToUserId = permissions.users.view;

  if (!hasAccessToUserId) {
    return;
  }

  if (!user) {
    throw new Error('Current logged in user data are not available!. PLease check useUserContext');
  }

  if (!userId) {
    throw new Error('User ID is not available because user was not set!');
  }

  if (!hasRightsToUseUserId(user)) {
    throw new Error("You don't have permissions to use user id");
  }

  if (!('userId' in payload)) {
    throw new Error('Request denied. User id is missing in a payload!');
  }
};

export const useMissingUserIdError = (payload: object, enabled: boolean) => {
  const { user } = useUserContext();
  const userId = useUserId();

  if (!enabled) return;

  getMissingUserIdError(user, userId, payload);
};
