import { SVGProps } from 'react';

export const HelpIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      {...props}
      xmlns='http://www.w3.org/2000/svg'
      width='13'
      height='14'
      viewBox='0 0 13 14'
      fill='none'
    >
      <path
        d='M4.92375 5.37467C5.05109 5.01266 5.30245 4.7074 5.6333 4.51295C5.96416 4.31851 6.35315 4.24743 6.73138 4.31231C7.10962 4.37719 7.45269 4.57383 7.69983 4.86742C7.94697 5.161 8.08223 5.53258 8.08166 5.91634C8.08166 6.99967 6.45666 7.54134 6.45666 7.54134M6.5 9.70801H6.50541M11.9167 6.99967C11.9167 9.99122 9.49154 12.4163 6.5 12.4163C3.50845 12.4163 1.08333 9.99122 1.08333 6.99967C1.08333 4.00813 3.50845 1.58301 6.5 1.58301C9.49154 1.58301 11.9167 4.00813 11.9167 6.99967Z'
        stroke='#98A2B3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
