import { createStyles } from '@stryberventures/gaia-react.theme';

export default createStyles((theme) => ({
  item: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr 150px auto auto',
    alignItems: 'center',
    padding: theme.spacing[16],
    gap: 14,
    borderBottom: [1, 'solid', theme.colors.neutralGray.light200],
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  titleWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing[4],
    flexGrow: 1,
  },
  saveQuantityButton: {
    height: 48,
    width: 60,
    '& path': {
      fill: theme.colors.neutralGray.medium300,
    },
  },
  image: {
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: 73,
    height: 54,
    borderRadius: 4,
  },
}));
