import { useMutation } from '@tanstack/react-query';
import { handleError } from 'api/error-handling';
import {
  DeletePickupRequestParams,
  PickupApiService,
} from 'features/pickup/api/pickup-api.service';
import { pickupsQueryKeys } from 'features/pickup/use-cases/query-keys';
import { queryClient } from 'index';
import { enqueueSnackbar } from 'notistack';

const { deletePickup } = PickupApiService();

export const useDeletePickup = () => {
  return useMutation(
    (params: DeletePickupRequestParams) => {
      return deletePickup(params);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(pickupsQueryKeys.lists());
      },
      onError: (error) => {
        const uiError = handleError(error);
        enqueueSnackbar(uiError, { variant: 'error' });
      },
    },
  );
};
