import { TopBar } from 'components';
import { EditDeliveryForm } from 'features/delivery/components/edit-delivery-form/edit-delivery-form';
import i18n from 'i18n';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ROUTES } from 'routes';

export const DeliveryOverviewView = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const handleGoBack = () => {
    navigate({
      pathname: ROUTES.LOGISTICS,
      search: searchParams.toString(),
    });
  };

  return (
    <div>
      <TopBar title={i18n.screens.deliveryOverview.title} onGoBackButtonClick={handleGoBack} />
      <EditDeliveryForm />
    </div>
  );
};
