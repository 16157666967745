import { createStyles } from '@stryberventures/gaia-react.theme';

export default createStyles((theme) => ({
  statTextContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  greyText: {
    color: theme.colors.neutralGray.medium400,
    fontWeight: 500,
  },
  hintContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing[4],
    gap: 2,
    color: theme.colors.primary.dark600,
  },
}));
