import { createStyles } from '@stryberventures/gaia-react.theme';

export const useGlobalStyles = createStyles((theme) => ({
  '@global': {
    body: {
      height: '100%',
      width: '100%',
      padding: 0,
      margin: 0,
      fontFamily: theme.font,
    },
    ul: {
      padding: '0px',
      margin: '0px',
      listStyleType: 'none',
    },
    '#root': {
      height: '100%',
    },
    html: {
      height: '100%',
      width: '100%',
    },
  },
  '*': {
    boxSizing: 'border-box',
  },
}));
