import { useUpdateDelivery } from 'features/delivery/use-cases/update-delivery';
import { useParams } from 'react-router-dom';

export const useUpdateQueryIdDelivery = () => {
  const { id } = useParams();

  if (!id) {
    throw new Error('Can not update delivery, because delivery id in the url is missing');
  }

  return useUpdateDelivery(id);
};
