import { createStyles } from '@stryberventures/gaia-react.theme';

export default createStyles((theme) => ({
  stepper: {
    display: 'flex',
    gap: theme.spacing['24'],
    alignItems: 'center',
    margin: 0,
    padding: 0,
    listStyle: 'none',
  },
  step: {
    color: theme.colors.text.disabled,
  },
  activeStep: {
    color: theme.colors.primary.main500,
  },
}));
