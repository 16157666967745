import { Text, TextColor } from 'components/text/text';

import useStyles from './styles';

type DividerProps = {
  text: string;
};
export const Divider = ({ text }: DividerProps) => {
  const classes = useStyles();

  return (
    <div className={classes.divider}>
      <div className={classes.line} />
      <Text
        style={{
          letterSpacing: '1.82px',
        }}
        variant={'body3'}
        color={TextColor.Tint}
        weight={'medium'}
      >
        {text.toUpperCase()}
      </Text>
      <div className={classes.line} />
    </div>
  );
};
