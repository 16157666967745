import { createStyles } from '@stryberventures/gaia-react.theme';

export default createStyles((theme) => ({
  controls: {
    display: 'grid',
    gridTemplateColumns: 'max-content max-content max-content',
    gridTemplateRows: '60px',
    justifyContent: 'space-between',
  },
  switch: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
  },
  buttonWrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: theme.spacing[12],
  },
  button: {
    maxHeight: '42px',
  },
}));
