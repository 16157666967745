import Input from '@stryberventures/gaia-react.input';
import { FormControl } from 'components/form-control/form-control';
import { Select } from 'components/select/select';
import { useGetTruckTypes } from 'features/truck/use-cases/get-truck-types';
import i18n from 'i18n';
import React, { useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { TruckType } from 'types/truck-type';
import { getTruckTypesOptions } from 'utils';

import useStyles from './styles';

type TruckTypeFormProps = {
  truckTypeId?: number;
  comments?: string;
  isLoading?: boolean;
  onTruckTypeChange: (truckTypeId: number) => void;
  onCommentsBlur: (value: string) => void;
  onPriceChange?: (price: number) => void;
};

type FormInputs = {
  truckType: {
    label: string;
    value: number;
  };
  comment: string;
};

const getTruckType = (truckTypes: TruckType[], truckTypeId: number) =>
  truckTypes.find((truckType) => truckType.id === truckTypeId);

export const TruckTypeForm = ({
  truckTypeId,
  comments,
  isLoading,
  onTruckTypeChange,
  onCommentsBlur,
  onPriceChange,
}: TruckTypeFormProps) => {
  const classes = useStyles();
  const { data: truckTypes, isSuccess: isTruckTypesSuccess } = useGetTruckTypes();

  const truckTypesOptions = useMemo(() => {
    if (!truckTypes) return;
    return getTruckTypesOptions(truckTypes.data);
  }, [truckTypes]);
  const initialTruckType = truckTypesOptions?.find((truck) => truck.value === truckTypeId);

  const { control, watch } = useForm<FormInputs>({
    defaultValues: {
      comment: comments,
      truckType: initialTruckType,
    },
  });

  const truckTypeOption = watch('truckType');

  useEffect(() => {
    if (!truckTypeId || !truckTypes) return;
    const truckType = getTruckType(truckTypes.data, truckTypeId);
    if (truckType && onPriceChange) {
      onPriceChange(truckType.priceCents);
    }
  }, [truckTypeId, truckTypeOption, truckTypes, onPriceChange]);

  if (!isTruckTypesSuccess) {
    return null;
  }

  return (
    <form className={classes.form}>
      <FormControl title={i18n.components.truckTypeForm.truckType.label}>
        <Controller
          name='truckType'
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              placeholder={i18n.components.truckTypeForm.truckType.placeholder}
              onChange={(truckOption) => {
                field.onChange(truckOption);
                if (truckOption) {
                  onTruckTypeChange(Number(truckOption.value));
                }
              }}
              isDisabled={isLoading}
              options={truckTypesOptions}
            />
          )}
        />
      </FormControl>
      <FormControl title={i18n.components.truckTypeForm.comment.label}>
        <Controller
          name='comment'
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              onChange={() => {}}
              disabled={isLoading}
              onBlur={(event) => {
                field.onChange(event.target.value);
                onCommentsBlur(event.target.value);
              }}
              fullWidth
              placeholder={i18n.components.truckTypeForm.comment.placeholder}
            />
          )}
        />
      </FormControl>
    </form>
  );
};
