import Divider from '@stryberventures/gaia-react.divider';
import React from 'react';

import useStyles from './styles';

type PageNavigationBarProps = {
  renderLeftSide?: React.ReactNode;
  renderRightSide?: React.ReactNode;
};

export const PageNavigationBar: React.FC<PageNavigationBarProps> = ({
  renderLeftSide,
  renderRightSide,
}) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.pageNavigationBar}>
        <div className={classes.side}>{renderLeftSide}</div>
        <div className={classes.side}>{renderRightSide}</div>
      </div>
      <Divider />
    </>
  );
};
