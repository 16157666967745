import { FormControl } from 'components/form-control/form-control';
import { getLatLngString } from 'components/google-maps/get-lat-lng';
import { useLocationContext } from 'components/location/location-context';
import { Select } from 'components/select/select';
import i18n from 'i18n';
import React, { useEffect, useState } from 'react';
import { SingleValue } from 'react-select';
import { Location } from 'types/location';
import { Option } from 'types/option';

type SelectLocationFormProps = {
  savedLocations: Location[];
  savedLocationId?: number;
  onUpdate: (locationId: number) => void;
  isUpdating?: boolean;
  locationId: number;
  isLoading?: boolean;
};

const prepareLocationOptions = (locations: Location[]) => {
  return locations.map((location) => ({
    label: location.name,
    value: location.id,
  }));
};

export const SelectLocationForm = ({
  savedLocations,
  savedLocationId,
  onUpdate,
  isUpdating,
  locationId,
  isLoading,
}: SelectLocationFormProps) => {
  const { setPin, setSelectedLocationId, selectedLocationId } = useLocationContext();
  const [options, setOptions] = useState<{ label: string; value: number }[]>([]);

  const isDisabled = isLoading || isUpdating;

  useEffect(() => {
    setOptions(prepareLocationOptions(savedLocations));
    setSelectedLocationId(selectedLocationId);
  }, [savedLocations, selectedLocationId, setSelectedLocationId]);

  useEffect(() => {
    setSelectedLocationId(savedLocationId || null);
  }, [savedLocationId, setSelectedLocationId]);

  const updateMapPin = (option: SingleValue<Option>) => {
    const value = option?.value;
    const locationMatch = savedLocations.find((location) => location.id === Number(value));

    if (locationMatch) {
      onUpdate(locationMatch.id);
      setPin(getLatLngString({ lat: locationMatch.latitude, lng: locationMatch?.longitude }));
    }
    if (value) {
      selectLocation(Number(value));
    }
  };

  const selectLocation = (id: number | null) => {
    setSelectedLocationId(id);
  };

  const locationMatch = savedLocations.find((location) => location.id === locationId);
  const defaultValue = {
    label: locationMatch?.name || '',
    value: locationMatch?.id || -1,
  };

  return (
    <FormControl title={i18n.components.location.formFields.locationNameSelect.label}>
      <Select
        defaultValue={defaultValue ? defaultValue : undefined}
        value={defaultValue}
        isDisabled={isDisabled}
        onChange={updateMapPin}
        options={options}
        placeholder={i18n.components.location.formFields.locationNameSelect.placeholder}
      />
    </FormControl>
  );
};
