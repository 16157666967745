import { createStyles, toRem } from '@stryberventures/gaia-react.theme';

interface IUseStyles {
  dir: string;
}
// eslint-disable-next-line
export default createStyles<any, IUseStyles>((theme) => ({
  signUpEmail: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: `${theme.spacing[48]} ${theme.spacing[80]}`,
    boxSizing: 'border-box',
    '*, *:after, *:before': {
      boxSizing: 'inherit',
    },
  },
  logoWrapper: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    marginBottom: theme.spacing[24],
    boxSizing: 'border-box',
  },
  screen: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: toRem(560),
    width: '100%',
    height: '100%',
  },
  title: {
    width: '100%',
    marginBottom: theme.spacing[8],
    color: theme.colors.text.headline,
    fontSize: 28,
    fontFamily: theme.secondaryFont,
    fontWeight: 700,
  },
  description: {
    width: '100%',
    marginBottom: theme.spacing[32],
    color: theme.colors.neutralGray.main500,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  input: {
    position: 'relative',
    marginBottom: theme.spacing[48],
  },
  submitButton: {
    marginBottom: theme.spacing[24],
  },
  nameContainer: {
    display: 'flex',
    gap: 15,
    marginBottom: theme.spacing[48],
  },
  loginText: {
    fontSize: 14,
    color: theme.colors.neutralGray.main500,
  },
  link: {
    '&>span': {
      fontSize: 14,
    },
  },
  [`@media (max-width: ${theme.breakpoints.md}px)`]: {
    signUpEmail: {
      height: '100vh',
      padding: [theme.spacing[48], theme.spacing[16]],
    },
    formContainer: {
      maxWidth: '100%',
    },
    nameContainer: {
      marginBottom: theme.spacing[24],
    },
    input: {
      marginBottom: theme.spacing[24],
    },
    repeatPasswordInput: {
      marginBottom: theme.spacing[12],
    },
    submitButton: {
      marginTop: 'auto',
    },
  },
}));
