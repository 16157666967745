import { useMutation } from '@tanstack/react-query';
import { handleError } from 'api/error-handling';
import {
  DeliveryItemsApiService,
  UpdateDeliveryItemDTO,
} from 'features/delivery/api/delivery-items-api.service';
import { deliveriesQueryKeys } from 'features/delivery/use-cases/query-keys';
import { useUserId } from 'features/users';
import { queryClient } from 'index';
import { enqueueSnackbar } from 'notistack';

const { updateDeliveryItem } = DeliveryItemsApiService();

export const useUpdateDeliveryItem = (deliveryId?: number) => {
  const userId = useUserId();

  return useMutation(
    (payload: UpdateDeliveryItemDTO) => {
      return updateDeliveryItem({ userId, ...payload });
    },
    {
      onSuccess: () => {
        if (deliveryId) {
          queryClient.invalidateQueries(deliveriesQueryKeys.detail(String(deliveryId)));
          queryClient.invalidateQueries(deliveriesQueryKeys.list({ deliveryId: `${deliveryId}` }));
        }
      },
      onError: (error) => {
        const uiError = handleError(error);
        enqueueSnackbar(uiError, { variant: 'error' });
      },
    },
  );
};
