import { Order, PaginatedRequestParams } from 'api';
import { TopBar } from 'components';
import { AddItemForm } from 'features/delivery/components/add-item-form/add-item-form';
import { DeliveryItemsList } from 'features/delivery/components/delivery-items-list/delivery-items-list';
import { DeliveryNavigation } from 'features/delivery/components/delivery-navigation';
import { RequestedStorage } from 'features/delivery/components/requested-storage/requested-storage';
import { useGetQueryIdDelivery, useStorageGuard } from 'features/delivery/hooks';
import { useGetWarehouseItems } from 'features/warehouse/use-cases/get-warehouse-items';
import i18n from 'i18n';
import Skeleton from 'react-loading-skeleton';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ROUTES } from 'routes';
import { getRoute } from 'routes/utils/get-route';
import { useGetStorageTypes } from 'utils/hooks/get-storage-types';

import useStyles from './styles';

const initialWarehouseItemsParams: PaginatedRequestParams = {
  order: Order.asc,
  page: 1,
  take: 50,
};

export const DeliveryStorageView = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const classes = useStyles();

  useStorageGuard();

  const { storageTypes, storageUnit } = useGetStorageTypes();
  const { data: deliveryData } = useGetQueryIdDelivery();
  const warehouseTypeId = deliveryData?.warehouseTypeId;
  const { data: warehouseItemsData } = useGetWarehouseItems(
    { ...initialWarehouseItemsParams, warehouseTypeId },
    {
      enabled: !!warehouseTypeId,
    },
  );

  const itemsToDelivery = deliveryData?.itemsToDelivery || [];
  const warehouseItems = warehouseItemsData?.data || [];

  if (!deliveryData || !storageTypes) return <Skeleton width='100%' height='100%' />;

  const handleGoBack = () => {
    navigate({
      pathname: ROUTES.LOGISTICS,
      search: searchParams.toString(),
    });
  };

  const handleProceed = () => {
    navigate({
      pathname: getRoute(ROUTES.DELIVERY_LOGISTICS, deliveryData.id),
      search: searchParams.toString(),
    });
  };

  return (
    <div>
      <TopBar title={i18n.screens.deliveryStorage.title} onGoBackButtonClick={handleGoBack} />
      <DeliveryNavigation
        isProceedDisabled={!itemsToDelivery.length}
        onProceedClick={handleProceed}
      />
      <div className={classes.sections}>
        <RequestedStorage
          storageTypes={storageTypes}
          isItemsToDelivery={!!itemsToDelivery.length}
          warehouseTypeId={warehouseTypeId}
          storageUnit={storageUnit}
        />
        <AddItemForm isStorageTypeSelected={!!warehouseTypeId} warehouseItems={warehouseItems} />
        <DeliveryItemsList warehouseItems={warehouseItems} items={itemsToDelivery} />
      </div>
    </div>
  );
};
