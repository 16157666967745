import { PointArrowIcon } from '@stryberventures/gaia-react.icons';
import Text from '@stryberventures/gaia-react.text';
import React from 'react';

import useStyles from './styles';

type GoBackButtonProps = {
  children?: React.ReactNode;
  onClick?: () => void;
};

export const GoBackButton: React.FC<GoBackButtonProps> = ({ children, onClick }) => {
  const classes = useStyles();
  return (
    <button type='button' className={classes.goBackButton} onClick={onClick}>
      <PointArrowIcon variant='left' />
      <Text variant='buttonLabelMini' weight='bold'>
        {children}
      </Text>
    </button>
  );
};
