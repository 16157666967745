import { Text, TextColor } from 'components/text/text';
import i18n from 'i18n';
import React from 'react';

import useStyles from './styles';

type StorageItemsListProps = {
  isEmpty: boolean;
  children: React.ReactNode;
};

export const StorageItemsList: React.FC<StorageItemsListProps> = ({ isEmpty, children }) => {
  const classes = useStyles();
  return (
    <ul className={classes.itemList}>
      {isEmpty ? (
        children
      ) : (
        <div className={classes.noItemsMessage}>
          <Text color={TextColor.Tint} variant='components2'>
            {i18n.components.storageItemsList.noItemsMessage}
          </Text>
        </div>
      )}
    </ul>
  );
};
