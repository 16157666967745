import { ThemeProvider } from '@stryberventures/gaia-react.theme';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { UserContextProvider, UsersContextProvider } from 'features';
import React from 'react';
import ReactDOM from 'react-dom/client';
import Modal from 'react-modal';

import SnackbarProvider from './components/SnackbarProvider';
import Navigation from './navigation';
import reportWebVitals from './reportWebVitals';
import theme from './styles/theme';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

Modal.setAppElement('#root');

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider>
          <UserContextProvider>
            <UsersContextProvider>
              <Navigation />
            </UsersContextProvider>
          </UserContextProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </QueryClientProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
