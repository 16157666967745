export enum AuthStorageKey {
  AccessToken = 'accessToken',
}

export function setAccessToken(value: string, persist = true) {
  const storage = persist ? localStorage : sessionStorage;

  storage.setItem(AuthStorageKey.AccessToken, value);
}

export function getAccessToken() {
  return (
    localStorage.getItem(AuthStorageKey.AccessToken) ||
    sessionStorage.getItem(AuthStorageKey.AccessToken)
  );
}
