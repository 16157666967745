import { TableContainer as GaiaTableContainer } from '@stryberventures/gaia-react.table';
import { ITableContainer } from '@stryberventures/gaia-react.table/TableContainer';

import useStyles from './table.styles';

export const TableContainer = ({ ...props }: ITableContainer) => {
  const classes = useStyles();
  return (
    <GaiaTableContainer {...props} className={classes.tableContainer}>
      {props.children}
    </GaiaTableContainer>
  );
};
