import Layout from 'components/layout/layout';
import { LocationContextProvider } from 'components/location/location-context';
import {
  AccountNotActivatedView,
  DashboardView,
  DeliveryCreatedInfo,
  DeliveryStorageView,
  DeliveryView,
  LogisticsView,
  PickupCreatedInfoView,
  PickupLogisticsView,
  PickupOverviewView,
  PickupStorageView,
} from 'features';
import { DeliveryLogisticsView } from 'features/delivery/routes/delivery-logistics';
import { DeliveryOverviewView } from 'features/delivery/routes/delivery-overview';
import {
  ErrorPage,
  ForgotPassword,
  ForgotPasswordConfirm,
  Login,
  SignUp,
  SignUpConfirm,
  SignUpVerification,
} from 'features/users/routes';
import { InventoryView } from 'features/warehouse';
import { createBrowserRouter, RouteObject } from 'react-router-dom';
import { AuthenticatedRoute, RestrictedRoute, ROUTES, UnauthenticatedRoute } from 'routes';

const authConfig = [
  {
    path: ROUTES.LOGIN,
    element: (
      <UnauthenticatedRoute>
        <Login />
      </UnauthenticatedRoute>
    ),
  },
  {
    path: ROUTES.SIGN_UP,
    element: (
      <UnauthenticatedRoute>
        <SignUp />
      </UnauthenticatedRoute>
    ),
  },
  {
    path: ROUTES.SIGN_UP_CONFIRM,
    element: (
      <UnauthenticatedRoute>
        <SignUpConfirm />
      </UnauthenticatedRoute>
    ),
  },
  {
    path: ROUTES.SIGN_UP_VERIFICATION,
    element: (
      <UnauthenticatedRoute>
        <SignUpVerification />
      </UnauthenticatedRoute>
    ),
  },
  {
    path: ROUTES.FORGOT_PASSWORD,
    element: (
      <UnauthenticatedRoute>
        <ForgotPassword />
      </UnauthenticatedRoute>
    ),
  },
  {
    path: ROUTES.FORGOT_PASSWORD_CONFIRM,
    element: (
      <UnauthenticatedRoute>
        <ForgotPasswordConfirm />
      </UnauthenticatedRoute>
    ),
  },
];

export const routerConfig: RouteObject[] = [
  {
    errorElement: <ErrorPage />,
    children: [
      ...authConfig,
      {
        path: ROUTES.HOME,
        element: (
          <AuthenticatedRoute>
            <Layout />
          </AuthenticatedRoute>
        ),
        children: [
          {
            index: true,
            element: (
              <AuthenticatedRoute>
                <DashboardView />
              </AuthenticatedRoute>
            ),
          },
          {
            path: ROUTES.ACCOUNT_NOT_ACTIVATED,
            element: (
              <AuthenticatedRoute>
                <AccountNotActivatedView />
              </AuthenticatedRoute>
            ),
          },
          {
            path: ROUTES.DELIVERIES,
            element: (
              <AuthenticatedRoute>
                <DeliveryView />
              </AuthenticatedRoute>
            ),
          },
          {
            path: ROUTES.DELIVERY_STORAGE,
            element: (
              <RestrictedRoute
                requiredPermission={'deliveryStorage.view'}
                redirectPath={ROUTES.HOME}
              >
                <DeliveryStorageView />
              </RestrictedRoute>
            ),
          },
          {
            path: ROUTES.DELIVERY_LOGISTICS,
            element: (
              <RestrictedRoute
                requiredPermission={'deliveryLogistics.view'}
                redirectPath={ROUTES.HOME}
              >
                <LocationContextProvider>
                  <DeliveryLogisticsView />
                </LocationContextProvider>
              </RestrictedRoute>
            ),
          },
          {
            path: ROUTES.DELIVERY_CREATED_INFO,
            element: (
              <RestrictedRoute
                requiredPermission={'deliveryCreatedInfo.view'}
                redirectPath={ROUTES.HOME}
              >
                <DeliveryCreatedInfo />
              </RestrictedRoute>
            ),
          },
          {
            path: ROUTES.DELIVERY_OVERVIEW,
            element: (
              <RestrictedRoute
                requiredPermission={'deliveryOverview.view'}
                redirectPath={ROUTES.HOME}
              >
                <DeliveryOverviewView />
              </RestrictedRoute>
            ),
          },
          {
            path: ROUTES.PICKUP_STORAGE,
            element: (
              <RestrictedRoute requiredPermission={'pickupStorage.view'} redirectPath={ROUTES.HOME}>
                <PickupStorageView />
              </RestrictedRoute>
            ),
          },
          {
            path: ROUTES.PICKUP_LOGISTICS,
            element: (
              <RestrictedRoute
                requiredPermission={'pickupLogistics.view'}
                redirectPath={ROUTES.HOME}
              >
                <LocationContextProvider>
                  <PickupLogisticsView />
                </LocationContextProvider>
              </RestrictedRoute>
            ),
          },
          {
            path: ROUTES.PICKUP_OVERVIEW,
            element: (
              <RestrictedRoute
                requiredPermission={'pickupOverview.view'}
                redirectPath={ROUTES.HOME}
              >
                <PickupOverviewView />
              </RestrictedRoute>
            ),
          },
          {
            path: ROUTES.PICKUP_CREATED_INFO,
            element: (
              <RestrictedRoute
                requiredPermission={'pickupCreatedInfo.view'}
                redirectPath={ROUTES.HOME}
              >
                <PickupCreatedInfoView />
              </RestrictedRoute>
            ),
          },
          {
            path: ROUTES.LOGISTICS,
            element: (
              <RestrictedRoute requiredPermission={'logistics.view'} redirectPath={ROUTES.HOME}>
                <LogisticsView />
              </RestrictedRoute>
            ),
          },
          {
            path: ROUTES.INVENTORY,
            element: (
              <RestrictedRoute requiredPermission={'inventory.view'} redirectPath={ROUTES.HOME}>
                <InventoryView />
              </RestrictedRoute>
            ),
          },
        ],
      },
    ],
  },
];

export const router = createBrowserRouter(routerConfig);
