import { createStyles } from '@stryberventures/gaia-react.theme';

interface IUseStyles {
  dir: string;
}
//eslint-disable-next-line
export default createStyles<any, IUseStyles>((theme) => ({
  title: {
    color: theme.colors.text.secondary,
    marginBottom: theme.spacing[8],
  },
  articles: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: theme.spacing[12],
  },
  details: {
    display: 'grid',
    gridTemplateRows: 'min-content min-content',
    gap: theme.spacing[8],
  },
}));
