import { useMutation } from '@tanstack/react-query';
import { handleError } from 'api/error-handling';
import { AuthApiService, LoginDTO } from 'features/auth/auth-api.service';
import { setAccessToken } from 'features/auth/auth-storage';
import { enqueueSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes/constants';

const { login } = AuthApiService();

export const useLogin = () => {
  const navigate = useNavigate();

  const options = (credentials: LoginDTO) => {
    return login(credentials);
  };

  return useMutation(options, {
    onError: (error) => {
      const uiError = handleError(error);
      enqueueSnackbar(uiError, { variant: 'error' });
    },
    onSuccess: (response) => {
      if (response.result === 'TOKENS') {
        setAccessToken(response.data.accessToken);
        navigate(ROUTES.HOME);
      }
    },
  });
};
