import { getUserPermissions } from 'features/users/permissions';
import { useUserContext } from 'features/users/user-context';

export const usePermissions = () => {
  const { user } = useUserContext();

  if (!user) return null;

  const permissions = getUserPermissions(user);

  return permissions;
};
