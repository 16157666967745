import { createStyles } from '@stryberventures/gaia-react.theme';

export default createStyles(() => ({
  contextMenuButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 30,
    height: 30,
    cursor: 'pointer',
  },
}));
