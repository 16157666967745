import { useGetQueryIdDelivery } from 'features/delivery/hooks/get-query-id-delivery';
import Skeleton from 'react-loading-skeleton';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { ROUTES } from 'routes';
import { getRoute } from 'routes/utils/get-route';
import { Status } from 'types/status';

export const useSuccessGuard = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const { data, isLoading, isSuccess } = useGetQueryIdDelivery();

  if (!id) {
    navigate({
      pathname: ROUTES.HOME,
      search: searchParams.toString(),
    });
    return;
  }

  if (isLoading) {
    return <Skeleton width={'100%'} height={'100%'} />;
  }

  if (isSuccess) {
    const isDraftStatus = data.status === Status.DRAFT;
    if (isDraftStatus) {
      navigate({
        pathname: getRoute(ROUTES.DELIVERY_LOGISTICS, id),
        search: searchParams.toString(),
      });
      return;
    }

    const isSubmittedStatus = data.status === Status.SUBMITTED;
    if (!isSubmittedStatus) {
      navigate({
        pathname: ROUTES.HOME,
        search: searchParams.toString(),
      });
      return;
    }
  }
};
