import { createStyles } from '@stryberventures/gaia-react.theme';

export default createStyles((theme) => ({
  wrapper: {
    display: 'grid',
    padding: theme.spacing[32],
    gap: theme.spacing[24],
  },
  titleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  topBarActionsWrapper: {
    display: 'flex',
    gap: theme.spacing[12],
  },
}));
