import { apiClient, ApiClientConfig } from 'api';
import { REST_API_ENDPOINTS } from 'api/constants';
import { StatisticsInProgress, StatisticsOverview } from 'types/statistics';

export type getStatisticsOverviewParams = {
  userId?: string;
};

export type getStatisticsInProgressParams = {
  userId?: string;
};

export const StatisticsApiService = () => {
  const getStatisticsOverview = async (
    params: getStatisticsOverviewParams,
    apiConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<StatisticsOverview>({
      params,
      ...apiConfig,
      endpoint: REST_API_ENDPOINTS.STATISTICS_OVERVIEW,
    });

    return response.data;
  };

  const getStatisticsInProgress = async (
    params: getStatisticsInProgressParams,
    apiConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<StatisticsInProgress>({
      params,
      ...apiConfig,
      endpoint: REST_API_ENDPOINTS.STATISTICS_IN_PROGRESS,
    });

    return response.data;
  };

  return {
    getStatisticsOverview,
    getStatisticsInProgress,
  };
};
