import Form from '@stryberventures/gaia-react.form';
import Input from '@stryberventures/gaia-react.input';
import InputPassword from '@stryberventures/gaia-react.input-password';
import Text from '@stryberventures/gaia-react.text';
import TextLink from '@stryberventures/gaia-react.text-link';
import { useDir } from '@stryberventures/gaia-react.theme';
import classNames from 'classnames';
import { LogoIcon } from 'components/Icons/Logo';
import LoadingButton from 'components/LoadingButton';
import { LoginDTO } from 'features/auth/auth-api.service';
import { useLogin } from 'features/auth/use-cases/login';
import useStyles from 'features/users/routes/login/styles';
import i18n from 'i18n';
import { RouteName } from 'navigation/routeNames';
import React from 'react';
import * as yup from 'yup';

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email(i18n.screens.login.errors.emailInvalid)
    .required(i18n.screens.login.errors.email),
  password: yup.string().required(i18n.screens.login.errors.password),
});

export const Login = () => {
  const dir = useDir();
  const classes = useStyles({ dir });
  const [disabled, setDisabled] = React.useState(true);
  const { mutate, isLoading } = useLogin();

  //eslint-disable-next-line
  const handleSubmit = (values: any) => {
    const payload: LoginDTO = { password: values.password, username: values.email };
    mutate(payload);
  };

  return (
    <div className={classes.loginEmail}>
      <div className={classes.screen}>
        <div className={classes.logoWrapper}>
          <LogoIcon />
        </div>
        <div className={classes.formContainer}>
          <Text variant='h4' component='h4' weight='bold' align='center' className={classes.title}>
            {i18n.screens.login.title}
          </Text>
          <Text align='center' className={classes.description}>
            {i18n.screens.login.description}
          </Text>
          <Form
            className={classes.form}
            validationSchema={validationSchema}
            onChange={(formData, { isValid }) => {
              setDisabled(!isValid);
            }}
            onSubmit={handleSubmit}
          >
            <Input
              fullWidth
              name='email'
              label={i18n.screens.login.email}
              placeholder={i18n.screens.login.placeholders.email}
              className={classes.emailInput}
            />
            <InputPassword
              fullWidth
              name='password'
              autoComplete='new-password'
              label={i18n.screens.login.password}
              placeholder={i18n.screens.login.placeholders.password}
              className={classes.passwordInput}
            />
            <div className={classes.rememberMeContainer}>
              {/* TODO: SOR-142 Uncomment when this functionality is ready */}
              {/* <CheckBox */}
              {/*   name='remember' */}
              {/*   label={i18n.screens.login.checkbox} */}
              {/*   className={classes.checkboxInput} */}
              {/* /> */}
              <TextLink
                href={RouteName.ForgotPassword}
                className={classNames(classes.textLink, classes.link)}
              >
                {i18n.screens.login.forgotPassword}
              </TextLink>
            </div>
            <LoadingButton
              fullWidth
              type='submit'
              disabled={disabled}
              className={classes.submitButton}
              loading={isLoading}
            >
              {i18n.screens.login.submit}
            </LoadingButton>
            <Text className={classes.signUpText}>
              {i18n.screens.login.signUpDescription}{' '}
              <TextLink className={classes.link} href={RouteName.SignUp}>
                {i18n.screens.login.signUp}
              </TextLink>
            </Text>
          </Form>
        </div>
      </div>
    </div>
  );
};
