import Button from '@stryberventures/gaia-react.button';
import Form from '@stryberventures/gaia-react.form';
import InputPassword from '@stryberventures/gaia-react.input-password';
import Text from '@stryberventures/gaia-react.text';
import { useDir } from '@stryberventures/gaia-react.theme';
import { LogoIcon } from 'components/Icons/Logo';
import { ForgotPasswordConfirmDTO } from 'features/auth/auth-api.service';
import { useForgotPasswordConfirm } from 'features/auth/use-cases/forgot-password-confirm';
import useStyles from 'features/users/routes/forgot-password-confirm/styles';
import i18n from 'i18n';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import * as yup from 'yup';

const passwordRegEx =
  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])(?=(?:.*[!@#$%^&*()\-_=+{};:,<.>])+).{8,}$/;

const validationSchema = yup.object().shape({
  password: yup
    .string()
    .matches(passwordRegEx, i18n.screens.forgotPasswordConfirm.errors.passwordInvalid)
    .required(i18n.screens.forgotPasswordConfirm.errors.password),
  repeatPassword: yup
    .string()
    .oneOf([yup.ref('password')], i18n.screens.forgotPasswordConfirm.errors.confirmPassword),
});

export const ForgotPasswordConfirm = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const dir = useDir();
  const classes = useStyles({ dir });
  const [disabled, setDisabled] = React.useState(true);

  const { mutate: changePassword } = useForgotPasswordConfirm();

  if (!token) return null;
  //eslint-disable-next-line
  const handleSubmit = (values: any) => {
    const payload: ForgotPasswordConfirmDTO = {
      password: values.password,
      token,
    };
    changePassword(payload);
  };

  return (
    <div className={classes.createPassword}>
      <div className={classes.logoWrapper}>
        <LogoIcon />
      </div>
      <div className={classes.screen}>
        <div className={classes.formContainer}>
          <Text variant='h4' component='h4' weight='bold' align='center' className={classes.title}>
            {i18n.screens.forgotPasswordConfirm.title}
          </Text>
          <Form
            className={classes.form}
            validationSchema={validationSchema}
            onChange={(formData, { isValid }) => {
              setDisabled(!isValid);
            }}
            onSubmit={handleSubmit}
          >
            <InputPassword
              fullWidth
              name='password'
              autoComplete='new-password'
              label={i18n.screens.forgotPasswordConfirm.labels.password}
              placeholder={i18n.screens.forgotPasswordConfirm.placeholders.password}
              hint={i18n.screens.forgotPasswordConfirm.hint}
              className={classes.passwordInput}
            />
            <InputPassword
              fullWidth
              name='repeatPassword'
              autoComplete='new-password'
              label={i18n.screens.forgotPasswordConfirm.labels.confirmPassword}
              placeholder={i18n.screens.forgotPasswordConfirm.placeholders.confirmPassword}
              className={classes.repeatPasswordInput}
            />
            <Button fullWidth type='submit' disabled={disabled} className={classes.submitButton}>
              {i18n.screens.forgotPasswordConfirm.submit}
            </Button>
          </Form>
        </div>
      </div>
    </div>
  );
};
