import { PointArrowIcon } from '@stryberventures/gaia-react.icons';
import { CustomButton, CustomButtonProps } from 'components/custom-button/custom-button';
import i18n from 'i18n';
import React from 'react';

import useStyles from './styles';

export const ContinueButton: React.FC<CustomButtonProps> = (props) => {
  const classes = useStyles();
  return (
    <CustomButton
      className={classes.continueButton}
      iconRight={<PointArrowIcon variant='right' />}
      {...props}
    >
      {i18n.screens.pickupStorage.continueButton}
    </CustomButton>
  );
};
