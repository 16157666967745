import { StorageItemsList } from 'components';
import { Text } from 'components/text/text';
import { PickupItem } from 'features/pickup/components/pickup-item-list/pickup-item/pickup-item';
import i18n from 'i18n';
import React, { useEffect } from 'react';
import { ItemToPickup } from 'types/item-to-pickup';

import useStyles from './styles';

type PickupItemsListProps = {
  items: ItemToPickup[];
};

export const PickupItemList: React.FC<PickupItemsListProps> = ({ items }) => {
  const [itemList, setItemList] = React.useState<ItemToPickup[]>([]);
  const classes = useStyles();

  useEffect(() => {
    setItemList(items);
  }, [items]);

  return (
    <section className={classes.wrapper}>
      <Text variant='components1' weight='medium'>
        {i18n.screens.pickupStorage.pickupItemList.title}
      </Text>
      <StorageItemsList isEmpty={!!itemList.length}>
        {itemList.map((item) => (
          <PickupItem key={item.id} item={item} />
        ))}
      </StorageItemsList>
    </section>
  );
};
