import Button, { IButton } from '@stryberventures/gaia-react.button';
import CircularProgress from '@stryberventures/gaia-react.circular-progress';
import React from 'react';

interface ILoadingButton extends IButton {
  loading?: boolean;
}

const LoadingButton: React.FC<ILoadingButton> = ({
  loading,
  children,
  disabled,
  icon,
  ...rest
}) => {
  const disabledButton = disabled || loading;

  return (
    <Button icon={loading ? null : icon} disabled={disabledButton} {...rest}>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/*// @ts-ignore */}
      {loading ? <CircularProgress size={24} thickness={3} /> : children}
    </Button>
  );
};

export default LoadingButton;
