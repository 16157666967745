import { createStyles } from '@stryberventures/gaia-react.theme';

export default createStyles((theme) => ({
  actions: {
    display: 'grid',
    gridTemplateColumns: '200px 1fr',
  },
  actionContainer: {
    padding: [26, theme.spacing[32]],
    borderRight: [1, 'solid', theme.colors.neutralGray.light200],
    '&:last-child': {
      borderRight: 'none',
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  statContainer: {
    display: 'flex',
    gap: 50,
  },
  buttonWrapper: {
    display: 'flex',
    gap: theme.spacing[8],
  },
  subtitle: {
    display: 'block',
    textTransform: 'uppercase',
    fontFamily: theme.secondaryFont,
    fontWeight: 700,
    fontSize: 13,
    lineHeight: '150%',
    letterSpacing: 1.82,
    color: theme.colors.text.tint,
    marginTop: 22,
    marginBottom: 4,
  },
  invoiceButtonContainer: {
    marginTop: 22,
  },
}));
