import { Calendar } from 'components/calendar/calendar';
import i18n from 'i18n';
import React from 'react';
import { ReactDatePickerProps } from 'react-datepicker';

type TransportCalendarProps = {
  date: Date | null;
  onChange: (date: Date | null) => void;
} & ReactDatePickerProps;
export const TransportCalendar = ({ date, onChange, ...props }: TransportCalendarProps) => (
  <Calendar
    date={date}
    onChange={onChange}
    isHighlighted={Boolean(date)}
    placeholderText={i18n.components.transportDate.timeSlot.selectDate}
    {...props}
  />
);
