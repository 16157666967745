import RadioButton from '@stryberventures/gaia-react.radio-button';
import { useLocationContext } from 'components/location/location-context';
import { LocationMode } from 'components/location/types';
import React from 'react';

import useStyles from './styles';

export const SwitchForms = () => {
  const classes = useStyles();
  const { mode, setMode } = useLocationContext();

  return (
    <div className={classes.switch}>
      <RadioButton
        alignControl='top'
        label='Saved'
        name='mode'
        checked={mode === LocationMode.Select}
        onChange={() => setMode(LocationMode.Select)}
        value={LocationMode.Select}
      />
      <RadioButton
        alignControl='top'
        label='Create New'
        name='mode'
        checked={mode === LocationMode.Create}
        onChange={() => setMode(LocationMode.Create)}
        value={LocationMode.Create}
      />
    </div>
  );
};
