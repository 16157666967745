import { useMutation } from '@tanstack/react-query';
import { handleError } from 'api';
import { CreateDeliveryDTO, DeliveryApiService } from 'features/delivery/api/delivery-api.service';
import { useUserId } from 'features/users';
import { enqueueSnackbar } from 'notistack';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
import { getRoute } from 'routes/utils/get-route';

const { createDelivery } = DeliveryApiService();

export const useCreateDelivery = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const userId = useUserId();

  return useMutation(
    (payload?: CreateDeliveryDTO) => {
      return createDelivery({ userId, ...payload });
    },
    {
      onSuccess: (data) => {
        navigate({
          pathname: getRoute(ROUTES.DELIVERY_STORAGE, data.id),
          search: searchParams.toString(),
        });
      },
      onError: (error) => {
        const uiError = handleError(error);
        enqueueSnackbar(uiError, { variant: 'error' });
      },
    },
  );
};
