import { UseQueryOptions } from '@tanstack/react-query';
import { Order, PaginatedRequestParams } from 'api';
import { UserPrices } from 'features/users/api/users-api-service';
import { useGetUserPrices } from 'features/users/use-cases/get-user-prices';

const initialParams: PaginatedRequestParams = {
  order: Order.asc,
  page: 1,
  take: 10,
};

export const useGetQueryIdUserPrices = (
  params: PaginatedRequestParams = initialParams,
  options?: UseQueryOptions<UserPrices>,
) => {
  return useGetUserPrices(params, options);
};
