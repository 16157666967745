import { ListItem, ListItemText } from '@stryberventures/gaia-react.list';
import Menu from '@stryberventures/gaia-react.menu';
import classNames from 'classnames';
import React from 'react';
import { useOutsideClick } from 'utils/hooks/use-outside-click';

import useStyles from './styles';

export type ContextMenuOption = {
  label: string;
  onClick: () => void;
};

type ContextMenuProps = {
  options: ContextMenuOption[];
  children: React.ReactNode;
  position?: 'left' | 'right';
};

export const ContextMenu: React.FC<ContextMenuProps> = ({
  children,
  position = 'left',
  options,
}) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = React.useState(false);

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);
  const wrapperRef = useOutsideClick(handleClose);

  return (
    <div className={classes.modalWrapper} ref={wrapperRef} onClick={handleOpen}>
      {children}
      {isOpen && (
        <div className={classNames(classes.modal, classes[position])}>
          <Menu>
            {options.map((option, index) => (
              <ListItem className={classes.modalOption} key={index} onClick={option.onClick}>
                <ListItemText primary={option.label} />
              </ListItem>
            ))}
          </Menu>
        </div>
      )}
    </div>
  );
};
