import Divider from '@stryberventures/gaia-react.divider';
import { SuccessfulRequest, SuccessfulRequestType } from 'components';
import { Text, TextColor } from 'components/text/text';
import i18n from 'i18n';
import React from 'react';
import { useParams } from 'react-router-dom';
import { Pickup } from 'types/pickup';
import { TruckType } from 'types/truck-type';
import { UserPrice } from 'types/user-price';
import { DateService, DEFAULT_TIMEZONE, PriceService } from 'utils';
import { formatDate } from 'utils/format-date';

import useStyles from './styles';

type PickupInfoProps = {
  pickup: Pickup;
  truckPrice: number;
  warehousePrice: UserPrice;
  storageUnit: string;
  truckType: TruckType;
};

const { UTCtoLocalTimezone } = DateService(DEFAULT_TIMEZONE);
const { getMonthlyMeterRequestedPrice, getPriceWithCurrency } = PriceService();

export const PickupInfo: React.FC<PickupInfoProps> = ({
  pickup,
  truckPrice,
  warehousePrice,
  storageUnit,
  truckType,
}) => {
  const { id } = useParams();

  const classes = useStyles();

  if (!id) return null;

  const monthlyPrice = getMonthlyMeterRequestedPrice(warehousePrice.price, pickup.metersRequested);
  const truckPriceWithCurrency = getPriceWithCurrency(truckPrice);

  return (
    <SuccessfulRequest id={id} type={SuccessfulRequestType.PICKUP}>
      <div>
        <div className={classes.sidesText}>
          <Text variant='components2' color={TextColor.Tint}>
            {i18n.screens.pickupCreatedInfo.pickupInfo.size}
          </Text>
          <Text variant='components2' color={TextColor.Tint}>
            {i18n.screens.pickupCreatedInfo.pickupInfo.location}
          </Text>
          <Text variant='components2' color={TextColor.Tint}>
            {i18n.screens.pickupCreatedInfo.pickupInfo.date}
          </Text>
        </div>
        <div className={classes.sidesText}>
          <Text variant='components1'>
            {pickup.metersRequested} {storageUnit} {i18n.estimatedAbbreviation}
          </Text>
          <Text variant='components1'>{pickup.location?.name}</Text>
          <Text variant='components1'>
            {formatDate({
              date: UTCtoLocalTimezone(pickup.pickupDate),
              dateFormat: 'dateTime',
            })}
          </Text>
        </div>
      </div>
      <Divider />
      <div>
        <div className={classes.sidesText}>
          <Text variant='components1'>
            {i18n.screens.pickupCreatedInfo.pickupInfo.transportPrice}
          </Text>
          <Text variant='components1'>{truckPriceWithCurrency}</Text>
        </div>
        <div className={classes.sidesText}>
          <Text variant='components1' color={TextColor.Tint}>
            {truckType.name}
          </Text>
          <Text variant='components1' color={TextColor.Tint}>
            {truckPriceWithCurrency}
          </Text>
        </div>
      </div>
      <div>
        <div className={classes.sidesText}>
          <Text variant='components1'>
            {i18n.screens.pickupCreatedInfo.pickupInfo.storagePrice} {i18n.estimatedAbbreviation}
          </Text>
          <Text variant='components1'>
            {monthlyPrice} {i18n.currency.sar}/{i18n.monthAbbreviation}
          </Text>
        </div>
        <Text variant='components2' color={TextColor.Tint}>
          {i18n.screens.pickupCreatedInfo.pickupInfo.estimationDescription}
        </Text>
        <div className={classes.overviewBottom}>
          <Text variant='components1' color={TextColor.Tint}>
            {i18n.screens.pickupCreatedInfo.pickupInfo.storageSpace} {i18n.estimatedAbbreviation}:{' '}
            {pickup.metersRequested} {storageUnit}
          </Text>
          <Text variant='components1' color={TextColor.Tint}>
            {warehousePrice.warehouseType.name} {i18n.screens.pickupCreatedInfo.pickupInfo.charge}:{' '}
            {warehousePrice.price} {i18n.currency.sar}/{storageUnit}
          </Text>
        </div>
      </div>
    </SuccessfulRequest>
  );
};
