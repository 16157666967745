import { axiosInstance } from './axios-instance';
import { ApiClientConfig, baseUrl } from './types';

export const apiClient = <TResponseData>({
  endpoint,
  baseURL = baseUrl.sortedApi,
  data = {},
  params = {},
  method = 'GET',
  responseType = 'json',
  config = {},
}: ApiClientConfig) => {
  const hasRequestData = data instanceof FormData || Object.keys(data).length > 0;
  const hasParams = Object.keys(params).length > 0;

  return axiosInstance.request<TResponseData>({
    ...config,
    baseURL,
    url: endpoint,
    ...(hasRequestData ? { data } : undefined),
    ...(hasParams ? { params } : undefined),
    method,
    responseType,
    headers: { 'Content-Type': 'application/json', ...config.headers },
  });
};
