import 'react-loading-skeleton/dist/skeleton.css';

import { api } from '@stryberventures/auth-web';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import env from 'env';
import { RouterProvider } from 'react-router-dom';
import { router } from 'routes/router';
import { useGlobalStyles } from 'styles/globalStyles';

api.setUrl(env.authApiUrl);

const Navigation = () => {
  useGlobalStyles();

  return (
    <>
      <RouterProvider router={router} />
      <ReactQueryDevtools initialIsOpen={false} position='bottom-right' />
    </>
  );
};

export default Navigation;
