import { createStyles } from '@stryberventures/gaia-react.theme';

export default createStyles((theme) => ({
  storagePrice: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  priceTitle: {
    color: theme.colors.neutralGray.medium400,
  },
  price: {
    color: theme.colors.primary.main500,
  },
}));
