import { CheckIcon } from '@stryberventures/gaia-react.icons';
import NumberInput from '@stryberventures/gaia-react.number-input';
import LoadingButton from 'components/LoadingButton';
import { Select } from 'components/select/select';
import { useAddExistingPickupItem } from 'features/pickup/use-cases/add-existing-pickup-item';
import i18n from 'i18n';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Options } from 'react-select';
import { Option } from 'types/option';
import { PickupItem } from 'types/pickup-item';

import useStyles from './styles';

type AddExistingItemFormProps = {
  pickupItems: PickupItem[];
};

type FormData = {
  quantity: number;
  selectedOption: Option | null;
};

const initialValues = {
  quantity: 1,
  selectedOption: null,
};

const getExistingItemsOptions = (items: PickupItem[]): Options<Option> => {
  return items.map((item) => ({
    label: `${item.name} (${item.sku})`,
    value: item.id,
  }));
};

export const AddExistingItemForm: React.FC<AddExistingItemFormProps> = ({ pickupItems }) => {
  const { id } = useParams();
  const [formData, setFormData] = React.useState<FormData>(initialValues);
  const [options, setOptions] = React.useState<Options<Option>>([]);
  const classes = useStyles();

  const onSuccess = () => {
    setFormData(initialValues);
  };

  const { mutate: addPickupItem, isLoading } = useAddExistingPickupItem(id, onSuccess);

  const handleSelectChange = (selectedOption: Option | null) => {
    setFormData({ ...formData, selectedOption });
  };

  const handleQuantityChange = (quantity: number) => {
    setFormData({ ...formData, quantity });
  };

  const handleSubmit = () => {
    if (!formData.selectedOption || !formData.quantity) return;
    if (typeof formData.selectedOption.value !== 'number') return;
    addPickupItem({
      pickupId: Number(id),
      itemId: formData.selectedOption.value,
      quantity: formData.quantity,
    });
  };

  useEffect(() => {
    setOptions(getExistingItemsOptions(pickupItems));
  }, [pickupItems]);

  return (
    <div className={classes.addExistingItemForm}>
      <Select
        options={options}
        placeholder={
          i18n.screens.pickupStorage.addItemForm.addExistingItemForm.placeholders.selectItem
        }
        onChange={handleSelectChange}
        value={formData.selectedOption}
      />
      <NumberInput
        controlled
        fullWidth
        quantityCounter
        value={formData.quantity}
        onChange={handleQuantityChange}
      />
      <LoadingButton
        icon={<CheckIcon />}
        size='small'
        loading={isLoading}
        onClick={handleSubmit}
        disabled={!formData.selectedOption || !formData.quantity}
        className={classes.addItemButton}
      />
    </div>
  );
};
