import { QUERY_KEYS } from 'features/query-keys';

/**
 * @see https://tkdodo.eu/blog/effective-react-query-keys#use-query-key-factories
 */
export const pickupsQueryKeys = {
  all: [QUERY_KEYS.pickups] as const,
  lists: () => [...pickupsQueryKeys.all, 'list'] as const,
  list: (filters: object) => [...pickupsQueryKeys.lists(), { filters }] as const,
  details: () => [...pickupsQueryKeys.all, 'detail'] as const,
  detail: (id: string) => [...pickupsQueryKeys.details(), id] as const,
};
