import { MoreIcon } from '@stryberventures/gaia-react.icons';
import { ContextMenu } from 'components/context-menu/context-menu';
import { useDeletePickupItem } from 'features/pickup/use-cases/delete-pickup-item';
import i18n from 'i18n';
import React from 'react';

import useStyles from './styles';

type DeletePickupItemProps = {
  itemId: number;
  pickupId: number;
};

export const DeletePickupItem: React.FC<DeletePickupItemProps> = ({ itemId, pickupId }) => {
  const { mutate: deleteItem } = useDeletePickupItem();
  const classes = useStyles();

  const contextMenuOptions = [
    {
      label: i18n.screens.pickupStorage.pickupItemList.pickupItem.deleteButton,
      onClick: () =>
        deleteItem({
          itemId,
          pickupId,
        }),
    },
  ];

  return (
    <ContextMenu position='right' options={contextMenuOptions}>
      <div className={classes.contextMenuButton}>
        <MoreIcon />
      </div>
    </ContextMenu>
  );
};
