import { TopBar } from 'components/top-bar/top-bar';
import { NotActivatedMessage } from 'features/auth/components/not-activated-message/not-activated-message';
import i18n from 'i18n';
import React from 'react';

import useStyles from './styles';

export const AccountNotActivatedView: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <TopBar title={i18n.title} titleColor='neutralGrey' />
      <NotActivatedMessage />
    </div>
  );
};
