import CircularProgress from '@stryberventures/gaia-react.circular-progress';
import { LoadIcon } from '@stryberventures/gaia-react.icons';
import { CustomButton } from 'components';
import { UploadPickupItemsCsvParams } from 'features/pickup/api/pickup-items-api.service';
import { useUploadPickupItemsCsv } from 'features/pickup/use-cases/upload-pickup-items-csv';
import i18n from 'i18n';
import { forwardRef } from 'react';
import { getTypedFormData } from 'types/typed-form-data';

import useStyles from './styles';

type BulkUploadItemsInputsProps = {
  pickupId?: string;
  onClick: () => void;
};

export const BulkUploadItemsInputs = forwardRef<HTMLInputElement, BulkUploadItemsInputsProps>(
  ({ pickupId, onClick }, ref) => {
    const classes = useStyles();
    const { mutate: uploadCsv, isLoading } = useUploadPickupItemsCsv(pickupId);

    const handleFileChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
      if (!e.target.files) return;
      const file = e.target.files[0];
      if (!file) return;
      const formData = getTypedFormData<UploadPickupItemsCsvParams>();
      formData.append('file', file);
      if (pickupId) {
        formData.append('pickupId', pickupId.toString());
      }
      uploadCsv(formData);
      e.target.value = '';
    };

    return (
      <div className={classes.wrapper}>
        <label htmlFor='bulkUpload' className={classes.inputLabel}>
          <input
            ref={ref}
            type='file'
            id='bulkUpload'
            accept='.csv'
            onChange={handleFileChange}
            style={{ display: 'none', cursor: 'pointer' }}
          />
          {isLoading ? (
            <CircularProgress
              className={classes.uploadButton}
              size={20}
              shape='flat'
              thickness={2}
            />
          ) : (
            <CustomButton
              className={classes.uploadButton}
              iconLeft={<LoadIcon variant='upload' />}
              variant='ghost'
              onClick={onClick}
            >
              {i18n.screens.pickupStorage.addItemForm.bulkUpload.buttonLabel}
            </CustomButton>
          )}
        </label>
      </div>
    );
  },
);

BulkUploadItemsInputs.displayName = 'BulkUploadItemsInputs';
