import NumberInput from '@stryberventures/gaia-react.number-input';
import { StorageItem } from 'components';
import { DeletePickupItem } from 'features/pickup/components/pickup-item-list/pickup-item/delete-pickup-item/delete-pickup-item';
import { useUpdatePickupItem } from 'features/pickup/use-cases/update-pickup-item';
import React, { useEffect } from 'react';
import { ItemToPickup } from 'types/item-to-pickup';

type PickupItemProps = {
  item: ItemToPickup;
};

export const PickupItem: React.FC<PickupItemProps> = ({ item }) => {
  const [quantity, setQuantity] = React.useState<number>(item.quantity);

  const { mutate: updateItem, isLoading: isItemUpdateLoading } = useUpdatePickupItem(
    item.itemId,
    item.pickupId,
  );

  const handleChange = (value: number) => {
    setQuantity(value);
  };

  const handleSaveQuantity = () => {
    updateItem({
      pickupId: item.pickupId,
      quantity,
    });
  };

  useEffect(() => {
    setQuantity(item.quantity);
  }, [item.quantity]);

  return (
    <StorageItem
      name={item.item.name}
      sku={item.item.sku}
      multimedia={item.item.multimedia}
      quantityComponent={
        <NumberInput
          quantityCounter
          min={1}
          value={quantity}
          fullWidth
          onChange={handleChange}
          controlled
        />
      }
      deleteItemComponent={<DeletePickupItem itemId={item.itemId} pickupId={item.pickupId} />}
      isSubmitButtonDisabled={item.quantity === quantity}
      isSubmitButtonLoading={isItemUpdateLoading}
      onSubmitButtonClick={handleSaveQuantity}
    />
  );
};
