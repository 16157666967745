import development from './development';
import production from './production';

type TEnvName = 'development' | 'production';
const name = (process.env.REACT_APP_ENVIRONMENT as TEnvName) || 'development';

interface IEnv {
  name: TEnvName;
  authApiUrl: string;
  sortedApi: string;
  googleMapsApiKey: string;
  ADMIN_ROLE: string;
  USER_ROLE: string;
}

const env: IEnv = {
  name,
  ...{ development, production }[name],
};
export default env;
