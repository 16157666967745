import { createStyles } from '@stryberventures/gaia-react.theme';

interface IUseStyles {
  dir: string;
}
//eslint-disable-next-line
export default createStyles<any, IUseStyles>((theme) => ({
  form: {
    display: 'grid',
    gap: theme.spacing[16],
  },
  submit: {
    width: 'max-content',
  },
}));
