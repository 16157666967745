import Divider from '@stryberventures/gaia-react.divider';
import { SuccessfulRequest, SuccessfulRequestType, Text, TextColor } from 'components';
import i18n from 'i18n';
import React from 'react';
import { Delivery } from 'types/delivery';
import { TruckType } from 'types/truck-type';
import { DateService, DEFAULT_TIMEZONE, formatDate, PriceService } from 'utils';

import useStyles from './styles';

type DeliveryInfoProps = {
  delivery: Delivery;
  truckType: TruckType;
  id: string;
};

const { UTCtoLocalTimezone } = DateService(DEFAULT_TIMEZONE);
const { getPriceWithCurrency } = PriceService();

export const DeliveryInfo: React.FC<DeliveryInfoProps> = ({ delivery, truckType, id }) => {
  const classes = useStyles();
  const truckTypePrice = getPriceWithCurrency(truckType.priceCents);

  return (
    <SuccessfulRequest id={id} type={SuccessfulRequestType.DELIVERY}>
      <div>
        <div className={classes.sidesText}>
          <Text variant='components2' color={TextColor.Tint}>
            {i18n.screens.deliveryCreatedInfo.deliveryInfo.location}
          </Text>
          <Text variant='components2' color={TextColor.Tint}>
            {i18n.screens.deliveryCreatedInfo.deliveryInfo.date}
          </Text>
        </div>
        <div className={classes.sidesText}>
          <Text variant='components1'>{delivery.location?.name}</Text>
          <Text variant='components1'>
            {formatDate({
              date: UTCtoLocalTimezone(delivery.deliveryDate),
              dateFormat: 'dateTime',
            })}
          </Text>
        </div>
      </div>
      <Divider />
      <div>
        <div className={classes.sidesText}>
          <Text variant='components1'>
            {i18n.screens.deliveryCreatedInfo.deliveryInfo.transportPrice}
          </Text>
          <Text variant='components1'>{truckTypePrice}</Text>
        </div>
        <div className={classes.sidesText}>
          <Text variant='components1' color={TextColor.Tint}>
            {truckType.name}
          </Text>
          <Text variant='components1' color={TextColor.Tint}>
            {truckTypePrice}
          </Text>
        </div>
      </div>
    </SuccessfulRequest>
  );
};
