import { SVGProps } from 'react';

export const InventoryIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      {...props}
    >
      <path
        d='M18.49 6.54063L12.49 1.21062C11.07 -0.049375 8.93 -0.049375 7.51 1.21062L1.51 6.54063C0.71 7.25063 0.25 8.27063 0.25 9.34062V15.9906C0.25 18.0606 1.93 19.7406 4 19.7406H16C18.07 19.7406 19.75 18.0606 19.75 15.9906V9.34062C19.75 8.27063 19.29 7.25063 18.49 6.54063ZM14.25 18.2506H5.75V12.0006C5.75 11.3106 6.31 10.7506 7 10.7506H13C13.69 10.7506 14.25 11.3106 14.25 12.0006V18.2506ZM18.25 16.0006C18.25 17.2406 17.24 18.2506 16 18.2506H15.75V12.0006C15.75 10.4806 14.52 9.25062 13 9.25062H7C5.48 9.25062 4.25 10.4806 4.25 12.0006V18.2506H4C2.76 18.2506 1.75 17.2406 1.75 16.0006V9.35063C1.75 8.71062 2.03 8.09063 2.5 7.67063L8.5 2.34063C8.93 1.96063 9.46 1.77062 10 1.77062C10.54 1.77062 11.07 1.96063 11.5 2.34063L17.5 7.67063C17.98 8.10063 18.25 8.71062 18.25 9.35063V16.0006Z'
        fill='#667085'
      />
      <path
        d='M12 6.25H8C7.59 6.25 7.25 6.59 7.25 7C7.25 7.41 7.59 7.75 8 7.75H12C12.41 7.75 12.75 7.41 12.75 7C12.75 6.59 12.41 6.25 12 6.25Z'
        fill='#667085'
      />
    </svg>
  );
};
