export type LatLngLiteral = {
  lat: number;
  lng: number;
};
export enum LocationMode {
  Select = 'Select',
  Create = 'Create',
}

export type LocationState = {
  selectedLocationId: number | null;
  pin: string;
  mode: LocationMode;
};
