import { createStyles } from '@stryberventures/gaia-react.theme';

export default createStyles((theme) => ({
  topBar: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: [theme.spacing[24], theme.spacing[32]],
    alignItems: 'center',
  },
  contentWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing[8],
  },
  pageTitle: {
    color: theme.colors.neutralGray.medium400,
  },
  neutralGrey: {
    color: theme.colors.neutralGray.medium400,
  },
  headline: {
    color: theme.colors.text.headline,
  },
  select: {
    marginLeft: 'auto',
    marginRight: theme.spacing[16],
    width: '300px',
  },
}));
